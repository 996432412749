import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import FormInput from "../../components/form-input";
import FormSelect from "../../components/form-select";
import Button from "../../components/button";
import OnboardingLayout from "../../components/layouts/onboarding";
import { formValidation } from "../../utils/functions";
import DOMPurify from "dompurify";
import { useCreateCompany } from "../../redux/company/hook";
import { countriesAndState, AllCountries } from "../../constants/countries";
import { industryOptions } from "../../utils/constants/industry-options";

const AboutPage = () => {
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const [industry, setIndustry] = useState("Financial Technology");
  const [country, setCountry] = useState("Nigeria");
  const [state, setState] = useState("Lagos");
  const [stateOptions, setStateOptions] = useState([]);
  const { mutate: createCompany, isLoading: loading } = useCreateCompany();

  useEffect(() => {
    if (country) {
      let getStates = countriesAndState.find((a) => a.name === country);

      if (getStates) {
        let computedStates = [];

        getStates.states.map((item, index) => {
          computedStates.push({ label: item, value: item });
        });

        setStateOptions(computedStates);
      }
    }
  }, [country]);

  async function submitForm(data) {
    let formData = {
      company_name: DOMPurify.sanitize(data?.company_name),
      email: DOMPurify.sanitize(data?.email),
      mobile: DOMPurify.sanitize(data?.mobile),
      reg_no: DOMPurify.sanitize(data?.reg_no),
      tax_id: DOMPurify.sanitize(data?.tax_id),
      nhf_code: DOMPurify.sanitize(data?.nhf_code),
      itf_code: DOMPurify.sanitize(data?.itf_code),
      nhis_code: DOMPurify.sanitize(data?.nhis_code),
      nsitf_code: DOMPurify.sanitize(data?.nsitf_code),
      address: DOMPurify.sanitize(data?.address),
      city: DOMPurify.sanitize(data?.city),
      employerPensionCode: DOMPurify.sanitize(data?.employerPensionCode),
      state,
      balance: 0,
      low_balance_limit: 0,
      ip_address: "",
      industry: industry,
      country,
      employerPerContribution: 1,
    };

    createCompany(formData);
  }

  useEffect(() => {}, []);

  return (
    <OnboardingLayout step={1}>
      <div className="md:flex flex-col hidden header-4">
        <span className="header-3">Company information</span>
        <hr className="divider mt-2" />
      </div>

      <form action="px-8" onSubmit={handleSubmit(submitForm)}>
        <div className="flex flex-col bg-highlight p-3 rounded-md">
          <FormInput
            label="Company Name"
            name="company_name"
            placeholder={"Enter company name"}
            type="text"
            inputRef={register(formValidation("text", true))}
            readOnly={loading}
            error={errors.company_name}
            errorMessage={errors.company_name && errors.company_name.message}
          />
          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-2/3 md:pr-2">
              <FormInput
                label="Company Email address"
                name="email"
                type="text"
                placeholder={"Enter company email"}
                inputRef={register(formValidation("email", true))}
                readOnly={loading}
                error={errors.email}
                errorMessage={errors.email && errors.email.message}
              />
            </div>
            <div className="w-full md:w-1/3 md:pl-2">
              <FormInput
                label="Company Phone number"
                name="mobile"
                type="text"
                placeholder={"Enter phone number"}
                inputRef={register(formValidation("text", true, 10))}
                readOnly={loading}
                error={errors.mobile}
                errorMessage={errors.mobile && errors.mobile.message}
              />
            </div>
          </div>
          <hr className="divider mt-0 mb-2" />
          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-1/2 md:pr-2">
              <FormSelect
                defaultValue={country}
                options={AllCountries}
                onChange={(selected) => {
                  setCountry(selected);
                }}
                label="Country"
              />
            </div>
            <div className="w-full md:w-1/2 md:pl-2">
              {country == "Nigeria" ? (
                <FormSelect
                  defaultValue={state}
                  options={stateOptions}
                  onChange={(selected) => {
                    setState(selected);
                  }}
                  label="State"
                />
              ) : (
                <FormInput
                  label="State/Province"
                  name="state"
                  type="text"
                  value={state}
                  placeholder={"Enter state of operation"}
                  onInput={(e) => setState(e.target.value)}
                  error={errors.state}
                  errorMessage={errors.state && errors.state.message}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-2/3 md:pr-2">
              <FormInput
                label="Company Address"
                name="address"
                type="text"
                placeholder={"Enter company address"}
                inputRef={register(formValidation("text", true))}
                readOnly={loading}
                error={errors.address}
                errorMessage={errors.address && errors.address.message}
              />
            </div>
            <div className="w-full md:w-1/3 md:pl-2">
              <FormInput
                label="City"
                name="city"
                type="text"
                placeholder={"Enter city"}
                inputRef={register(formValidation("text", true))}
                readOnly={loading}
                error={errors.city}
                errorMessage={errors.city && errors.city.message}
              />
            </div>
          </div>
          <hr className="divider mt-0 mb-2" />
          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-1/2 md:pr-2">
              <FormInput
                label="Reg. Number - (Optional)"
                inputRef={register(formValidation("text", false))}
                placeholder={"Enter company registration number"}
                name="reg_no"
                type="text"
                readOnly={loading}
              />
            </div>
            <div className="w-full md:w-1/2 md:pl-2">
              <FormSelect
                defaultValue={industry}
                options={industryOptions}
                onChange={(selected) => {
                  setIndustry(selected);
                }}
                label="Industry"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-1/2 md:pr-2">
              <FormInput
                label="Tax ID (PAYE) - (Optional)"
                inputRef={register(formValidation("text", false))}
                placeholder={"Enter PAYE tax ID"}
                name="tax_id"
                type="text"
                readOnly={loading}
              />
            </div>
            <div className="w-full md:w-1/2 md:pl-2">
              <FormInput
                label="Pension Code - (Optional)"
                name="employeePensionCode"
                type="text"
                placeholder={"Enter pension number (RSA Pin)"}
                inputRef={register(formValidation("text", false))}
                readOnly={loading}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-1/2 md:pr-2">
              <FormInput
                label="NHF Code - (Optional)"
                inputRef={register(formValidation("text", false))}
                placeholder={"Enter NHF Code"}
                name="nhf_code"
                type="text"
                readOnly={loading}
              />
            </div>
            <div className="w-full md:w-1/2 md:pl-2">
              <FormInput
                label="NHIS Code - (Optional)"
                name="nhis_code"
                placeholder={"Enter NHIS Code"}
                type="text"
                inputRef={register(formValidation("text", false))}
                readOnly={loading}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-1/2 md:pr-2">
              <FormInput
                label=" ITF Code - (Optional)"
                inputRef={register(formValidation("text", false))}
                name="itf_code"
                placeholder={"Enter ITF Code"}
                type="text"
                readOnly={loading}
              />
            </div>
            <div className="w-full md:w-1/2 md:pl-2">
              <FormInput
                label="NSITF Code - (Optional)"
                name="nsitf_code"
                placeholder={"Enter NSITF Code"}
                type="text"
                inputRef={register(formValidation("text", false))}
                readOnly={loading}
              />
            </div>
          </div>
          <div className="flex justify-end w-full mt-[20px]">
            <Button
              text="Save and Continue"
              className={"w-[180px] h-[42px]"}
              textClass={"text-[13px]"}
              type="submit"
              loading={loading}
            />
          </div>
        </div>
      </form>
    </OnboardingLayout>
  );
};

export default AboutPage;
