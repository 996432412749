import { getMessaging, getToken } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { store } from '../../redux';
import { setWebToken } from '../../redux/user/user-slice';

let firebase = null;
let messaging = null;

const firebaseConfig = {
  apiKey: 'AIzaSyCcAwggvLXkbX6PEeZJE-YpbklLX7D20uM',
  authDomain: 'ropay-africa-f9c4b.firebaseapp.com',
  projectId: 'ropay-africa-f9c4b',
  storageBucket: 'ropay-africa-f9c4b.appspot.com',
  messagingSenderId: '617847142944',
  appId: '1:617847142944:web:e2943b42c8c405f7a95a45',
  measurementId: 'G-M3Z3QBQC9N',
};

try {
  // Initialize Firebase
  firebase = initializeApp(firebaseConfig);

  messaging = getMessaging(firebase);
} catch (error) {
  // console.log(error);
}

// Initialize Firebase Cloud Messaging and get a reference to the service
export function getPushNotificationToken() {
  try {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {

        getToken(messaging, {
          vapidKey:
            'BPUpZfOfiVWo0mGFufTWcV_jZ6nmNt70xO9OtqHee9RwEr-tsr4J4Cb2U2fLj2haST3n3N5TXUrxF94Jq0i6Wvc',
        })
          .then((currentToken) => {
            if (currentToken) {
              store.dispatch(setWebToken(currentToken));
            } else {
              // console.log(
              //   'No registration token available. Request permission to generate one.',
              // );
              // ...
            }
          })
          .catch((err) => {
            // console.log('An error occurred while retrieving token. ', err);
            // ...
          });
      }
    });
  } catch (error) {
    // console.log('cant receive notification');
  }
}
