import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import FormSelect from '../../../form-select';
import Modal from '../../modal';
import { useAddVideoAssessment } from '../../../../redux/human-resources/hook/recruitment';
import AsyncSelect from 'react-select/async';
import { Editor } from '@tinymce/tinymce-react';
import { recruitments as recruitmentAPI } from '../../../../api/hr/recruitment';
import WarningSVG from '../../../../assets/svg/warning.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../../../redux/components/components-slice';

function VideoAssessmentModal({
  isOpen,
  closeModal,
  stage,
  recruitment_id,
  applicant=null
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });
    const { company } = useSelector((state) => state.companySlice);

    const { mutateAsync: videoAssessment, isLoading: videoAssessmentLoading } = useAddVideoAssessment();
    const [type, setType] = useState('all');
    const [assessmentQuestion, setAssessmentQuestion] = useState();
    const [applicants, setApplicants] = useState([]);
    const dispatch = useDispatch()

    const loadSample = () => {
      setAssessmentQuestion(
        `
        <p><strong>Company:</strong> ${company?.company_name}</p>
        <p><strong>Location:</strong> ${company?.address} ${company?.city} ${company?.state} ${company?.country}</p>
        <p>Record yourself, while you answer the following questions:</p>
        <ul>
        <li>Introduce yourself (Name, prior experience).</li>
        <li>Why should we hire you?</li>
        <li>What are your best qualities? Mention a time you handled a conflict with a colleague</li>
        </ul>
        <p>Good Luck!!!</p>`
      )
    }

    const typeOptions = [
        {
          value: 'all',
          label: 'All Applicants (In this stage)',
        },
        {
          value: 'select',
          label: 'Selected Applicants',
        },
    ];

    useEffect(() => {
      if (applicant) {
        let data = []
        data.push(
          {label: `${applicant.first_name} ${applicant.last_name} | ${applicant.mobile}`,
          value: applicant.id,
        })
        setApplicants(data)
        setType('select')
      }
    }, [applicant])

    const loadOptions = (inputValue) =>
    new Promise((resolve) => {
        recruitmentAPI
        .getStageApplicants({
            stage: stage,
            recruitment_id: recruitment_id,
            limit: 10,
            statusFilter: 1,
            search: inputValue,
            page: 1,
        })
        .then((response) => {
          let data = [];

          response.data.forEach((item) => {
            data.push({
              label: `${item.first_name} ${item.last_name} | ${item.mobile} | ${item.email}`,
              value: item.id,
            });
          });

          resolve(data);
        })
        .catch((err) => {
          resolve([]);
        });
    });

  const submitForm = async () => {
    if (type == 'all') {
      let payload = {
          type,
          stage,
          question: assessmentQuestion
      };
      await videoAssessment(payload).then((result) => {
        closeModal();
      });
    } else {
      let applicant_ids = "";
      if (type == 'select') {
        if (applicants.length === 0) {
          dispatch(setAlert(true, 'info', 'Select at least one applicant'));
          return;
        }
        let applicantArrays = [];
        applicants.forEach((item) => {
          applicantArrays.push(item.value);
        });
    
        applicant_ids = applicantArrays.join();
      } else {
        applicant_ids = "none"; 
      }
      
      let payload = {
        type,
        applicant_ids,
        stage,
        question: assessmentQuestion
      };
      await videoAssessment(payload).then((result) => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
            <span className='header-4'>Video Assessment</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
            </div>
            </div>
        </div>
        <hr className='divider mb-2' />
        <form className='form pb-4 px-8' onSubmit={handleSubmit(submitForm)}>
          <div className='flex flex-col'>
            {applicant?
            <div className='flex flex-col border bg-highlight p-2 rounded-md'>
              <span className='text-[15px] font-extrabold text-color-gray'>Applicant name</span>
              <span className='text-[14px] font-normal text-color-black-2'>
                {applicant?.first_name} {applicant?.last_name} | {applicant?.mobile}
              </span>
            </div>
            :
            <div className='border bg-highlight p-2 rounded-md'>
              <FormSelect
                value={type}
                options={typeOptions}
                readOnly={applicant}
                onChange={(selected) => {
                  if (applicant == null) {
                    setType(selected)
                  }
                }}
                label='Assessment Scope'
                extraClass={'bg-white'}
              />
              {type === 'select'?
              <div className='min-h-fit'>
                <label className='!text-[13px] font-normal text-color-gray mb-1'>
                    Select Applicants
                </label>
                <AsyncSelect
                    isMulti
                    cacheOptions
                    defaultOptions
                    className='text-[13px]'
                    placeholder={'Type to select multiple applicants'}
                    loadOptions={loadOptions}
                    onChange={(options) => setApplicants(options)}
                    classNamePrefix='mySelect'
                />
              </div>
              :
              null
              }
            </div>
            }
            <hr className='divider mt-2 mb-2' />
            <div className='flex flex-col md:flex-row justify-between mb-2 mt-2'>
              <div className='w-full'>
                <div className='flex gap-2 mb-2 items-center w-full justify-between'>
                  <span className='text-color-gray text-[13px]'>
                    Assessment question
                  </span>
                  <Button
                    text='Load Sample data'
                    type='button'
                    theme='primary'
                    leftIcon={'refresh'}
                    className='flex gap-2 !h-[28px] w-fit p-2 '
                    textClass={'!text-[11px]'}
                    onClick={() => {
                      loadSample()
                    }}
                  />
                </div>
                <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                  onEditorChange={(editor) => {
                  setAssessmentQuestion(editor)
                  }}
                  value={assessmentQuestion}
                  init={{
                  height: 300,
                  menubar: false,
                  placeholder: 'Enter Assessment requirements',
                  plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor fontsize fontfamily | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:10pt }'
                  }}
                />
              </div>
            </div>
            <div className='flex flex-row border-secondary-4 border-[1.2px] rounded-lg pr-2 py-2 mt-2 bg-color-warning-light'>
              <div className='w-[20%] flex flex-row items-center justify-center '>
                <img src={WarningSVG} alt='' srcSet='' />
              </div>
              <span className='text-[12px] w-[70%] text-justify'>
                Kindly note that you can only carryout a single video assessment per applicant during recruitment. Ensure you enter all the questions/requirements you want this applicant to answer in their video.
              </span>
            </div>
            <div className='flex md:flex-row flex-col gap-3 w-full mt-[20px] '>
              <Button
                loading={videoAssessmentLoading}
                disabled={!isValid}
                onClick={() => submitForm()}
                text={'COMMENCE VIDEO ASSESSMENT'}
                type='button'
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default VideoAssessmentModal;
