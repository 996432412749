import React, { useEffect, useState } from "react";
import Button from "../button";
import { useSelector } from "react-redux";
import UnauthorizedPage from "../../pages/errors/unauthorized";
import TabView from "../tabview";
import { HMOBeneficiariesTab } from "./hmo-beneficiaries";
import HMOSettingsTab from "./hmo-settings";
import EmployeeHMOGroupsTab from "./hmo-groups-tab";
import EmptyTableSVG from "../../assets/svg/empty-table.svg";
import { useAddCompanyToHMO } from "../../redux/hmo/hook";

const EmployeeHMOPage = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const { company } = useSelector((state) => state.companySlice);

  const { mutateAsync: addCompanyToHMO, isLoading: addCompanyToHMOLoading } =
    useAddCompanyToHMO();

  const [activeTab, setActiveTab] = useState(1);

  const airtimeTabs = [
    {
      title: "HMO Beneficiaries",
      Content:
        user?.permissions?.hmo_permission?.beneficiary_read === 1 ||
        user?.permissions?.company_permission?.role === "Owner"
          ? HMOBeneficiariesTab
          : UnauthorizedPage,
    },
    {
      title: "HMO Groups",
      Content:
        user?.permissions?.hmo_permission?.hmo_group_read === 1 ||
        user?.permissions?.company_permission?.role === "Owner"
          ? EmployeeHMOGroupsTab
          : UnauthorizedPage,
    },
    {
      title: "HMO Settings",
      Content:
        user?.permissions?.hmo_permission?.hmo_settings_read === 1 ||
        user.permissions?.company_permission?.role === "Owner"
          ? HMOSettingsTab
          : UnauthorizedPage,
    },
  ];

  const addCompanyHMO = async () => {
    await addCompanyToHMO().then(() => {
      setActiveTab(3);
    });
  };

  return (
    <div className="flex flex-col -mt-2">
      {company.hmo === 1 ? (
        <div className="mt-2">
          <TabView
            Tabs={airtimeTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center max:w-[100%] md:mt-0 mt-8 select-none min-h-fit ">
          <img
            src={EmptyTableSVG}
            alt="No Data Fetched"
            width={"40%"}
            height={"100px"}
          />
          <div className="flex w-full justify-center md:w-[100%] mt-[10px] text-[18px] font-bold">
            {/* <div>Almost Ready. Doing some housekeeping...</div> */}
            <Button
              text={"REGISTER COMPANY FOR HMO"}
              onClick={() => {
                addCompanyHMO();
              }}
              loading={addCompanyToHMOLoading}
              className="flex gap-2 !h-[35px] w-fit p-2"
              textClass={"!text-[15px]"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeHMOPage;
