import React, { useEffect } from "react";
import {
  useActivateOrDeactivateEmployee,
  useActivateOrDeactivatePendingEmployee,
  useActivateOrDeactivatePreEmployee,
  useBulkActivateEmployees,
  useBulkDeactivateEmployees,
  useBulkDeleteEmployee,
  useDeleteEmployee,
  useExportEmployees,
  useGetEmployees,
  useResendInviteEmail,
  useResendMobileInviteEmail,
} from "../../redux/employees/hook";
import DataTable from "../datatable";
import { useState } from "react";
import {
  employeeStatusConfig,
  employeehrStatusConfig,
  PAGINATION_DEFAULT,
} from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { store } from "../../redux";
import { setAlert } from "../../redux/components/components-slice";
import AddEmployeeToHrSubscription from "../modal/hr-section/subscription/add-employee-subscription";
import Button from "../button";
import UploadProfilePix from "../modal/employees/add-profile-pix";
import DoYouWantToModal from "../modal/do-you-want-to";
import HelpSVG from "../../assets/svg/help.svg";
import HrHelpSVG from "../../assets/svg/hr-help.svg";
import InfoModal from "../modal/info-modal";
import InvitationLinkModal from "../modal/employees/invitation-link";
import { useSelector } from "react-redux";
import SendOfferLetter from "../modal/hr-section/recruitment/send-offer-letter";
import Excel from "exceljs";
import { saveAs } from "file-saver";

const OverviewTab = () => {
  useEffect(() => {}, []);

  const { section } = useSelector((state) => state.sectionSlice);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { refetch, data: employees } = useGetEmployees(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const workbook = new Excel.Workbook();

  const [exportLoading, setSetExportLoading] = useState(false);

  const { mutateAsync: exportEmployee } = useExportEmployees();

  const exportEmployeeData = async () => {
    setSetExportLoading(true);
    let payload = {
      limit: 100000,
      page: 1,
      statusFilter: -1,
      search: "",
    };

    await exportEmployee(payload).then(async (response) => {
      let data = response.data;
      const workSheetName = "Employee Data";
      const workBookName = `Employee Information`;

      let columns = [
        { header: "First Name", key: "first_name" },
        { header: "Middle Name", key: "middle_name" },
        { header: "Last Name", key: "last_name" },
        { header: "Mobile", key: "mobile" },
        { header: "Email", key: "email" },
        { header: "Staff Number", key: "staff_number" },
        { header: "Bank Name", key: "bank_name" },
        { header: "Account Number", key: "account_number" },
        { header: "Gender", key: "gender" },
        { header: "Date Of Birth", key: "dob" },
        { header: "Employment Date", key: "date_joined" },
        { header: "Marital Status", key: "marital_status" },
        { header: "Job Position", key: "job_position" },
        { header: "Address", key: "street_address" },
        { header: "State", key: "state" },
        { header: "City", key: "city" },
        { header: "Country", key: "country" },
        { header: "PFA", key: "pfa_name" },
        { header: "RSA PIN", key: "rsa_pin" },
        { header: "Tax number", key: "tax_number" },
        { header: "Next Of Kin Name", key: "next_of_kin_name" },
        { header: "Next Of Kin Mobile", key: "next_of_kin_mobile" },
        { header: "Next Of Kin Email", key: "next_of_kin_email" },
        { header: "Next Of Kin Relationship", key: "next_of_kin_relationship" },
        { header: "Next Of Kin Address", key: "next_of_kin_address" },
        { header: "Status", key: "status" },
      ];
      try {
        const fileName = workBookName;

        const worksheet = workbook.addWorksheet(workSheetName);

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns;

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach((column) => {
          if (column._number === 5 || column._number === 18) {
            column.width = 23;
            column.style.alignment = { horizontal: "left" };
          } else if (column._number === 4) {
            column.width = 15;
            column.style.alignment = { horizontal: "left" };
          } else if (column._number === 14) {
            column.width = 30;
            column.style.alignment = { horizontal: "left" };
          } else {
            column.width = column.header.length + 2;
            column.style.alignment = { horizontal: "left" };
          }
        });

        // loop through data and add each one to worksheet
        data.forEach((singleData) => {
          if (singleData.status == 0) {
            singleData.status = "Inactive";
          } else if (singleData.status == 1) {
            singleData.status = "Active";
          } else if (singleData.status == 2) {
            singleData.status = "Pending";
          } else if (singleData.status == 3) {
            singleData.status = "Deleted";
          } else if (singleData.status == 2) {
            singleData.status = "Awaiting Activation";
          } else {
            singleData.status = "Pre-registered";
          }
          worksheet.addRow(singleData);
        });

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach((singleCell) => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
            const cellAddress = singleCell._address;

            if (
              singleCell._column._number == 26 &&
              singleCell._row._number > 1
            ) {
              if (singleCell._value.model.value == "Inactive") {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "D34829" },
                };
              } else if (singleCell._value.model.value == "Active") {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "0d5225" },
                };
              } else if (singleCell._value.model.value == "Pending") {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "FFA500" },
                };
              } else if (singleCell._value.model.value == "Deleted") {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "D34829" },
                };
              } else if (
                singleCell._value.model.value == "Awaiting Activation"
              ) {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "FFA500" },
                };
              } else {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "FFA500" },
                };
              }
            }

            // apply border
            worksheet.getCell(cellAddress).border = {
              top: { style: "none" },
              left: { style: "none" },
              bottom: { style: "none" },
              right: { style: "none" },
            };
          });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      } catch (error) {
      } finally {
        workbook.removeWorksheet(workSheetName);
      }
    });
    setSetExportLoading(false);
  };

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showGenerateLinkModal, setShowGenerateLinkModal] = useState(false);

  const [showAddEmployeeToHr, setShowAddEmployeeToHr] = useState(false);
  const [employeeHrData, setEmployeeHrData] = useState();

  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  const [showSendOfferLetter, setShowSendOfferLetter] = useState(false);

  const [applicant, setApplicant] = useState(null);

  const { mutateAsync: bulkActivate } = useBulkActivateEmployees();
  const { mutateAsync: bulkDeactivate } = useBulkDeactivateEmployees();

  const { mutateAsync: pendingActivateStatusChange } =
    useActivateOrDeactivatePendingEmployee();
  const { mutateAsync: preActivateStatusChange } =
    useActivateOrDeactivatePreEmployee();
  const { mutateAsync: ActivateOrDeactivate } =
    useActivateOrDeactivateEmployee();
  const { mutateAsync: resendInvite } = useResendInviteEmail();

  const { mutateAsync: resendMobileInvite } = useResendMobileInviteEmail();

  const { mutateAsync: deleteBulkEmployee } = useBulkDeleteEmployee();
  const { mutateAsync: deleteEmployee, isLoading: deleteLoading } =
    useDeleteEmployee();

  const [tableType, setTableType] = useState("employee");

  const switchFunction = () => {
    if (tableType === "employee") {
      setTableType("table");
    } else {
      setTableType("employee");
    }
  };

  const history = useHistory();

  const tableConfig = {
    headers: [
      "Name",
      "Email",
      "Mobile",
      "Date employed",
      "Status",
      "HR Status",
    ],
    keys: [
      "employeeName",
      "email",
      "mobile",
      "date_joined",
      "status",
      "hr_status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Status",
        key: "status",
      },
    },
  };

  const deleteSingleEmployee = async (id) => {
    let index = employees.data.findIndex((item) => item.id === id);
    await deleteEmployee(employees.data[index].id).then(() => {
      setShowDoYouWant(false);
    });
  };

  async function Continue(id, type) {
    let index = employees.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      if (type === "view") {
        history.push("/employees/view/" + employees.data[index].id);
      } else if (type === "pending-activate") {
        pendingActivateStatusChange({
          id: employees.data[index].id,
          type: "activate",
        });
      } else if (type === "pending-deactivate") {
        pendingActivateStatusChange({
          id: employees.data[index].id,
          type: "deactivate",
        });
      } else if (type === "pre-activate") {
        preActivateStatusChange({
          id: employees.data[index].id,
          type: "activate",
        });
      } else if (type === "pre-deactivate") {
        preActivateStatusChange({
          id: employees.data[index].id,
          type: "deactivate",
        });
      } else if (type === "activate") {
        ActivateOrDeactivate(employees.data[index].id);
      } else if (type === "resend-invite") {
        resendInvite(employees.data[index].id);
      } else if (type === "add-to-hr") {
        setEmployeeHrData({
          employee_ids: employees.data[index].id.toString(),
        });
        setShowAddEmployeeToHr(true);
      } else if (type === "upload-pix") {
        setModalData(employees.data[index]);
        setShowProfileModal(true);
      } else if (type === "resend-mobile") {
        resendMobileInvite(employees.data[index].id);
      } else if (type === "send-offer-letter") {
        setApplicant(employees.data[index]);
        setShowSendOfferLetter(true);
      }
    }
  }

  const actionConfig = [
    {
      name: "View",
      color: "orange",
      action: (id) => {
        Continue(id, "view");
      },
    },
    {
      name: "Activate",
      color: "green",
      action: (id) => {
        Continue(id, "pending-activate");
      },
    },
    {
      name: "Make Inactive",
      color: "red",
      action: (id) => {
        Continue(id, "pending-deactivate");
      },
    },
    {
      name: "Activate",
      color: "green",
      action: (id) => {
        Continue(id, "activate");
      },
    },
    {
      name: "Make Inactive",
      color: "red",
      action: (id) => {
        Continue(id, "activate");
      },
    },
    {
      name: "Resend Invite",
      color: "green",
      action: (id) => {
        Continue(id, "resend-invite");
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = employees.data.findIndex((item) => item.id === id);
        setDeleteId(employees.data[index].id);
        setShowDoYouWant(true);
      },
    },
    {
      name: "Add To HR",
      color: "green",
      action: (id) => {
        Continue(id, "add-to-hr");
      },
    },
    {
      name: "Upload Pix",
      color: "green",
      action: (id) => {
        Continue(id, "upload-pix");
      },
    },
    {
      name: "Resend Mobile Link",
      color: "green",
      action: (id) => {
        Continue(id, "resend-mobile");
      },
    },
    {
      name: "Activate",
      color: "green",
      action: (id) => {
        Continue(id, "pre-activate");
      },
    },
    {
      name: "Make Inactive",
      color: "red",
      action: (id) => {
        Continue(id, "pre-deactivate");
      },
    },
    {
      name: "Send Offer Letter",
      color: "green",
      action: (id) => {
        Continue(id, "send-offer-letter");
      },
    },
  ];

  function gotoEmployeeOnboarding() {
    history.push("/employees/onboarding/select-option");
  }

  const bulkActionConfig = [
    {
      label: "Activate",
      value: "active",
      action: (data) => {
        let newData = data.split(",");
        let result = [];

        newData.forEach((id) => {
          let index = employees.data.findIndex((item) => item.id == id);
          if (index != -1) {
            result.push(employees.data[index].id);
          }
        });
        bulkActivate(result.join(","));
      },
    },
    {
      label: "Make Inactive",
      value: "make-active",
      action: (data) => {
        let newData = data.split(",");
        let result = [];

        newData.forEach((id) => {
          let index = employees.data.findIndex((item) => item.id == id);
          if (index != -1) {
            result.push(employees.data[index].id);
          }
        });
        bulkDeactivate(result.join(","));
      },
    },
    {
      label: "Add to HR",
      value: "add-employees-to-hr",
      action: (data) => {
        let newData = data.split(",");
        let result = [];
        newData.forEach((id) => {
          let index = employees.data.findIndex((item) => item.id == id);
          if (index != -1 && employees.data[index].hr_status != 1) {
            result.push(employees.data[index].id);
          }
        });
        if (result.length > 0) {
          setEmployeeHrData({ employee_ids: result.join(",") });
          setShowAddEmployeeToHr(true);
        } else {
          store.dispatch(
            setAlert(
              true,
              "info",
              "Employees Selected are already in People Plan"
            )
          );
        }
      },
    },
    {
      label: "Delete",
      value: "delete-employees",
      action: (data) => {
        let newData = data.split(",");
        let result = [];

        newData.forEach((id) => {
          let index = employees.data.findIndex((item) => item.id == id);
          if (index != -1) {
            result.push(employees.data[index].id);
          }
        });
        deleteBulkEmployee({ employee_ids: result.join(",") });
      },
    },
  ];

  return (
    <div className="flex flex-col ">
      <div className="flex items-center justify-end w-full mt-4">
        <img
          className="animate-bounce cursor-pointer hover:animate-none hover:scale-110 duration-300"
          onClick={() => {
            setShowInfoModal(true);
          }}
          src={section.name == "payroll" ? HelpSVG : HrHelpSVG}
          alt="help!"
          width={"40px"}
          height={"10px"}
        />
      </div>
      <div className="flex flex-col bg-white rounded border">
        {showDoYouWant ? (
          <DoYouWantToModal
            isOpen={showDoYouWant}
            closeModal={() => setShowDoYouWant(false)}
            id={deleteId}
            loading={deleteLoading}
            title={"Are you sure you want to delete this employee?"}
            buttonText={"DELETE"}
            btnFunction={deleteSingleEmployee}
          />
        ) : null}
        {showProfileModal ? (
          <UploadProfilePix
            isOpen={showProfileModal}
            closeModal={() => setShowProfileModal(false)}
            employee={modalData}
            title={"Upload Profile Picture"}
            buttonText={"Save"}
          />
        ) : null}
        {showAddEmployeeToHr ? (
          <AddEmployeeToHrSubscription
            isOpen={showAddEmployeeToHr}
            source="selective"
            employeeHrData={employeeHrData}
            closeModal={() => setShowAddEmployeeToHr(false)}
          />
        ) : null}
        {showInfoModal ? (
          <InfoModal
            isOpen={showInfoModal}
            type={"employee"}
            closeModal={() => setShowInfoModal(false)}
          />
        ) : null}
        {showGenerateLinkModal ? (
          <InvitationLinkModal
            isOpen={showGenerateLinkModal}
            type={"employee"}
            closeModal={() => setShowGenerateLinkModal(false)}
          />
        ) : null}
        {showSendOfferLetter ? (
          <SendOfferLetter
            title={"Send Offer Letter"}
            isOpen={showSendOfferLetter}
            closeModal={() => setShowSendOfferLetter(false)}
            applicant={applicant}
            source={"employee"}
            buttonText="SEND LETTER"
          />
        ) : null}
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[25%] w-full">
            Employees
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[75%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="New Employee(s)"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                onClick={() => {
                  gotoEmployeeOnboarding();
                }}
              />
            </div>
            <div className="md:w-fit w-[100%]">
              <Button
                text="Export Employee Data"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"export"}
                loading={exportLoading}
                onClick={() => {
                  exportEmployeeData();
                }}
              />
            </div>
            <div className="md:w-fit w-[100%]">
              <Button
                text="Refresh Table"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"change"}
                onClick={() => {
                  refetch();
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={employees}
          tableConfig={tableConfig}
          statusConfig={employeeStatusConfig}
          actionConfig={actionConfig}
          bulkActionConfig={bulkActionConfig}
          updatePagination={(data) => setPagination(data)}
          filterByStatus={true}
          computedActions={true}
          tableType={tableType}
          showProfile={true}
          switchFunction={switchFunction}
          layoutSwitch={true}
          hrStatusConfig={employeehrStatusConfig}
          searchData={true}
        />
      </div>
    </div>
  );
};

export default OverviewTab;
