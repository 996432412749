import React, { useEffect, useRef } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import {
  employeeStatusConfig,
  PAGINATION_DEFAULT,
} from "../../utils/constants";
import { useHistory } from "react-router-dom";
import {
  useDeleteSalary,
  useGetEmployeeSalaries,
  useGetEmployeeSalariesExport,
} from "../../redux/employees/hook/salaries";
import Button from "../button";
import SalaryReviews from "../modal/employees/salary-reviews";
import { useSelector } from "react-redux";
import HelpSVG from "../../assets/svg/help.svg";
import HrHelpSVG from "../../assets/svg/hr-help.svg";
import InfoModal from "../modal/info-modal";
import { useGetEmployees } from "../../redux/employees/hook";
import RunBasicPayroll from "../modal/payroll/run-basic-payroll";
import RunAdvancedPayroll from "../modal/payroll/run-advanced-payroll";
import Excel from "exceljs";
import { saveAs } from "file-saver";

const EmployeeSalariesTab = () => {
  useEffect(() => {}, []);

  const { section } = useSelector((state) => state.sectionSlice);
  const { data: employees } = useGetEmployees(1, 1, -1, "");
  const { mutateAsync: deleteCompensation } = useDeleteSalary();

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const [tableType, setTableType] = useState("table");

  const switchFunction = () => {
    if (tableType === "salary") {
      setTableType("table");
    } else {
      setTableType("salary");
    }
  };

  const { refetch, data: employeeSalaries } = useGetEmployeeSalaries(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const [showInfoModal, setShowInfoModal] = useState(false);

  // const [showPayrollOptions, setShowPayrollOptions] = useState(false);
  const [showPayEmployees, setShowPayEmployees] = useState(false);
  const [showRunPayroll, setShowRunPayroll] = useState(false);
  const [showSalaryReviews, setShowSalaryReviews] = useState(false);
  const [payrollData, setPayrollData] = useState("");
  const [dropOpen, setDropOpen] = useState(false);

  const wrapperRef = useRef(null);
  const moreButtonRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target))
        setDropOpen(false);
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      setDropOpen(false);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, moreButtonRef]);

  const history = useHistory();

  const tableConfig = {
    // title: 'Salary Management',
    headers: [
      "Employee Name",
      "Mobile",
      "Basic",
      "Transportation",
      "Housing",
      "Others",
      "Gross pay",
      "Status",
    ],
    keys: [
      "employeeName",
      "phone",
      "formatted_basic_salary",
      "formatted_transportation_allowance",
      "formatted_housing_allowance",
      "formatted_other_accum_allowance",
      "formatted_gross_pay",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Mobile",
        key: "phone",
      },
    },
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = employeeSalaries.data.findIndex((item) => item.id === id);

        if (index != -1) {
          history.push(
            `/employees/view/${employeeSalaries.data[index].employee}`
          );
        }
      },
    },
    {
      name: "Update",
      color: "orange",
      action: (id) => {
        let index = employeeSalaries.data.findIndex((item) => item.id === id);
        if (index != -1) {
          history.push(
            `/employees/onboarding/add-salary-information?type=single&salary=true&update=true`
          );
        }
      },
    },
    {
      name: "Delete Salary",
      color: "red",
      action: (id) => {
        let index = employeeSalaries.data.findIndex((item) => item.id === id);
        if (index != -1) {
          deleteCompensation(employeeSalaries.data[index].id);
        }
      },
    },
  ];

  function openPayroll(data) {
    let newData = data.split(",");
    let result = [];

    newData.forEach((id) => {
      let index = employeeSalaries.data.findIndex((item) => item.id == id);
      if (index != -1) {
        result.push(employeeSalaries.data[index].employee);
      }
    });

    setPayrollData(result.join(","));
    setShowPayEmployees(true);
  }

  function runPayroll(data) {
    let newData = data.split(",");
    let result = [];

    newData.forEach((id) => {
      let index = employeeSalaries.data.findIndex((item) => item.id == id);
      if (index != -1) {
        result.push(employeeSalaries.data[index].employee);
      }
    });

    setPayrollData(result.join(","));
    setShowRunPayroll(true);
  }

  const { mutateAsync: exportSalaries, isLoading: exportSalariesLoading } =
    useGetEmployeeSalariesExport();

  const workbook = new Excel.Workbook();

  const [exportLoading, setSetExportLoading] = useState(false);

  const exportSalariesFn = async () => {
    setSetExportLoading(true);
    let payload = {
      limit: 100000,
      page: 1,
      statusFilter: 1,
      search: "",
    };

    await exportSalaries(payload).then(async (response) => {
      let data = response.data;
      const workSheetName = "salaries-data";
      const workBookName = `Salaries Information`;
      let columns = [
        { header: "First Name", key: "first_name" },
        { header: "Last Name", key: "last_name" },
        { header: "Mobile", key: "phone" },
        { header: "Email", key: "email" },
        { header: "Staff Number", key: "staff_number" },
        { header: "Bank Name", key: "bank_name" },
        { header: "Account Number", key: "account_number" },
        { header: "Basic Salary", key: "basic_salary" },
        { header: "Housing Allowance", key: "housing_allowance" },
        {
          header: "Transport Allowance",
          key: "transportation_allowance",
        },
        { header: "Leave Allowance", key: "leave_allowance" },
        { header: "Lunch Allowance", key: "lunch_allowance" },
        { header: "Benefit In Kind", key: "benefit_in_kind" },
        { header: "Utility Allowance", key: "utility_allowance" },
        { header: "Other Allowance", key: "other_allowance" },
      ];

      await data.forEach((item) => {
        let newAllowanceItem = [];
        if (
          item.additional_allowances &&
          item.additional_allowances != "null" &&
          Array.isArray(JSON.parse(item.additional_allowances)) &&
          JSON.parse(item.additional_allowances?.length > 0)
        ) {
          JSON.parse(item.additional_allowances).forEach((subItem) => {
            let description = subItem.title;
            let headersArray = [];
            columns.forEach((columnHeaders) => {
              headersArray.push(columnHeaders.header);
            });
            for (let i = 0; i < columns.length; i++) {
              if (!headersArray.includes(description)) {
                let singleItemList = null;
                if (newAllowanceItem.length > 0) {
                  for (let j = 0; j < newAllowanceItem.length; j++) {
                    if (newAllowanceItem[j].header == description) {
                      if (
                        description !== "Leave Allowance" ||
                        description !== "Lunch Allowance" ||
                        description !== "Benefit In Kind" ||
                        description !== "Utility Allowance" ||
                        description !== "Other Allowance"
                      ) {
                        singleItemList = null;
                        break;
                      } else {
                        description = description + "(1)";
                        if (newAllowanceItem[j].header == description) {
                          singleItemList = null;
                          break;
                        } else {
                          singleItemList = {
                            header: description,
                            key: description,
                          };
                        }
                      }
                    } else {
                      singleItemList = {
                        header: description,
                        key: description,
                      };
                    }
                  }
                } else {
                  singleItemList = { header: description, key: description };
                }
                if (singleItemList) {
                  newAllowanceItem.push(singleItemList);
                }
              }
            }
          });
          columns.push(...newAllowanceItem);
        }
      });

      let gArray = [
        { header: "Gross Pay", key: "gross_pay" },
        { header: "Net Pay", key: "net_pay" },
      ];

      columns.push(...gArray);

      try {
        const fileName = workBookName;

        const worksheet = workbook.addWorksheet(workSheetName);

        worksheet.columns = columns;

        worksheet.getRow(1).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach((column) => {
          column.width = 23;
          if (column._number > 7) {
            column.width = 23;
            column.style.alignment = { horizontal: "right" };
            column.numFmt = "#,##0.00;[Red]-#,##0.00";
          } else {
            column.width = column.header.length + 2;
            column.style.alignment = { horizontal: "left" };
          }
        });

        // loop through data and add each one to worksheet
        await data.forEach((singleData) => {
          if (
            singleData.additional_allowances &&
            singleData.additional_allowances != "null" &&
            Array.isArray(JSON.parse(singleData.additional_allowances)) &&
            JSON.parse(singleData.additional_allowances?.length > 0)
          ) {
            JSON.parse(singleData.additional_allowances).forEach((item) => {
              singleData[item.title] = parseFloat(item.amount);
            });
          }

          if (singleData.status == 0) {
            singleData.status = "Inactive";
          } else if (singleData.status == 1) {
            singleData.status = "Active";
          } else if (singleData.status == 2) {
            singleData.status = "Pending";
          } else if (singleData.status == 3) {
            singleData.status = "Deleted";
          } else if (singleData.status == 2) {
            singleData.status = "Awaiting Activation";
          } else {
            singleData.status = "Pre-registered";
          }
          worksheet.addRow(singleData);
        });

        // loop through all of the rows and set the outline style.
        // worksheet.eachRow({ includeEmpty: false }, (row) => {
        //   // store each cell to currentCell
        //   const currentCell = row._cells;

        //   // loop through currentCell to apply border only for the non-empty cell of excel
        //   currentCell.forEach((singleCell) => {
        //     // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
        //     // const cellAddress = singleCell._address;
        //     // // apply border
        //     // worksheet.getCell(cellAddress).border = {
        //     //   top: { style: 'thin' },
        //     //   left: { style: 'thin' },
        //     //   bottom: { style: 'thin' },
        //     //   right: { style: 'thin' }
        //     // };
        //   });
        // });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      } catch (error) {
      } finally {
        workbook.removeWorksheet(workSheetName);
      }
    });
    setSetExportLoading(false);
  };

  const bulkActionConfig = [
    {
      label: "Run Payroll",
      value: "run-payroll",
      action: (data) => {
        runPayroll(data);
      },
    },
    {
      label: "Pay Employee(s)",
      value: "pay-employees",
      action: (data) => {
        openPayroll(data);
      },
    },
  ];

  return (
    <div className="flex flex-col mt-4">
      <div className="flex items-center justify-end w-full">
        <img
          className="animate-bounce cursor-pointer hover:animate-none hover:scale-110 duration-300"
          onClick={() => {
            setShowInfoModal(true);
          }}
          src={section.name == "payroll" ? HelpSVG : HrHelpSVG}
          alt="help!"
          width={"40px"}
          height={"10px"}
        />
      </div>
      <div className="flex flex-col bg-white rounded border">
        {showSalaryReviews ? (
          <SalaryReviews
            isOpen={showSalaryReviews}
            closeModal={() => setShowSalaryReviews(false)}
          />
        ) : null}
        {showInfoModal ? (
          <InfoModal
            isOpen={showInfoModal}
            type={"salary"}
            closeModal={() => setShowInfoModal(false)}
          />
        ) : null}
        {showPayEmployees ? (
          <RunBasicPayroll
            isOpen={showPayEmployees}
            source="selective"
            sourceData={payrollData}
            closeModal={() => setShowPayEmployees(false)}
          />
        ) : null}
        {showRunPayroll ? (
          <RunAdvancedPayroll
            isOpen={showRunPayroll}
            source="selective"
            sourceData={payrollData}
            closeModal={() => setShowRunPayroll(false)}
          />
        ) : null}
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3border">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[25%] w-full">
            Salaries
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[75%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="New Salary"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                disabled={!employees}
                onClick={() => {
                  history.push(
                    "/employees/onboarding/select-option?salary=true"
                  );
                }}
              />
            </div>
            <div className="md:w-fit w-[100%]">
              <Button
                text="Refetch"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"change"}
                onClick={() => {
                  refetch();
                }}
              />
            </div>
            <div
              ref={moreButtonRef}
              className="flex flex-col gap-2 md:w-fit w-[100%] relative"
            >
              <Button
                text="More Options"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center bg-green-800"
                textClass={"!text-[11px]"}
                rightIcon={"forward"}
                loading={exportLoading}
                onClick={() => setDropOpen(!dropOpen)}
              />
              <div
                ref={wrapperRef}
                className={`${
                  dropOpen ? "flex " : "hidden "
                } z-50 bg-white divide-y divide-gray-100 rounded-sm md:w-44 w-full dark:bg-gray-700 absolute top-[42px] focus:pointer-events-auto`}
              >
                <ul className="flex flex-col text-[13px] text-gray-700 w-full px-0">
                  <li className="min-w-full">
                    <div
                      onClick={() =>
                        history.push(
                          "/employees/review/review-salary-information?type=single&salary=true"
                        )
                      }
                      className="block px-4 py-2 hover:bg-gray-100 w-full cursor-pointer"
                    >
                      Review Salary
                    </div>
                  </li>
                  <li className="min-w-full">
                    <div
                      onClick={() => setShowSalaryReviews(true)}
                      className="block px-4 py-2 hover:bg-gray-100 w-full cursor-pointer"
                    >
                      View Salary Reviews
                    </div>
                  </li>
                  <li className="min-w-full">
                    <div
                      onClick={() => exportSalariesFn()}
                      className="block px-4 py-2 hover:bg-gray-100 w-full cursor-pointer"
                    >
                      Export Salary Data
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={employeeSalaries}
          tableConfig={tableConfig}
          statusConfig={employeeStatusConfig}
          actionConfig={actionConfig}
          bulkActionConfig={
            section.name === "core hr" ? false : bulkActionConfig
          }
          updatePagination={(data) => setPagination(data)}
          filterByStatus={true}
          searchData={true}
          computedActions={true}
          tableType={tableType}
          switchFunction={switchFunction}
          layoutSwitch={true}
        />
      </div>
    </div>
  );
};

export default EmployeeSalariesTab;
