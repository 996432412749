export const currencies = [
  {
    symbol: "₦",
    label: "Nigerian Naira",
    symbol_native: "₦",
    decimal_digits: 2,
    rounding: 0,
    value: "NGN",
    name_plural: "Nigerian nairas",
  },
  {
    symbol: "$",
    label: "US Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "USD",
    name_plural: "US dollars",
  },
  {
    symbol: "CA$",
    label: "Canadian Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "CAD",
    name_plural: "Canadian dollars",
  },
  {
    symbol: "€",
    label: "Euro",
    symbol_native: "€",
    decimal_digits: 2,
    rounding: 0,
    value: "EUR",
    name_plural: "euros",
  },
  //   {
  //     symbol: "AED",
  //     label: "United Arab Emirates Dirham",
  //     symbol_native: "د.إ.‏",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "AED",
  //     name_plural: "UAE dirhams",
  //   },
  //   {
  //     symbol: "Af",
  //     label: "Afghan Afghani",
  //     symbol_native: "؋",
  //     decimal_digits: 0,
  //     rounding: 0,
  //     value: "AFN",
  //     name_plural: "Afghan Afghanis",
  //   },
  //   {
  //     symbol: "ALL",
  //     label: "Albanian Lek",
  //     symbol_native: "Lek",
  //     decimal_digits: 0,
  //     rounding: 0,
  //     value: "ALL",
  //     name_plural: "Albanian lekë",
  //   },
  //   {
  //     symbol: "AMD",
  //     label: "Armenian Dram",
  //     symbol_native: "դր.",
  //     decimal_digits: 0,
  //     rounding: 0,
  //     value: "AMD",
  //     name_plural: "Armenian drams",
  //   },
  //   {
  //     symbol: "AR$",
  //     label: "Argentine Peso",
  //     symbol_native: "$",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "ARS",
  //     name_plural: "Argentine pesos",
  //   },
  //   {
  //     symbol: "AU$",
  //     label: "Australian Dollar",
  //     symbol_native: "$",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "AUD",
  //     name_plural: "Australian dollars",
  //   },
  //   {
  //     symbol: "man.",
  //     label: "Azerbaijani Manat",
  //     symbol_native: "ман.",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "AZN",
  //     name_plural: "Azerbaijani manats",
  //   },
  //   {
  //     symbol: "KM",
  //     label: "Bosnia-Herzegovina Convertible Mark",
  //     symbol_native: "KM",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "BAM",
  //     name_plural: "Bosnia-Herzegovina convertible marks",
  //   },
  //   {
  //     symbol: "Tk",
  //     label: "Bangladeshi Taka",
  //     symbol_native: "৳",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "BDT",
  //     name_plural: "Bangladeshi takas",
  //   },
  //   {
  //     symbol: "BGN",
  //     label: "Bulgarian Lev",
  //     symbol_native: "лв.",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "BGN",
  //     name_plural: "Bulgarian leva",
  //   },
  //   {
  //     symbol: "BD",
  //     label: "Bahraini Dinar",
  //     symbol_native: "bd",
  //     decimal_digits: 3,
  //     rounding: 0,
  //     value: "BHD",
  //     name_plural: "Bahraini dinars",
  //   },
  //   {
  //     symbol: "FBu",
  //     label: "Burundian Franc",
  //     symbol_native: "FBu",
  //     decimal_digits: 0,
  //     rounding: 0,
  //     value: "BIF",
  //     name_plural: "Burundian francs",
  //   },
  //   {
  //     symbol: "BN$",
  //     label: "Brunei Dollar",
  //     symbol_native: "$",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "BND",
  //     name_plural: "Brunei dollars",
  //   },
  //   {
  //     symbol: "Bs",
  //     label: "Bolivian Boliviano",
  //     symbol_native: "Bs",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "BOB",
  //     name_plural: "Bolivian bolivianos",
  //   },
  //   {
  //     symbol: "R$",
  //     label: "Brazilian Real",
  //     symbol_native: "R$",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "BRL",
  //     name_plural: "Brazilian reals",
  //   },
  {
    symbol: "BWP",
    label: "Botswanan Pula",
    symbol_native: "P",
    decimal_digits: 2,
    rounding: 0,
    value: "BWP",
    name_plural: "Botswanan pulas",
  },
  //   {
  //     symbol: "Br",
  //     label: "Belarusian Ruble",
  //     symbol_native: "руб.",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "BYN",
  //     name_plural: "Belarusian rubles",
  //   },
  //   {
  //     symbol: "BZ$",
  //     label: "Belize Dollar",
  //     symbol_native: "$",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "BZD",
  //     name_plural: "Belize dollars",
  //   },
  //   {
  //     symbol: "CDF",
  //     label: "Congolese Franc",
  //     symbol_native: "FrCD",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "CDF",
  //     name_plural: "Congolese francs",
  //   },
  {
    symbol: "CHF",
    label: "Swiss Franc",
    symbol_native: "CHF",
    decimal_digits: 2,
    rounding: 0.05,
    value: "CHF",
    name_plural: "Swiss francs",
  },
  //   {
  //     symbol: "CL$",
  //     label: "Chilean Peso",
  //     symbol_native: "$",
  //     decimal_digits: 0,
  //     rounding: 0,
  //     value: "CLP",
  //     name_plural: "Chilean pesos",
  //   },
  {
    symbol: "CN¥",
    label: "Chinese Yuan",
    symbol_native: "CN¥",
    decimal_digits: 2,
    rounding: 0,
    value: "CNY",
    name_plural: "Chinese yuan",
  },
  //   {
  //     symbol: "CO$",
  //     label: "Colombian Peso",
  //     symbol_native: "$",
  //     decimal_digits: 0,
  //     rounding: 0,
  //     value: "COP",
  //     name_plural: "Colombian pesos",
  //   },
  //   {
  //     symbol: "₡",
  //     label: "Costa Rican Colón",
  //     symbol_native: "₡",
  //     decimal_digits: 0,
  //     rounding: 0,
  //     value: "CRC",
  //     name_plural: "Costa Rican colóns",
  //   },
  //   {
  //     symbol: "CV$",
  //     label: "Cape Verdean Escudo",
  //     symbol_native: "CV$",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "CVE",
  //     name_plural: "Cape Verdean escudos",
  //   },
  //   {
  //     symbol: "Kč",
  //     label: "Czech Republic Koruna",
  //     symbol_native: "Kč",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "CZK",
  //     name_plural: "Czech Republic korunas",
  //   },
  //   {
  //     symbol: "Fdj",
  //     label: "Djiboutian Franc",
  //     symbol_native: "Fdj",
  //     decimal_digits: 0,
  //     rounding: 0,
  //     value: "DJF",
  //     name_plural: "Djiboutian francs",
  //   },
  //   {
  //     symbol: "Dkr",
  //     label: "Danish Krone",
  //     symbol_native: "kr",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "DKK",
  //     name_plural: "Danish kroner",
  //   },
  //   {
  //     symbol: "RD$",
  //     label: "Dominican Peso",
  //     symbol_native: "RD$",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "DOP",
  //     name_plural: "Dominican pesos",
  //   },
  {
    symbol: "DA",
    label: "Algerian Dinar",
    symbol_native: "د.ج.‏",
    decimal_digits: 2,
    rounding: 0,
    value: "DZD",
    name_plural: "Algerian dinars",
  },
  //   {
  //     symbol: "Ekr",
  //     label: "Estonian Kroon",
  //     symbol_native: "kr",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "EEK",
  //     name_plural: "Estonian kroons",
  //   },
  {
    symbol: "EGP",
    label: "Egyptian Pound",
    symbol_native: "ج.م.‏",
    decimal_digits: 2,
    rounding: 0,
    value: "EGP",
    name_plural: "Egyptian pounds",
  },
  //   {
  //     symbol: "Nfk",
  //     label: "Eritrean Nakfa",
  //     symbol_native: "Nfk",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "ERN",
  //     name_plural: "Eritrean nakfas",
  //   },
  {
    symbol: "Br",
    label: "Ethiopian Birr",
    symbol_native: "Br",
    decimal_digits: 2,
    rounding: 0,
    value: "ETB",
    name_plural: "Ethiopian birrs",
  },
  {
    symbol: "£",
    label: "British Pound Sterling",
    symbol_native: "£",
    decimal_digits: 2,
    rounding: 0,
    value: "GBP",
    name_plural: "British pounds sterling",
  },
  //   {
  //     symbol: "GEL",
  //     label: "Georgian Lari",
  //     symbol_native: "GEL",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "GEL",
  //     name_plural: "Georgian laris",
  //   },
  {
    symbol: "GH₵",
    label: "Ghanaian Cedi",
    symbol_native: "GH₵",
    decimal_digits: 2,
    rounding: 0,
    value: "GHS",
    name_plural: "Ghanaian cedis",
  },
  {
    symbol: "FG",
    label: "Guinean Franc",
    symbol_native: "FG",
    decimal_digits: 0,
    rounding: 0,
    value: "GNF",
    name_plural: "Guinean francs",
  },
  //   {
  //     symbol: "GTQ",
  //     label: "Guatemalan Quetzal",
  //     symbol_native: "Q",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "GTQ",
  //     name_plural: "Guatemalan quetzals",
  //   },
  //   {
  //     symbol: "HK$",
  //     label: "Hong Kong Dollar",
  //     symbol_native: "$",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "HKD",
  //     name_plural: "Hong Kong dollars",
  //   },
  //   {
  //     symbol: "HNL",
  //     label: "Honduran Lempira",
  //     symbol_native: "L",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "HNL",
  //     name_plural: "Honduran lempiras",
  //   },
  //   {
  //     symbol: "kn",
  //     label: "Croatian Kuna",
  //     symbol_native: "kn",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "HRK",
  //     name_plural: "Croatian kunas",
  //   },
  //   {
  //     symbol: "Ft",
  //     label: "Hungarian Forint",
  //     symbol_native: "Ft",
  //     decimal_digits: 0,
  //     rounding: 0,
  //     value: "HUF",
  //     name_plural: "Hungarian forints",
  //   },
  //   {
  //     symbol: "Rp",
  //     label: "Indonesian Rupiah",
  //     symbol_native: "Rp",
  //     decimal_digits: 0,
  //     rounding: 0,
  //     value: "IDR",
  //     name_plural: "Indonesian rupiahs",
  //   },
  //   {
  //     symbol: "₪",
  //     label: "Israeli New Sheqel",
  //     symbol_native: "₪",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "ILS",
  //     name_plural: "Israeli new sheqels",
  //   },
  //   {
  //     symbol: "Rs",
  //     label: "Indian Rupee",
  //     symbol_native: "টকা",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "INR",
  //     name_plural: "Indian rupees",
  //   },
  //   {
  //     symbol: "IQD",
  //     label: "Iraqi Dinar",
  //     symbol_native: "د.ع.‏",
  //     decimal_digits: 0,
  //     rounding: 0,
  //     value: "IQD",
  //     name_plural: "Iraqi dinars",
  //   },
  //   {
  //     symbol: "IRR",
  //     label: "Iranian Rial",
  //     symbol_native: "﷼",
  //     decimal_digits: 0,
  //     rounding: 0,
  //     value: "IRR",
  //     name_plural: "Iranian rials",
  //   },
  //   {
  //     symbol: "Ikr",
  //     label: "Icelandic Króna",
  //     symbol_native: "kr",
  //     decimal_digits: 0,
  //     rounding: 0,
  //     value: "ISK",
  //     name_plural: "Icelandic krónur",
  //   },
  //   {
  //     symbol: "J$",
  //     label: "Jamaican Dollar",
  //     symbol_native: "$",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "JMD",
  //     name_plural: "Jamaican dollars",
  //   },
  //   {
  //     symbol: "JD",
  //     label: "Jordanian Dinar",
  //     symbol_native: "د.أ.‏",
  //     decimal_digits: 3,
  //     rounding: 0,
  //     value: "JOD",
  //     name_plural: "Jordanian dinars",
  //   },
  {
    symbol: "¥",
    label: "Japanese Yen",
    symbol_native: "￥",
    decimal_digits: 0,
    rounding: 0,
    value: "JPY",
    name_plural: "Japanese yen",
  },
  {
    symbol: "Ksh",
    label: "Kenyan Shilling",
    symbol_native: "Ksh",
    decimal_digits: 2,
    rounding: 0,
    value: "KES",
    name_plural: "Kenyan shillings",
  },
  //   {
  //     symbol: "KHR",
  //     label: "Cambodian Riel",
  //     symbol_native: "៛",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "KHR",
  //     name_plural: "Cambodian riels",
  //   },
  //   {
  //     symbol: "CF",
  //     label: "Comorian Franc",
  //     symbol_native: "FC",
  //     decimal_digits: 0,
  //     rounding: 0,
  //     value: "KMF",
  //     name_plural: "Comorian francs",
  //   },
  {
    symbol: "₩",
    label: "South Korean Won",
    symbol_native: "₩",
    decimal_digits: 0,
    rounding: 0,
    value: "KRW",
    name_plural: "South Korean won",
  },
  {
    symbol: "KD",
    label: "Kuwaiti Dinar",
    symbol_native: "د.ك.‏",
    decimal_digits: 3,
    rounding: 0,
    value: "KWD",
    name_plural: "Kuwaiti dinars",
  },
  //   {
  //     symbol: "KZT",
  //     label: "Kazakhstani Tenge",
  //     symbol_native: "тңг.",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "KZT",
  //     name_plural: "Kazakhstani tenges",
  //   },
  //   {
  //     symbol: "L.L.",
  //     label: "Lebanese Pound",
  //     symbol_native: "ل.ل.‏",
  //     decimal_digits: 0,
  //     rounding: 0,
  //     value: "LBP",
  //     name_plural: "Lebanese pounds",
  //   },
  //   {
  //     symbol: "SLRs",
  //     label: "Sri Lankan Rupee",
  //     symbol_native: "SL Re",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "LKR",
  //     name_plural: "Sri Lankan rupees",
  //   },
  //   {
  //     symbol: "Lt",
  //     label: "Lithuanian Litas",
  //     symbol_native: "Lt",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "LTL",
  //     name_plural: "Lithuanian litai",
  //   },
  //   {
  //     symbol: "Ls",
  //     label: "Latvian Lats",
  //     symbol_native: "Ls",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "LVL",
  //     name_plural: "Latvian lati",
  //   },
  //   {
  //     symbol: "LD",
  //     label: "Libyan Dinar",
  //     symbol_native: "د.ل.‏",
  //     decimal_digits: 3,
  //     rounding: 0,
  //     value: "LYD",
  //     name_plural: "Libyan dinars",
  //   },
  //   {
  //     symbol: "MAD",
  //     label: "Moroccan Dirham",
  //     symbol_native: "د.م.‏",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "MAD",
  //     name_plural: "Moroccan dirhams",
  //   },
  //   {
  //     symbol: "MDL",
  //     label: "Moldovan Leu",
  //     symbol_native: "MDL",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "MDL",
  //     name_plural: "Moldovan lei",
  //   },
  //   {
  //     symbol: "MGA",
  //     label: "Malagasy Ariary",
  //     symbol_native: "MGA",
  //     decimal_digits: 0,
  //     rounding: 0,
  //     value: "MGA",
  //     name_plural: "Malagasy Ariaries",
  //   },
  //   {
  //     symbol: "MKD",
  //     label: "Macedonian Denar",
  //     symbol_native: "MKD",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "MKD",
  //     name_plural: "Macedonian denari",
  //   },
  //   {
  //     symbol: "MMK",
  //     label: "Myanma Kyat",
  //     symbol_native: "K",
  //     decimal_digits: 0,
  //     rounding: 0,
  //     value: "MMK",
  //     name_plural: "Myanma kyats",
  //   },
  //   {
  //     symbol: "MOP$",
  //     label: "Macanese Pataca",
  //     symbol_native: "MOP$",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "MOP",
  //     name_plural: "Macanese patacas",
  //   },
  {
    symbol: "MURs",
    label: "Mauritian Rupee",
    symbol_native: "MURs",
    decimal_digits: 0,
    rounding: 0,
    value: "MUR",
    name_plural: "Mauritian rupees",
  },
  //   {
  //     symbol: "MX$",
  //     label: "Mexican Peso",
  //     symbol_native: "$",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "MXN",
  //     name_plural: "Mexican pesos",
  //   },
  {
    symbol: "RM",
    label: "Malaysian Ringgit",
    symbol_native: "RM",
    decimal_digits: 2,
    rounding: 0,
    value: "MYR",
    name_plural: "Malaysian ringgits",
  },
  //   {
  //     symbol: "MTn",
  //     label: "Mozambican Metical",
  //     symbol_native: "MTn",
  //     decimal_digits: 2,
  //     rounding: 0,
  //     value: "MZN",
  //     name_plural: "Mozambican meticals",
  //   },
  {
    symbol: "N$",
    label: "Namibian Dollar",
    symbol_native: "N$",
    decimal_digits: 2,
    rounding: 0,
    value: "NAD",
    name_plural: "Namibian dollars",
  },
//   {
//     symbol: "C$",
//     label: "Nicaraguan Córdoba",
//     symbol_native: "C$",
//     decimal_digits: 2,
//     rounding: 0,
//     value: "NIO",
//     name_plural: "Nicaraguan córdobas",
//   },
//   {
//     symbol: "Nkr",
//     label: "Norwegian Krone",
//     symbol_native: "kr",
//     decimal_digits: 2,
//     rounding: 0,
//     value: "NOK",
//     name_plural: "Norwegian kroner",
//   },
//   {
//     symbol: "NPRs",
//     label: "Nepalese Rupee",
//     symbol_native: "नेरू",
//     decimal_digits: 2,
//     rounding: 0,
//     value: "NPR",
//     name_plural: "Nepalese rupees",
//   },
//   {
//     symbol: "NZ$",
//     label: "New Zealand Dollar",
//     symbol_native: "$",
//     decimal_digits: 2,
//     rounding: 0,
//     value: "NZD",
//     name_plural: "New Zealand dollars",
//   },
//   {
//     symbol: "OMR",
//     label: "Omani Rial",
//     symbol_native: "ر.ع.‏",
//     decimal_digits: 3,
//     rounding: 0,
//     value: "OMR",
//     name_plural: "Omani rials",
//   },
//   {
//     symbol: "B/.",
//     label: "Panamanian Balboa",
//     symbol_native: "B/.",
//     decimal_digits: 2,
//     rounding: 0,
//     value: "PAB",
//     name_plural: "Panamanian balboas",
//   },
//   {
//     symbol: "S/.",
//     label: "Peruvian Nuevo Sol",
//     symbol_native: "S/.",
//     decimal_digits: 2,
//     rounding: 0,
//     value: "PEN",
//     name_plural: "Peruvian nuevos soles",
//   },
//   {
//     symbol: "₱",
//     label: "Philippine Peso",
//     symbol_native: "₱",
//     decimal_digits: 2,
//     rounding: 0,
//     value: "PHP",
//     name_plural: "Philippine pesos",
//   },
//   {
//     symbol: "PKRs",
//     label: "Pakistani Rupee",
//     symbol_native: "₨",
//     decimal_digits: 0,
//     rounding: 0,
//     value: "PKR",
//     name_plural: "Pakistani rupees",
//   },
//   {
//     symbol: "zł",
//     label: "Polish Zloty",
//     symbol_native: "zł",
//     decimal_digits: 2,
//     rounding: 0,
//     value: "PLN",
//     name_plural: "Polish zlotys",
//   },
//   {
//     symbol: "₲",
//     label: "Paraguayan Guarani",
//     symbol_native: "₲",
//     decimal_digits: 0,
//     rounding: 0,
//     value: "PYG",
//     name_plural: "Paraguayan guaranis",
//   },
  {
    symbol: "QR",
    label: "Qatari Rial",
    symbol_native: "ر.ق.‏",
    decimal_digits: 2,
    rounding: 0,
    value: "QAR",
    name_plural: "Qatari rials",
  },
//   {
//     symbol: "RON",
//     label: "Romanian Leu",
//     symbol_native: "RON",
//     decimal_digits: 2,
//     rounding: 0,
//     value: "RON",
//     name_plural: "Romanian lei",
//   },
//   {
//     symbol: "din.",
//     label: "Serbian Dinar",
//     symbol_native: "дин.",
//     decimal_digits: 0,
//     rounding: 0,
//     value: "RSD",
//     name_plural: "Serbian dinars",
//   },
  {
    symbol: "RUB",
    label: "Russian Ruble",
    symbol_native: "₽.",
    decimal_digits: 2,
    rounding: 0,
    value: "RUB",
    name_plural: "Russian rubles",
  },
  {
    symbol: "RWF",
    label: "Rwandan Franc",
    symbol_native: "FR",
    decimal_digits: 0,
    rounding: 0,
    value: "RWF",
    name_plural: "Rwandan francs",
  },
  {
    symbol: "SR",
    label: "Saudi Riyal",
    symbol_native: "ر.س.‏",
    decimal_digits: 2,
    rounding: 0,
    value: "SAR",
    name_plural: "Saudi riyals",
  },
//   {
//     symbol: "SDG",
//     label: "Sudanese Pound",
//     symbol_native: "SDG",
//     decimal_digits: 2,
//     rounding: 0,
//     value: "SDG",
//     name_plural: "Sudanese pounds",
//   },
  {
    symbol: "Skr",
    label: "Swedish Krona",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    value: "SEK",
    name_plural: "Swedish kronor",
  },
  {
    symbol: "S$",
    label: "Singapore Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "SGD",
    name_plural: "Singapore dollars",
  },
//   {
//     symbol: "Ssh",
//     label: "Somali Shilling",
//     symbol_native: "Ssh",
//     decimal_digits: 0,
//     rounding: 0,
//     value: "SOS",
//     name_plural: "Somali shillings",
//   },
//   {
//     symbol: "SY£",
//     label: "Syrian Pound",
//     symbol_native: "ل.س.‏",
//     decimal_digits: 0,
//     rounding: 0,
//     value: "SYP",
//     name_plural: "Syrian pounds",
//   },
//   {
//     symbol: "฿",
//     label: "Thai Baht",
//     symbol_native: "฿",
//     decimal_digits: 2,
//     rounding: 0,
//     value: "THB",
//     name_plural: "Thai baht",
//   },
  {
    symbol: "DT",
    label: "Tunisian Dinar",
    symbol_native: "د.ت.‏",
    decimal_digits: 3,
    rounding: 0,
    value: "TND",
    name_plural: "Tunisian dinars",
  },
//   {
//     symbol: "T$",
//     label: "Tongan Paʻanga",
//     symbol_native: "T$",
//     decimal_digits: 2,
//     rounding: 0,
//     value: "TOP",
//     name_plural: "Tongan paʻanga",
//   },
  {
    symbol: "TL",
    label: "Turkish Lira",
    symbol_native: "TL",
    decimal_digits: 2,
    rounding: 0,
    value: "TRY",
    name_plural: "Turkish Lira",
  },
//   {
//     symbol: "TT$",
//     label: "Trinidad and Tobago Dollar",
//     symbol_native: "$",
//     decimal_digits: 2,
//     rounding: 0,
//     value: "TTD",
//     name_plural: "Trinidad and Tobago dollars",
//   },
//   {
//     symbol: "NT$",
//     label: "New Taiwan Dollar",
//     symbol_native: "NT$",
//     decimal_digits: 2,
//     rounding: 0,
//     value: "TWD",
//     name_plural: "New Taiwan dollars",
//   },
  {
    symbol: "TSh",
    label: "Tanzanian Shilling",
    symbol_native: "TSh",
    decimal_digits: 0,
    rounding: 0,
    value: "TZS",
    name_plural: "Tanzanian shillings",
  },
//   {
//     symbol: "₴",
//     label: "Ukrainian Hryvnia",
//     symbol_native: "₴",
//     decimal_digits: 2,
//     rounding: 0,
//     value: "UAH",
//     name_plural: "Ukrainian hryvnias",
//   },
//   {
//     symbol: "USh",
//     label: "Ugandan Shilling",
//     symbol_native: "USh",
//     decimal_digits: 0,
//     rounding: 0,
//     value: "UGX",
//     name_plural: "Ugandan shillings",
//   },
//   {
//     symbol: "$U",
//     label: "Uruguayan Peso",
//     symbol_native: "$",
//     decimal_digits: 2,
//     rounding: 0,
//     value: "UYU",
//     name_plural: "Uruguayan pesos",
//   },
//   {
//     symbol: "UZS",
//     label: "Uzbekistan Som",
//     symbol_native: "UZS",
//     decimal_digits: 0,
//     rounding: 0,
//     value: "UZS",
//     name_plural: "Uzbekistan som",
//   },
//   {
//     symbol: "Bs.F.",
//     label: "Venezuelan Bolívar",
//     symbol_native: "Bs.F.",
//     decimal_digits: 2,
//     rounding: 0,
//     value: "VEF",
//     name_plural: "Venezuelan bolívars",
//   },
//   {
//     symbol: "₫",
//     label: "Vietnamese Dong",
//     symbol_native: "₫",
//     decimal_digits: 0,
//     rounding: 0,
//     value: "VND",
//     name_plural: "Vietnamese dong",
//   },
  {
    symbol: "FCFA",
    label: "CFA Franc BEAC",
    symbol_native: "FCFA",
    decimal_digits: 0,
    rounding: 0,
    value: "XAF",
    name_plural: "CFA francs BEAC",
  },
  {
    symbol: "CFA",
    label: "CFA Franc BCEAO",
    symbol_native: "CFA",
    decimal_digits: 0,
    rounding: 0,
    value: "XOF",
    name_plural: "CFA francs BCEAO",
  },
//   {
//     symbol: "YR",
//     label: "Yemeni Rial",
//     symbol_native: "ر.ي.‏",
//     decimal_digits: 0,
//     rounding: 0,
//     value: "YER",
//     name_plural: "Yemeni rials",
//   },
  {
    symbol: "R",
    label: "South African Rand",
    symbol_native: "R",
    decimal_digits: 2,
    rounding: 0,
    value: "ZAR",
    name_plural: "South African rand",
  },
  {
    symbol: "ZK",
    label: "Zambian Kwacha",
    symbol_native: "ZK",
    decimal_digits: 0,
    rounding: 0,
    value: "ZMK",
    name_plural: "Zambian kwachas",
  },
  {
    symbol: "ZWL$",
    label: "Zimbabwean Dollar",
    symbol_native: "ZWL$",
    decimal_digits: 0,
    rounding: 0,
    value: "ZWL",
    name_plural: "Zimbabwean Dollar",
  },
];
