const { Axios } = require('./axios');

const getPeoplePlans = async () => {
    const { data } = await Axios.get(`/subscriptions/packages`);
    return data?.data;
};

const getCurrentPeoplePlan = async () => {
    const { data } = await Axios.get(`/subscriptions`);
    return data?.data;
};

const getCalculateRenewSubscription = async () => {
    const { data } = await Axios.get(`/subscriptions/renew/calculate`);
    return data?.data;
};

const getCalculateUpgradeSubscription = async () => {
    const { data } = await Axios.get(`/subscriptions/upgrade/check`);
    return data?.data;
};

const getSubscriptionHistory = async (payload) => {
    const { data } = await Axios.get(`/subscriptions/history?limit=${payload.limit}&page=${payload.page}`);
    return data?.data;
};

const subscribe = async (payload) => {
    const { data } = await Axios.post(`/subscriptions`, payload);
    return data;
};

const renewSubscription = async () => {
    const { data } = await Axios.get(`/subscriptions/renew`);
    return data;
};

const upgradeSubscription = async () => {
    const { data } = await Axios.get(`/subscriptions/upgrade`);
    return data;
};

const getCalculateEmployeeSubscription = async (payload) => {
    const { data } = await Axios.post(`/subscriptions/add-employees/calculate`, payload);
    return data?.data;
};

const addEmployeeSubscription = async (payload) => {
    const { data } = await Axios.post(`/subscriptions/add-employees`, payload);
    return data?.data;
};

const cancelSubscription = async () => {
    const { data } = await Axios.get(`/subscriptions/cancel`);
    return data;
};

const changeAutoRenew = async () => {
    const { data } = await Axios.get(`/subscriptions/auto-renew`);
    return data;
};

export const hr_subscription = {
    getPeoplePlans,
    getCurrentPeoplePlan,
    getCalculateRenewSubscription,
    getCalculateUpgradeSubscription,
    renewSubscription,
    upgradeSubscription,
    getSubscriptionHistory,
    subscribe,
    getCalculateEmployeeSubscription,
    addEmployeeSubscription,
    cancelSubscription,
    changeAutoRenew
  };