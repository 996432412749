import React, { useState } from "react";
import {
  useGetHMOBeneficiaries,
  useSubscribeToHMO,
  useUpdateHMOStatus,
} from "../../redux/hmo/hook";
import { HMOstatusConfig, PAGINATION_DEFAULT } from "../../utils/constants";
import Button from "../button";
import DataTable from "../datatable";
import AddHMOBeneficiaries from "../modal/hmo/add-beneficiary";
import HMODetailedView from "../modal/hmo/hmo-detailed-view";
import { useGetEmployees } from "../../redux/employees/hook";

export const HMOBeneficiariesTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { data: employees } = useGetEmployees(9, 1, -1, "");
  const { data: hmo_beneficiaries } = useGetHMOBeneficiaries(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const [showAddBeneficiaries, setShowAddBeneficiaries] = useState(false);

  const [showViewBeneficiary, setShowViewBeneficiary] = useState(false);

  const [viewData, setViewData] = useState();

  const { mutateAsync: subscribeBeneficiary } = useSubscribeToHMO();

  const { mutateAsync: changeBeneficiaryStatus } = useUpdateHMOStatus();

  async function Continue(id, type) {
    let index = hmo_beneficiaries.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      if (type === "view") {
        setViewData(hmo_beneficiaries.data[index]);
        setShowViewBeneficiary(true);
      } else if (type === "subscribe") {
        subscribeBeneficiary(hmo_beneficiaries.data[index].id);
      } else if (type === "change-status") {
        changeBeneficiaryStatus(hmo_beneficiaries.data[index].id);
      }
    }
  }

  const tableConfig = {
    headers: [
      "Employee Name",
      "Mobile",
      "HMO Provider",
      "HMO Plan",
      "Budget",
      "Status",
    ],
    keys: [
      "employeeName",
      "mobile",
      "hmo_name",
      "hmo_plan",
      "formatted_hmo_cost",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "HMO Provider",
        key: "hmo_name",
      },
    },
  };

  const actionConfig = [
    {
      name: "Detailed View",
      color: "orange",
      action: (id) => {
        Continue(id, "view");
      },
    },
    {
      name: "Subscribe",
      color: "green",
      action: (id) => {
        Continue(id, "subscribe");
      },
    },
    {
      name: " Change Status",
      color: "red",
      action: (id) => {
        Continue(id, "change-status");
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded-md">
      {showViewBeneficiary ? (
        <HMODetailedView
          isOpen={showViewBeneficiary}
          viewData={viewData}
          closeModal={() => setShowViewBeneficiary(false)}
        />
      ) : null}
      {showAddBeneficiaries ? (
        <AddHMOBeneficiaries
          isOpen={showAddBeneficiaries}
          closeModal={() => setShowAddBeneficiaries(false)}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          HMO Beneficiaries
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New HMO Beneficiaries"
              type="submit"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              disabled={!employees}
              onClick={() => {
                setShowAddBeneficiaries(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={hmo_beneficiaries}
        tableConfig={tableConfig}
        statusConfig={HMOstatusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={true}
        searchData={true}
        computedActions={true}
      />
    </div>
  );
};
