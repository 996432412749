export const industryOptions = [
    { value: "Agriculture Industry", label: "Agriculture Industry" },
    { value: "Banking", label: "Banking" },
    { value: "Construction", label: "Construction" },
    { value: "Consumer Goods", label: "Consumer Goods" },
    { value: "Education", label: "Education" },
    { value: "EdTech", label: "EdTech" },
    { value: "Fashion", label: "Fashion" },
    { value: "Financial Technology", label: "Financial Technology" },
    { value: "Financial Services", label: "Financial Services" },
    { value: "Human Resources", label: "Human Resources" },
    { value: "Healthcare", label: "Healthcare" },
    { value: "ICT", label: "ICT" },
    { value: "Industrial Goods", label: "Industrial Goods" },
    { value: "Natural Resources", label: "Natural Resources" },
    { value: "Oil And Gas", label: "Oil And Gas" },
    { value: "Proptech", label: "Proptech" },
    { value: "Real Estate/Construction", label: "Real Estate/Construction" },
    { value: "Services", label: "Services" },
    { value: "Telecommunications", label: "Telecommunications" },
    { value: "Utilities", label: "Utilities" },
  ];