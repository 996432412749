import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { employees } from '../../../api';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { setEmployee } from '../reducer';
import moment from 'moment';

export function useGetEmployeeSummary() {
  return useQuery(
    ['employee-summary'],
    () => {
      return employees.getEmployeeSummary();
    }
  );
}

export function useGetEmployeeReport(start_date, end_date) {
  return useQuery(
    ['employee-report', { start_date, end_date }],
    () => {
      return employees.getEmployeeReport({ start_date, end_date });
    },
    {
      select: (data) => {
        let formatted_months = []
        data?.months?.forEach(element => {
          formatted_months.push(moment(element+'-01').format('MMM YY'));
        });
        data.formatted_months = formatted_months;
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
    
  );
}

export function useGetEmployees(limit, page, statusFilter, search) {
  return useQuery(
    ['employees', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employees.getEmployees({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((employee) => {
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          employee.date_joined = employee?.date_joined? moment(employee?.date_joined).format('MMMM D, YYYY') : '-'

          if (employee.hr_status === 0) {
            //compute action items
            switch (employee.status) {
              case 0:
                employee.computedActions = [0, 3, 6, 9, 12];
                break;
              case 1:
                employee.computedActions = [0, 4, 6, 7, 8, 9, 12];
                break;
              case 2:
                employee.computedActions = [0, 1, 2, 5, 6, 7, 9, 12];
                break;
              case 4:
                employee.computedActions = [0, 1, 2, 6, 7, 8, 9, 12];
                break;
              case 5:
                employee.computedActions = [0, 6, 8, 10, 11, 12];
                break;
              default:
                break;
            }
            
          } else {
            //compute action items
            switch (employee.status) {
              case 0:
                employee.computedActions = [0, 3, 6, 9, 12];
                break;
              case 1:
                employee.computedActions = [0, 4, 6, 8, 9, 12];
                break;
              case 2:
                employee.computedActions = [0, 1, 2, 5, 6, 9, 12];
                break;
              case 4:
                employee.computedActions = [0, 1, 2, 6, 8, 9, 12];
                break;
              case 5:
                employee.computedActions = [0, 6, 8, 10, 11, 12];
                break;
              default:
                break;
            }
          }
          // employee.computedActions = [0, 1, 2, 3, 4,5, 6, 7];
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useExportEmployees() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.getEmployees(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Employee data fetched successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
  return useQuery(
    ['employees', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employees.getEmployees({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((employee) => {
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          employee.date_joined = employee?.date_joined? moment(employee?.date_joined).format('MMMM D, YYYY') : '-'

          if (employee.hr_status === 0) {
            //compute action items
            switch (employee.status) {
              case 0:
                employee.computedActions = [0, 3, 6, 9, 12];
                break;
              case 1:
                employee.computedActions = [0, 4, 6, 7, 8, 9, 12];
                break;
              case 2:
                employee.computedActions = [0, 1, 2, 5, 6, 7, 9, 12];
                break;
              case 4:
                employee.computedActions = [0, 1, 2, 6, 7, 8, 9, 12];
                break;
              case 5:
                employee.computedActions = [0, 6, 8, 10, 11, 12];
                break;
              default:
                break;
            }
            
          } else {
            //compute action items
            switch (employee.status) {
              case 0:
                employee.computedActions = [0, 3, 6, 9, 12];
                break;
              case 1:
                employee.computedActions = [0, 4, 6, 8, 9, 12];
                break;
              case 2:
                employee.computedActions = [0, 1, 2, 5, 6, 9, 12];
                break;
              case 4:
                employee.computedActions = [0, 1, 2, 6, 8, 9, 12];
                break;
              case 5:
                employee.computedActions = [0, 6, 8, 10, 11, 12];
                break;
              default:
                break;
            }
          }
          // employee.computedActions = [0, 1, 2, 3, 4,5, 6, 7];
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetEmployeeToSelect(limit, page, statusFilter, search) {
  return useQuery(
    ['employee-select', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employees.getEmployees({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((employee, index) => {
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          if (employee.status != 1) {
            data.data.splice(index, 1);
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetEmployee(id) {
  return useQuery(
    ['employee', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return employees.getEmployee(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setEmployee(response.data));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetRegistrationLink() {
  return useQuery(
    ['employee-registration-link'],
    () => {
      store.dispatch(setIsLoading(true));
      return employees.getEmployeeRegistrationLink();
    },
    {
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRefreshInvitationLink() {
  return useMutation(
    () => {
      return employees.refreshInvitationLink();
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-registration-link');
        store.dispatch(setAlert(true, 'success', 'Link refreshed successfully'));
      },
    },
  );
}

export function useChangeInvitationLinkStatus() {
  return useMutation(
    () => {
      return employees.changeInvitationLinkSatus();
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-registration-link');
        store.dispatch(setAlert(true, 'success', 'Link status changed successfully'));
      },
    },
  );
}

export function useUpdateEmployee() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.updateEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, 'success', 'Employee information updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateEmployeeBank() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.updateEmployeeBank(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        queryClient.invalidateQueries('employee');
        store.dispatch(setAlert(true, 'success', 'Employee bank information updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteEmployee() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.deleteEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, 'success', 'Employee deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBulkDeleteEmployee() {
  return useMutation(
    (payload) => {
      return employees.bulkDeleteEmployee(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, 'success', 'Multiple employees deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useInviteSingleEmployees() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.inviteSingleEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setEmployee(response.data));
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useInviteBulkEmployees() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.inviteBulkEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUploadProfilePicture() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.uploadProfilePicture(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, 'success', 'Employee Profile picture added successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRegistrationUploadProfilePicture() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.employeeUploadProfilePicture(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Profile picture added successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCreateSingleEmployees() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.createSingleEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setEmployee(response.data));
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, 'success', 'Employee added and activated successfully.'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function usePreRegisterEmployee() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.preRegisterEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, 'success', 'Pre-registration successful'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSubmitOfferLetter() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.submitOfferLetter(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, 'success', 'Pre-registration successful'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateInvitedEmployees() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.updateInvitedEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setEmployee(response.data));
        store.dispatch(setAlert(true, 'success', 'Update successful'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCreateBulkEmployees() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.createBulkEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddEmployeeGroup() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.addEmployeeGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-groups');
        store.dispatch(setAlert(true, 'success', 'Employee added to group successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useNetPayReverse() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.netPayReverse(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Payroll breakdown calculated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useFullPayReverseBulk() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.fullPayReverseBulk(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useNetPayReverseBulk() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.netPayReverseBulk(payload);
    },
    {
    
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGrossPayReverse() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.grossPayReverse(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGrossPayReverseBulk() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.grossPayReverseBulk(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBulkActivateEmployees() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.bulkActivateEmployees(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        queryClient.invalidateQueries('employee');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useActivateOrDeactivateEmployee() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.activateOrDeactivateEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        queryClient.invalidateQueries('employee');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useActivateOrDeactivatePendingEmployee() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.activateOrDeactivatePendingEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        queryClient.invalidateQueries('employee');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useActivateOrDeactivatePreEmployee() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.activateOrDeactivatePreEmployee(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        queryClient.invalidateQueries('employee');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBulkDeactivateEmployees() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.bulkDeactivateEmployees(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employees');
        queryClient.invalidateQueries('employee');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGenerateOtp() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.generateEmployeeOtp(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useConfirmOtp() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.confirmEmployeeOtp(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setEmployee(response.data));
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useResendInviteEmail() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.resendInviteEmail(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useResendMobileInviteEmail() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employees.resendMobileRegistrationInviteEmail(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Mobile Invite sent successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useExportSalaryTemplate() {
  return useMutation(
    () => {
      store.dispatch(setAlert(true, 'success', 'Fetching Export Data...'));
      return employees.getEmployeeForSalary();
    },
    {
      onSuccess: (data) => {
        store.dispatch(setAlert(true, 'success', 'Salary uploading template fetched successfully'));
        return data;
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

