import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button";
import FormSelectEmployee from "../../../components/employees/form-select-employee";
import FormInput from "../../../components/form-input";
import { useRouteQuery } from "../../../hooks/useRouteQuery";
import { useGetSalaryByEmployee } from "../../../redux/employees/hook/salaries";
import ForwardArrowVG from "../../../assets/svg/forward-arrow.svg";
import { useAddSalaryReview } from "../../../redux/employees/hook/salary-review";
import SalaryReviewLayout from "../../../components/layouts/salary-review";
import { formatNumber } from "../../../utils/functions";

function ReviewSingleCompleteSalaryInfo() {
  useEffect(() => {}, []);
  const history = useHistory();
  const [fromSalary, setFromSalary] = useState("");
  const query = useRouteQuery();

  const { mutateAsync: reviewSalary, isLoading: loading } =
    useAddSalaryReview();

  const [employeeId, setEmployeeId] = useState(null);
  const [breakDown, setBreakDown] = useState({
    new_basic: 0,
    new_housing: 0,
    new_transport: 0,
    new_lunch: 0,
    new_benefit_in_kind: 0,
    new_leave: 0,
    new_utility: 0,
    new_other_allowance: 0,
    new_net_pay: 0,
    review_start_date: "",
    new_additional_allowances: [],
  });

  const { employee } = useSelector((state) => state.employeeSlice);

  const [additionalAllowance, setAdditionalAllowance] = useState([]);
  const { data: singleEmployeeSalary } = useGetSalaryByEmployee(employeeId);

  useEffect(() => {
    if (query) {
      setFromSalary(query.get("salary"));
    }
  }, [query]);

  useEffect(() => {
    if (singleEmployeeSalary) {
      if (singleEmployeeSalary?.additional_allowances) {
        let data = [];
        JSON.parse(singleEmployeeSalary?.additional_allowances).forEach(
          (item) => {
            item.amount = 0;
            data.push(item);
          }
        );
        setAdditionalAllowance(
          JSON.parse(singleEmployeeSalary?.additional_allowances)
        );
        let newArray = JSON.parse(JSON.stringify(breakDown));
        newArray.new_additional_allowances = data;
        setBreakDown(newArray);
      }
    }
  }, [singleEmployeeSalary]);

  useEffect(() => {
    if (employee) {
      setEmployeeId(employee.id);
    }
  }, [employee]);

  const submitSalary = async () => {
    let payload = {
      employee: employeeId,
      new_basic: breakDown?.new_basic,
      new_housing: breakDown?.new_housing,
      new_transport: breakDown?.new_transport,
      new_leave: breakDown?.new_leave,
      new_lunch: breakDown?.new_lunch,
      new_utility: breakDown?.new_utility,
      new_benefit_in_kind: breakDown?.new_benefit_in_kind,
      new_other_allowance: breakDown?.new_other_allowance,
      new_additional_allowances: JSON.stringify(
        breakDown?.new_additional_allowances
      ),
      new_net_pay: breakDown?.new_net_pay,
      review_start_date: breakDown?.review_start_date,
      employee: employeeId,
    };
    await reviewSalary(payload).then(() => {
      history.push("/employees");
    });
  };

  return (
    <SalaryReviewLayout step={2} title={"Review Compensation"}>
      <div className="flex flex-col w-full h-full">
        <span className="header-4">Review complete salary information</span>
        <hr className="divider" />
        <div className="border bg-secondary-2-extralight p-2 rounded-md -pb-2">
          {fromSalary ? (
            <FormSelectEmployee
              label={"Employee"}
              name="employee"
              placeholder="Type to select Employee"
              setEmployee={setEmployeeId}
              readOnly={loading}
            />
          ) : (
            <div className="flex flex-col border bg-secondary-2-extralight p-2 rounded-md">
              <span className="text-[15px] font-extrabold text-color-gray">
                Employee name
              </span>
              <span className="text-[13px] text-color-black-2 font-semibold">
                {employee?.first_name} {employee?.last_name}
              </span>
            </div>
          )}
        </div>
        {employeeId ? (
          <div className={"flex flex-col "}>
            <div className="flex flex-col justify-between w-full">
              <FormInput
                label="Review Effective Date"
                type="date"
                onInput={(e) =>
                  setBreakDown({
                    ...breakDown,
                    review_start_date: e.target.value,
                  })
                }
              />
              <div className="flex flex-row w-full justify-between">
                <FormInput
                  label="Old Basic Salary"
                  type="text"
                  placeholder={"Auto Fetched..."}
                  readOnly
                  value={formatNumber(singleEmployeeSalary?.basic_salary, 2)}
                />
                <img src={ForwardArrowVG} width={15} alt="to" />
                <FormInput
                  label="New Basic Salary"
                  name="basic"
                  type="text"
                  value={breakDown.new_basic}
                  onInput={(e) => {
                    let newArray = JSON.parse(JSON.stringify(breakDown));
                    newArray.new_basic = parseFloat(e.target.value);
                    setBreakDown(newArray);
                  }}
                />
              </div>

              <div className="flex flex-row w-full justify-between">
                <FormInput
                  label="Old Housing Allowance"
                  placeholder={"Auto Fetched..."}
                  type="text"
                  readOnly
                  value={formatNumber(
                    singleEmployeeSalary?.housing_allowance,
                    2
                  )}
                />
                <img
                  src={ForwardArrowVG}
                  width={15}
                  className="ml-2 mt-2"
                  alt="to"
                />
                <FormInput
                  label="New Housing Allowance"
                  name="housing"
                  type="number"
                  value={breakDown.new_housing}
                  onInput={(e) => {
                    let newArray = JSON.parse(JSON.stringify(breakDown));
                    newArray.new_housing = parseFloat(e.target.value);
                    setBreakDown(newArray);
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col justify-between w-full">
              <div className="flex flex-row w-full justify-between">
                <FormInput
                  label="Old Transport Allowance"
                  placeholder={"Auto Fetched..."}
                  type="text"
                  readOnly
                  value={formatNumber(
                    singleEmployeeSalary?.transportation_allowance,
                    2
                  )}
                />
                <img
                  src={ForwardArrowVG}
                  width={15}
                  className="ml-2 mt-2"
                  alt="to"
                />
                <FormInput
                  label="Transport Allowance"
                  name="transport"
                  type="number"
                  value={breakDown.new_transport}
                  onInput={(e) => {
                    let newArray = JSON.parse(JSON.stringify(breakDown));
                    newArray.new_transport = parseFloat(e.target.value);
                    setBreakDown(newArray);
                  }}
                />
              </div>

              <div className="flex flex-row w-full justify-between">
                <FormInput
                  label="Old Leave allowance"
                  placeholder={"Auto Fetched..."}
                  type="text"
                  readOnly
                  value={formatNumber(singleEmployeeSalary?.leave_allowance, 2)}
                />
                <img
                  src={ForwardArrowVG}
                  width={15}
                  className="ml-2 mt-2"
                  alt="to"
                />
                <FormInput
                  label="New Leave Allowance"
                  name="leave"
                  type="number"
                  value={breakDown.new_leave}
                  onInput={(e) => {
                    let newArray = JSON.parse(JSON.stringify(breakDown));
                    newArray.new_leave = parseFloat(e.target.value);
                    setBreakDown(newArray);
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col justify-between">
              <div className="flex flex-row w-full justify-between">
                <FormInput
                  label="Old Lunch allowance"
                  placeholder={"Auto Fetched..."}
                  type="text"
                  readOnly
                  value={formatNumber(singleEmployeeSalary?.lunch_allowance, 2)}
                />
                <img
                  src={ForwardArrowVG}
                  width={15}
                  className="ml-2 mt-2"
                  alt="to"
                />
                <FormInput
                  label="New Lunch Allowance"
                  name="lunch"
                  type="text"
                  value={breakDown.new_lunch}
                  onInput={(e) => {
                    let newArray = JSON.parse(JSON.stringify(breakDown));
                    newArray.new_lunch = parseFloat(e.target.value);
                    setBreakDown(newArray);
                  }}
                />
              </div>

              <div className="flex flex-row w-full justify-between">
                <FormInput
                  label="Old Utility allowance"
                  placeholder={"Auto Fetched..."}
                  type="text"
                  readOnly
                  value={formatNumber(
                    singleEmployeeSalary?.utility_allowance,
                    2
                  )}
                />
                <img
                  src={ForwardArrowVG}
                  width={15}
                  className="ml-2 mt-2"
                  alt="to"
                />
                <FormInput
                  label="New Utility Allowance"
                  name="utility"
                  type="text"
                  value={breakDown.new_utility}
                  onInput={(e) => {
                    let newArray = JSON.parse(JSON.stringify(breakDown));
                    newArray.new_utility = parseFloat(e.target.value);
                    setBreakDown(newArray);
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col justify-between">
              <div className="flex flex-row w-full justify-between">
                <FormInput
                  label="Old Benefit in kind"
                  placeholder={"Auto Fetched..."}
                  type="text"
                  readOnly
                  value={formatNumber(singleEmployeeSalary?.benefit_in_kind, 2)}
                />
                <img
                  src={ForwardArrowVG}
                  width={15}
                  className="ml-2 mt-2"
                  alt="to"
                />
                <FormInput
                  label="New Benefit In Kind"
                  name="benefit_in_kind"
                  type="text"
                  value={breakDown.new_benefit_in_kind}
                  onInput={(e) => {
                    let newArray = JSON.parse(JSON.stringify(breakDown));
                    newArray.new_benefit_in_kind = parseFloat(e.target.value);
                    setBreakDown(newArray);
                  }}
                />
              </div>

              <div className="flex flex-row w-full justify-between">
                <FormInput
                  label="Old Other allowance"
                  placeholder={"Auto Fetched..."}
                  type="text"
                  readOnly
                  value={formatNumber(singleEmployeeSalary?.other_allowance, 2)}
                />
                <img
                  src={ForwardArrowVG}
                  width={15}
                  className="ml-2 mt-2"
                  alt="to"
                />
                <FormInput
                  label="New Other Allowance"
                  name="other"
                  type="text"
                  value={breakDown.new_other_allowance}
                  onInput={(e) => {
                    let newArray = JSON.parse(JSON.stringify(breakDown));
                    newArray.new_other_allowance = parseFloat(e.target.value);
                    setBreakDown(newArray);
                  }}
                />
              </div>
            </div>
            {breakDown?.new_additional_allowances?.length > 0 ? (
              <>
                <hr className="divider mt-2 mb-2" />
                <div className="text-[15px] font-extrabold mb-2">
                  Additional Allowances
                </div>
                {breakDown?.new_additional_allowances?.map((item, index) => (
                  <div className="flex flex-row w-full justify-between">
                    {additionalAllowance.map((subItem, subIndex) => (
                      <>
                        {subItem.name == item.name ? (
                          <FormInput
                            label={"Old " + subItem.title}
                            name={"old_" + subItem.name}
                            type="text"
                            value={formatNumber(subItem.amount, 2)}
                            readOnly
                          />
                        ) : null}
                      </>
                    ))}
                    <img
                      src={ForwardArrowVG}
                      width={15}
                      className="ml-2 mt-2"
                      alt="to"
                    />
                    <FormInput
                      label={"New " + item.title}
                      name={"new_" + item.name}
                      type="text"
                      value={item.amount}
                      onInput={(e) => {
                        let newArray = JSON.parse(JSON.stringify(breakDown));
                        newArray.new_additional_allowances[index].amount =
                          parseFloat(e.target.value);
                        setBreakDown(newArray);
                      }}
                    />
                  </div>
                ))}
                <hr className="divider mt-2" />
              </>
            ) : null}
            <div className="flex flex-col justify-between">
              <div className="flex flex-row w-full justify-between">
                <FormInput
                  label="Old Net Pay"
                  placeholder={"Auto Fetched..."}
                  type="text"
                  readOnly
                  value={formatNumber(singleEmployeeSalary?.net_pay, 2)}
                />
                <img
                  src={ForwardArrowVG}
                  width={15}
                  className="ml-2 mt-2"
                  alt="to"
                />
                <FormInput
                  label="New Net Pay (Fixed Take Home)"
                  name="net_pay"
                  type="text"
                  defaultValue={0}
                  value={breakDown.new_net_pay}
                  onInput={(e) => {
                    let newArray = JSON.parse(JSON.stringify(breakDown));
                    newArray.new_net_pay = parseFloat(e.target.value);
                    setBreakDown(newArray);
                  }}
                />
              </div>
            </div>

            <div className="w-full mt-[10px]">
              <Button
                text="Review Salary"
                type="submit"
                onClick={() => submitSalary()}
                loading={loading}
                disabled={!breakDown.review_start_date}
              />
              <button
                className="flex justify-center w-full"
                onClick={() => history.goBack()}
              >
                <span className="p2-medium text-color-black mt-3">Go Back</span>
              </button>
            </div>
          </div>
        ) : (
          <div className="h-[60px] flex items-center justify-center w-full">
            <hr className="divider mt-4" />
            <span>No employee selected</span>
          </div>
        )}
      </div>
    </SalaryReviewLayout>
  );
}

export default ReviewSingleCompleteSalaryInfo;
