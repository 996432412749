import classNames from "classnames";
import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Collapse } from "reactstrap";
import CloseSVG from "../../../assets/svg/close.svg";
import { formValidation, formatNumber } from "../../../utils/functions";
import Button from "../../button";
import CollapsingIcon from "../../collapsing-icon";
import FormInput from "../../form-input";
import FormSelect from "../../form-select";
import FormSwitch from "../../form-switch";
import FormTextArea from "../../form-textarea";
import Modal from "../modal";
import {
  useAddHMOGroup,
  useEditHMOGroup,
  useGetHMOGroup,
  useGetHMOPlans,
  useGetHMOs,
  useGetOnboardingHMOPlans,
} from "../../../redux/hmo/hook";

function AddEmployeeHMOGroup({
  isOpen,
  closeModal,
  refresh,
  groupData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const [groupId, setGroupId] = useState(null);
  const [groupType, setGroupType] = useState();

  useEffect(() => {
    if (groupData) {
      setGroupId(groupData);
    }
  }, [groupData]);

  const [useCompanySettings, setUseCompanySettings] = useState();
  const [canSelectHMO, setCanSelectHMO] = useState(false);
  const [hmoName, setHmoName] = useState("");
  const [hmoId, setHmoId] = useState(null);
  const [hmoPlan, setHmoPlan] = useState("");
  const [planId, setPlanId] = useState(null);
  const [hmoBudget, setHmoBudget] = useState("");
  const [maxSubscriptionMonth, setMaxSubscriptionMonth] = useState(0);
  const [canAddDependants, setCanAddDependants] = useState(0);
  const [maxDependants, setMaxDependants] = useState(0);
  const [autoRenew, setAutoRenew] = useState(0);

  const { data: hmos } = useGetHMOs();

  const { data: hmoPlansCompleteInformation } = useGetHMOPlans(hmoId);

  const { data: hmoPlans } = useGetOnboardingHMOPlans(hmoId);

  const [CurrentHrPlan, setCurrentHrPlan] = useState();

  const [hmoData, setHmoData] = useState([
    { value: "", label: "No HMO Selected", hmo_name: "" },
  ]);

  const [hmoPlanData, setHmoPlanData] = useState([
    { value: "", label: "No Plan Selected", plan_name: "" },
  ]);

  useEffect(() => {
    if (hmos) {
      setHmoData(hmos);
    }
  }, [hmos]);

  useEffect(() => {
    if (hmoPlans) {
      setHmoPlanData(hmoPlans);
    }
  }, [hmoPlans]);

  useEffect(() => {
    if (planId && hmoPlansCompleteInformation) {
      let index = hmoPlansCompleteInformation?.findIndex(
        (item) => item.id == planId
      );
      if (index != -1) {
        setCurrentHrPlan(hmoPlansCompleteInformation[index]);
        setHmoBudget(hmoPlansCompleteInformation[index].price);
        setMaxSubscriptionMonth(
          hmoPlansCompleteInformation[index].payment_duration == "0"
            ? 1
            : hmoPlansCompleteInformation[index].payment_duration
        );
      }
    }
  }, [planId, hmoPlansCompleteInformation]);

  useEffect(() => {
    if (hmoId) {
      setCurrentHrPlan(null);
      setHmoBudget(null);
    }
  }, [hmoId]);

  useEffect(() => {
    if (hmoPlans) {
      setHmoPlanData(hmoPlans);
    }
  }, [hmoPlans]);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const { data: group } = useGetHMOGroup(groupId, 1, 1, -1, "");

  useEffect(() => {
    if (group) {
      setUseCompanySettings(group.use_company_settings);
      setName(group.name);
      setDescription(group.description);
      setHmoName(group.setting?.hmo_name);
      setCanSelectHMO(group.setting?.can_select_hmo);
      setHmoId(group.setting?.hmo_id ? group.setting?.hmo_id : null);
      setHmoPlan(
        group.setting?.hmo_plan_id ? group.setting?.hmo_plan_id : null
      );
      setPlanId(group.setting?.use_company_settings);
      setHmoBudget(group.setting?.hmo_budget);
      setMaxSubscriptionMonth(group.setting?.max_subscription_months);
      setCanAddDependants(group.setting?.can_add_dependant);
      setMaxDependants(group.setting?.max_dependants);
      setAutoRenew(group.setting?.auto_renew);
      setGroupId(group.id);
      setGroupType("edit");
    } else {
      setGroupType("add");
    }
  }, [group]);

  const { mutateAsync: addGroup, isLoading: addGrouploading } =
    useAddHMOGroup();
  const { mutateAsync: editGroup, isLoading: editGrouploading } =
    useEditHMOGroup();

  const submitForm = async () => {
    let payload = {
      name: name,
      description: description,
      use_company_settings: useCompanySettings ? 1 : 0,
      can_select_hmo: canSelectHMO ? 1 : 0,
      hmo_name: hmoName,
      hmo_id: hmoId,
      hmo_plan: hmoPlan,
      hmo_plan_id: planId,
      hmo_budget: hmoBudget,
      max_subscription_months: maxSubscriptionMonth,
      can_add_dependant: canAddDependants ? 1 : 0,
      max_dependants: maxDependants,
      auto_renew: autoRenew,
    };

    if (groupType === "add") {
      await addGroup(payload).then(() => {
        closeModal();
      });
    } else if (groupType === "edit") {
      let editPayload = {
        id: groupId,
        body: payload,
      };
      await editGroup(editPayload).then(() => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className="md:w-[75%] lg:w-[75%] w-[100%] !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col overflow-auto">
        <form className="form pb-6" onSubmit={handleSubmit(submitForm)}>
          <div className="pl-6 pr-6 pt-4">
            <div className="flex flex-row justify-between items-center">
              <span className="header-4">{title}</span>
              <div className="cursor-pointer" onClick={() => closeModal()}>
                <img src={CloseSVG} alt="close" />
              </div>
            </div>
          </div>
          <hr className="divider" />
          <div className="flex flex-col max-h-fit mx-8">
            <div className="flex gap-2 flex-col rounded border bg-highlight p-2 mb-3">
              <FormInput
                label="HMO Group Name"
                name="name"
                type="text"
                value={name}
                onInput={(e) => setName(e.target.value)}
                placeholder={"Enter group name"}
                readOnly={addGrouploading || editGrouploading}
                error={errors.name}
                errorMessage={errors.name && errors.name.message}
                className={"bg-white"}
              />
              <FormInput
                label={"Group Description"}
                name="description"
                type="text"
                placeholder={"Group Description"}
                value={description}
                onInput={(e) => setDescription(e.target.value)}
                readOnly={addGrouploading || editGrouploading}
                error={errors.description}
                errorMessage={errors.description && errors.description.message}
                className={"bg-white"}
              />
            </div>
            <div>
              <span className="header-4">HMO Group Settings</span>
            </div>
            <hr className="divider mt-2 mb-2" />
            <div
              className={
                "w-full items-center mt-2 mb-3 pt-3 border rounded-md px-2 bg-highlight"
              }
            >
              <FormSwitch
                label={"Use Company Settings"}
                name={"use_company_settings"}
                readOnly={addGrouploading || editGrouploading}
                checked={useCompanySettings}
                onClick={() => setUseCompanySettings(!useCompanySettings)}
              />
            </div>
            {!useCompanySettings ? (
              <div className="flex flex-col p-2 py-4 pb-2 border rounded-md mb-4">
                <div className={"flex w-full flex-col font-bold "}>
                  <FormSwitch
                    label={"Employee Can Select HMO"}
                    name={"can_select_hmo"}
                    readOnly={addGrouploading || editGrouploading}
                    checked={canSelectHMO}
                    onClick={() => {
                      if (!canSelectHMO) {
                        setHmoBudget(0);
                        setMaxSubscriptionMonth(0);
                        setCanSelectHMO(!canSelectHMO);
                      } else {
                        let index = hmoPlansCompleteInformation?.findIndex(
                          (item) => item.id == planId
                        );
                        if (index && index != -1) {
                          setCurrentHrPlan(
                            hmoPlansCompleteInformation[index]
                              ? hmoPlansCompleteInformation[index]
                              : null
                          );
                          setHmoBudget(
                            hmoPlansCompleteInformation[index].price
                              ? hmoPlansCompleteInformation[index].price
                              : null
                          );
                          setMaxSubscriptionMonth(
                            hmoPlansCompleteInformation[index].payment_duration
                              ? hmoPlansCompleteInformation[index]
                                  .payment_duration
                              : null
                          );
                        }
                        setCanSelectHMO(!canSelectHMO);
                      }
                    }}
                  />
                </div>
                <div className="flex flex-col p-2 py-2 border rounded bg-white">
                  {!canSelectHMO ? (
                    <>
                      <div className="flex flex-col w-full justify-between">
                        <div className="w-full">
                          <FormSelect
                            value={hmoId}
                            options={hmoData}
                            onChange={(selected) => {
                              setHmoId(selected);
                            }}
                            label="Select HMO"
                            extraClass={"!bg-white"}
                          />
                        </div>
                        <div className="w-full">
                          <FormSelect
                            value={planId}
                            options={hmoPlanData}
                            onChange={(selected) => {
                              setPlanId(selected);
                            }}
                            label="Select HMO Plan"
                            extraClass={"!bg-white"}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col p-2 border rounded mb-2 w-full">
                        {CurrentHrPlan ? (
                          <>
                            <div className="text-gray-500 text-[15px]">
                              Plan Name
                            </div>
                            <div className="text-[13px]">
                              {CurrentHrPlan?.name}
                            </div>
                            <hr className="divider" />
                            <div className="font-bold text-[16px] mb-2">
                              Plan Coverage
                            </div>
                            <div className="grid md:grid-cols-3 grid-cols-3 gap-4 w-full pt-2 duration-500">
                              <div className="flex flex-col">
                                <div className="text-gray-500 text-[14px]">
                                  Plan Type
                                </div>
                                <div className="text-[13px]">
                                  {CurrentHrPlan?.Plan_type}
                                </div>
                              </div>
                              <div className="flex flex-col">
                                <div className="text-gray-500 text-[14px]">
                                  An Pn Childbirth
                                </div>
                                <div className="text-[13px]">
                                  {CurrentHrPlan?.an_pn_childbirth}
                                </div>
                              </div>
                              <div className="flex flex-col">
                                <div className="text-gray-500 text-[14px]">
                                  Dental
                                </div>
                                <div className="text-[13px]">
                                  {CurrentHrPlan?.dental}
                                </div>
                              </div>
                              <div className="flex flex-col">
                                <div className="text-gray-500 text-[14px]">
                                  Drugs
                                </div>
                                <div className="text-[13px]">
                                  {CurrentHrPlan?.drugs}
                                </div>
                              </div>
                              <div className="flex flex-col">
                                <div className="text-gray-500 text-[14px]">
                                  Emergency
                                </div>
                                <div className="text-[13px]">
                                  {CurrentHrPlan?.emergency}
                                </div>
                              </div>
                              <div className="flex flex-col">
                                <div className="text-gray-500 text-[14px]">
                                  Gym
                                </div>
                                <div className="text-[13px]">
                                  {CurrentHrPlan?.gym}
                                </div>
                              </div>
                              <div className="flex flex-col">
                                <div className="text-gray-500 text-[14px]">
                                  Gynecology
                                </div>
                                <div className="text-[13px]">
                                  {CurrentHrPlan?.gynecology}
                                </div>
                              </div>
                              <div className="flex flex-col">
                                <div className="text-gray-500 text-[14px]">
                                  Optical care
                                </div>
                                <div className="text-[13px]">
                                  {CurrentHrPlan?.optical_care}
                                </div>
                              </div>
                              <div className="flex flex-col">
                                <div className="text-gray-500 text-[14px]">
                                  Surgery Coverage
                                </div>
                                <div className="text-[13px]">
                                  {formatNumber(
                                    CurrentHrPlan?.surgery_coverage,
                                    2
                                  )}
                                </div>
                              </div>
                              <div className="flex flex-col">
                                <div className="text-gray-500 text-[14px]">
                                  Ward Type
                                </div>
                                <div className="text-[13px]">
                                  {CurrentHrPlan?.ward_type}
                                </div>
                              </div>
                              <div className="flex flex-col justify-center">
                                <div className="text-gray-500 text-[14px] hover:underline hover:italic">
                                  <a
                                    href={CurrentHrPlan?.benefit_detail_link}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    View Full Benefits
                                  </a>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="flex p-2 justify-center items-center w-full text-[14px]">
                            No HMO Plan selected
                          </div>
                        )}
                      </div>
                    </>
                  ) : null}
                  <div className="flex flex-col md:flex-row w-full justify-between gap-2 md:gap-4">
                    <div className="md:w-1/2 w-full md:pr-2">
                      <FormInput
                        label={
                          canSelectHMO
                            ? "HMO Budget (Monthly) - Editable"
                            : "HMO Plan Price - (ReadOnly)"
                        }
                        placeholder={"Enter budget"}
                        name={"hmo_budget"}
                        type="number"
                        onInput={(e) => setHmoBudget(e.target.value)}
                        readOnly={canSelectHMO ? false : true}
                        value={hmoBudget}
                      />
                    </div>
                    <div className="md:w-1/2 w-full md:pl-2">
                      <FormInput
                        label={
                          canSelectHMO
                            ? "Subscription Month - (Editable)"
                            : "Subscription Month - (ReadOnly)"
                        }
                        name={"max_subscription_months"}
                        type="number"
                        onInput={(e) => setMaxSubscriptionMonth(e.target.value)}
                        readOnly={canSelectHMO ? false : true}
                        value={maxSubscriptionMonth}
                      />
                    </div>
                  </div>
                </div>
                <hr className="divider" />
                <div className={"flex w-full flex-col"}>
                  <div className="flex flex-row md:flex-col w-full justify-between gap-4 items-center">
                    <div className="w-full md:w-3/4 !text-[13px] mt-3">
                      <FormSwitch
                        label={"Employee Can Add Dependants"}
                        name={"can_add_dependants"}
                        readOnly={addGrouploading || editGrouploading}
                        checked={canAddDependants}
                        onClick={() => setCanAddDependants(!canAddDependants)}
                      />
                    </div>
                    <div className="w-full md:w-1/4 text-[13px]">
                      <FormInput
                        // label='Dependants'
                        placeholder={"Dependants"}
                        name="max_dependants"
                        className={"max-h-[35px]"}
                        type="number"
                        value={maxDependants}
                        onInput={(e) => setMaxDependants(e.target.value)}
                        readOnly={addGrouploading || editGrouploading}
                      />
                    </div>
                  </div>
                  <div className="mt-2 md:w-[50%] w-full">
                    <FormSwitch
                      label={"Auto Renew HMO"}
                      name={"auto_renew"}
                      readOnly={addGrouploading || editGrouploading}
                      checked={autoRenew}
                      onClick={() => setAutoRenew(!autoRenew)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <hr className="divider mt-2" />
            )}
            <div className="w-full">
              <Button
                text={buttonText}
                type="submit"
                disabled={
                  groupType === "edit" ? false : !isValid ? true : false
                }
                loading={addGrouploading || editGrouploading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddEmployeeHMOGroup;
