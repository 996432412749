import React, { useEffect } from "react";

const TransporatationBenefitsPage = () => {
  useEffect(() => {}, []);

  return (
    <div className="w-full bg-white border rounded flex items-center justify-start p-4">
      Transporation Benefit
      {/* <div className='md:w-[250px] h-[250px] flex flex-col justify-center items-center gap-4 border border-gray-300 rounded-lg hover:shadow-xl bg-highlight duration-300'>
        
      </div> */}
    </div>
  );
};

export default TransporatationBenefitsPage;
