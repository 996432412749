import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Button from "../../../components/button";
import FormDatalist from "../../../components/form-datalist";
import FormInput from "../../../components/form-input";
import FormSelect from "../../../components/form-select";
import LoadingBar from "../../../components/loader-bar";
import {
  useGetBanks,
  useGetPFA,
  useResolveBankAccount,
} from "../../../redux/bank/hook";
import { formValidation } from "../../../utils/functions";
import { countriesAndState, AllCountries } from "../../../constants/countries";
import { usePreRegisterEmployee } from "../../../redux/employees/hook";
import AuthLayout from "../../../components/layouts/auth";

function PreRegisterEmployee() {
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const { mutateAsync, isLoading: loading } = usePreRegisterEmployee();
  const [gender, setGender] = useState("Male");
  const [country, setCountry] = useState("Nigeria");
  const [state, setState] = useState("Lagos");
  const [stateOptions, setStateOptions] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState("single");
  const [pfa_name, setPFAName] = useState("");
  const [bankname, setBankName] = useState("");
  const [account_number, setAccountNumber] = useState("");
  const [account_name, setAccountName] = useState("");

  const { mutateAsync: resolveAccount, isLoading: resolveAccountLoading } =
    useResolveBankAccount();
  const { data: banks } = useGetBanks();
  const { data: PFAs } = useGetPFA();

  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  const maritalOptions = [
    { value: "single", label: "Single" },
    { value: "married", label: "Married" },
  ];

  const { token } = useParams();

  useEffect(() => {
    if (PFAs) {
      setPFAName(PFAs[0].value);
    }
  }, [PFAs]);

  useEffect(() => {
    if (account_number.length >= 10 && bankname !== "") {
      let payload = {
        bankname,
        account_number,
      };
      setAccountName("");
      resolveAccount(payload)
        .then((response) => {
          setAccountName(response.data.accountname);
        })
        .catch(() => {
          setAccountName("Not Resolved");
        });
    }
  }, [account_number, bankname]);

  useEffect(() => {
    if (country) {
      let getStates = countriesAndState.find((a) => a.name === country);

      if (getStates) {
        let computedStates = [];

        getStates.states.map((item, index) => {
          computedStates.push({ label: item, value: item });
        });

        setStateOptions(computedStates);
      }
    }
  }, [country]);

  const submitForm = async (data) => {
    const first_name = DOMPurify.sanitize(data?.first_name);
    const middle_name = DOMPurify.sanitize(data?.middle_name);
    const last_name = DOMPurify.sanitize(data?.last_name);
    const email = DOMPurify.sanitize(data?.email);
    const mobile = DOMPurify.sanitize(data?.mobile);
    const dob = DOMPurify.sanitize(data?.dob);
    const address = DOMPurify.sanitize(data?.address);
    const city = DOMPurify.sanitize(data?.city);
    const account_number = DOMPurify.sanitize(data?.account_number);
    const account_name = DOMPurify.sanitize(data?.account_name);
    const rsa_pin = DOMPurify.sanitize(data?.rsa_pin);
    const tax_number = DOMPurify.sanitize(data?.tax_number);
    const street_address = DOMPurify.sanitize(data?.street_address);
    const next_of_kin_name = DOMPurify.sanitize(data?.next_of_kin_name);
    const next_of_kin_mobile = DOMPurify.sanitize(data?.next_of_kin_mobile);
    const next_of_kin_email = DOMPurify.sanitize(data?.next_of_kin_email);
    const next_of_kin_relationship = DOMPurify.sanitize(
      data?.next_of_kin_relationship
    );
    const next_of_kin_address = DOMPurify.sanitize(data?.next_of_kin_address);

    const requestData = {
      token,
      first_name,
      middle_name,
      last_name,
      email,
      mobile,
      gender,
      dob,
      marital_status: maritalStatus,
      country: country,
      address,
      city,
      state: state,
      bank: bankname,
      account_number,
      street_address,
      account_name: account_name ? account_name : "Not Resolved",
      pfa_name,
      rsa_pin,
      tax_number,
      next_of_kin_name,
      next_of_kin_mobile,
      next_of_kin_email,
      next_of_kin_relationship,
      next_of_kin_address,
    };

    await mutateAsync(requestData).then((result) => {
      window.location.assign("https://www.employees.ropay.africa/register");
    });
  };

  return (
    <AuthLayout step={1} title={"Enter your information"}>
      <div className="flex flex-col w-full h-full">
        <span className="md:header-3 header-4 text-center">
          Employee pre-registration
        </span>
        <span className="text-[13px] text-center text-color-gray">
          Provide your personal details for your employer's records
        </span>
        <hr className="divider mb-2 mt-2" />
        <form className="form mt-2" onSubmit={handleSubmit(submitForm)}>
          <div className="p-2 rounded-md border bg-secondary-2-extralight">
            <div className="header-4 mb-2 text-color-gray">
              Employee Personal Information
            </div>
            <div className="flex flex-col md:flex-row justify-between">
              <div className="w-full md:w-1/2 md:pr-2">
                <FormInput
                  label="First name"
                  name="first_name"
                  type="text"
                  className={"!bg-white"}
                  placeholder={"Enter First Name"}
                  inputRef={register(formValidation("text", true))}
                  readOnly={loading}
                  error={errors.first_name}
                  errorMessage={errors.first_name && errors.first_name.message}
                />
              </div>
              <div className="w-full md:w-1/2 md:pl-2">
                <FormInput
                  label="Last name"
                  name="last_name"
                  type="text"
                  className={"!bg-white"}
                  placeholder={"Enter Last Name"}
                  inputRef={register(formValidation("text", true))}
                  readOnly={loading}
                  error={errors.last_name}
                  errorMessage={errors.last_name && errors.last_name.message}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between">
              <div className="w-full md:w-1/2 md:pr-2">
                <FormInput
                  label="Middle Name - (Optional)"
                  name="middle_name"
                  type="text"
                  className={"!bg-white"}
                  placeholder={"Enter Middle Name"}
                  inputRef={register(formValidation("text", false))}
                  readOnly={loading}
                  error={errors.middle_name}
                  errorMessage={
                    errors.middle_name && errors.middle_name.message
                  }
                />
              </div>

              <div className="w-full md:w-1/2 md:pl-2">
                <FormInput
                  label="Phone Number"
                  name="mobile"
                  type="text"
                  className={"!bg-white"}
                  placeholder={"Enter Phone Number"}
                  inputRef={register(formValidation("text", true))}
                  readOnly={loading}
                  error={errors.mobile}
                  errorMessage={errors.mobile && errors.mobile.message}
                />
              </div>
            </div>
            <FormInput
              label="Email"
              name="email"
              type="email"
              className={"!bg-white"}
              placeholder={"Enter Email"}
              inputRef={register(formValidation("email", true))}
              readOnly={loading}
              error={errors.email}
              errorMessage={errors.email && errors.email.message}
            />
            <div className="flex flex-col md:flex-row justify-between">
              <div className="w-full md:w-1/2 md:pr-2">
                <FormSelect
                  defaultValue={gender}
                  options={genderOptions}
                  onChange={(selected) => {
                    setGender(selected);
                  }}
                  label="Gender"
                  extraClass={"!bg-white"}
                />
              </div>

              <div className="w-full md:w-1/2 md:pl-2">
                <FormSelect
                  defaultValue={maritalStatus}
                  options={maritalOptions}
                  onChange={(selected) => {
                    setMaritalStatus(selected);
                  }}
                  label="Marital Status"
                  extraClass={"!bg-white"}
                />
              </div>
            </div>
            <div className="flex md:flex-row flex-col w-full justify-between">
              <div className="w-full md:w-1/2 md:pr-2">
                <FormInput
                  label="Date Of Birth"
                  name="dob"
                  type="date"
                  inputRef={register(formValidation("date", true))}
                  error={errors.dob}
                  errorMessage={errors.dob && errors.dob.message}
                  className={"!bg-white"}
                />
              </div>
              <div className="w-full md:w-1/2 md:pl-2">
                <FormSelect
                  defaultValue={country}
                  options={AllCountries}
                  onChange={(selected) => {
                    setCountry(selected);
                  }}
                  label="Country"
                  extraClass={"!bg-white"}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between">
              <div className="w-full md:w-1/2 md:pr-2">
                {country == "Nigeria" ? (
                  <FormSelect
                    defaultValue={state}
                    options={stateOptions}
                    onChange={(selected) => {
                      setState(selected);
                    }}
                    label="State"
                    extraClass={"!bg-white"}
                  />
                ) : (
                  <FormInput
                    label="State"
                    name="state"
                    type="text"
                    placeholder={"Enter State"}
                    value={state}
                    onInput={(e) => setState(e.target.value)}
                    error={errors.state}
                    errorMessage={errors.state && errors.state.message}
                    className={"!bg-white"}
                  />
                )}
              </div>
              <div className="w-full md:w-1/2 md:pl-2">
                <FormInput
                  label="City"
                  name="city"
                  type="text"
                  placeholder={"Enter City"}
                  inputRef={register(formValidation("text", true))}
                  readOnly={loading}
                  error={errors.city}
                  errorMessage={errors.city && errors.city.message}
                  className={"!bg-white"}
                />
              </div>
            </div>
            <FormInput
              label="Stress Address"
              name="street_address"
              type="text"
              placeholder={"Enter Street Address"}
              inputRef={register(formValidation("text", true))}
              readOnly={loading}
              error={errors.street_address}
              errorMessage={errors.address && errors.street_address.message}
              className={"!bg-white"}
            />
          </div>
          <hr className="divider mb-2 mt-2" />
          <div className="p-2 rounded-md border bg-secondary-2-extralight">
            <div className="header-4 mb-2 text-color-gray">
              Payroll Information
            </div>
            <div className="flex flex-col md:flex-row justify-between">
              <div className="w-full md:w-1/2 md:pr-2">
                <FormDatalist
                  label="Bank Name"
                  data={banks ? banks : []}
                  value={bankname}
                  onSelect={(value) => {
                    setAccountNumber("");
                    setBankName(value);
                  }}
                />
              </div>

              <div className="w-full md:w-1/2 md:pl-2">
                <FormInput
                  label="Bank Account Number"
                  placeholder={"Enter Account Number"}
                  name="account_number"
                  type="text"
                  value={account_number}
                  inputRef={register(formValidation("text", true))}
                  error={errors.account_number}
                  errorMessage={
                    errors.account_number && errors.account_number.message
                  }
                  onInput={(e) => setAccountNumber(e.target.value)}
                  className={"!bg-white"}
                />
              </div>
            </div>
            <div className="w-full">
              <FormInput
                label="Account Name (Auto-Generated)"
                name="account_name"
                type="text"
                placeholder={
                  resolveAccountLoading
                    ? "Fetching Account Name..."
                    : "Auto Generated"
                }
                readOnly
                inputRef={register(formValidation("text", true))}
                value={account_name}
                error={errors.account_name}
                errorMessage={
                  errors.account_name && errors.account_name.message
                }
                className={"!bg-white"}
              />
            </div>
            {PFAs ? (
              <FormSelect
                value={pfa_name}
                options={PFAs}
                onChange={(selected) => {
                  setPFAName(selected);
                }}
                label="Pension Fund Administrator (PFA) - (Optional)"
                extraClass={"!bg-white"}
              />
            ) : (
              <LoadingBar loading={true} />
            )}
            <div className="flex flex-col md:flex-row justify-between">
              <div className="w-full md:w-1/2 md:pr-2">
                <FormInput
                  label="RSA PIN - (Optional)"
                  name="rsa_pin"
                  type="text"
                  placeholder={"Enter RSA Pin"}
                  inputRef={register(formValidation("text", false))}
                  readOnly={loading}
                  className={"!bg-white"}
                />
              </div>
              <div className="w-full md:w-1/2 md:pl-2">
                <FormInput
                  label="Tax id - (Optional)"
                  name="tax_number"
                  type="text"
                  placeholder={"Enter Tax Number"}
                  inputRef={register(formValidation("text", false))}
                  readOnly={loading}
                  className={"!bg-white"}
                />
              </div>
            </div>
          </div>
          <hr className="divider mb-2 mt-2" />
          <div className="p-2 rounded-md border bg-secondary-2-extralight">
            <div className="header-4 mb-2 text-color-gray">
              Next Of Kin Information - (Optional)
            </div>
            <FormInput
              label="Next Of Kin Name - (Optional)"
              name="next_of_kin_name"
              type="text"
              placeholder={"Enter Full Name"}
              inputRef={register(formValidation("text", false))}
              readOnly={loading}
              error={errors.next_of_kin_name}
              errorMessage={
                errors.next_of_kin_name && errors.next_of_kin_name.message
              }
              className={"!bg-white"}
            />
            <div className="flex flex-col md:flex-row justify-between">
              <div className="w-full md:w-1/2 md:pr-2">
                <FormInput
                  label="Next Of Kin Mobile - (Optional)"
                  name="next_of_kin_mobile"
                  type="text"
                  placeholder={"Enter Next Of Kin Mobile"}
                  inputRef={register(formValidation("text", false))}
                  readOnly={loading}
                  error={errors.next_of_kin_mobile}
                  errorMessage={
                    errors.next_of_kin_mobile &&
                    errors.next_of_kin_mobile.message
                  }
                  className={"!bg-white"}
                />
              </div>
              <div className="w-full md:w-1/2 md:pl-2">
                <FormInput
                  label="Next Of Kin Email - (Optional)"
                  name="next_of_kin_email"
                  type="text"
                  placeholder={"Enter Next Of Kin Email"}
                  inputRef={register(formValidation("text", false))}
                  readOnly={loading}
                  error={errors.next_of_kin_email}
                  errorMessage={
                    errors.next_of_kin_email && errors.next_of_kin_email.message
                  }
                  className={"!bg-white"}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between">
              <div className="w-full md:w-1/2 md:pr-2">
                <FormInput
                  label="Relationship - (Optional)"
                  name="next_of_kin_relationship"
                  type="text"
                  placeholder={"What is your relationship?"}
                  inputRef={register(formValidation("text", false))}
                  readOnly={loading}
                  error={errors.next_of_kin_relationship}
                  errorMessage={
                    errors.next_of_kin_relationship &&
                    errors.next_of_kin_relationship.message
                  }
                  className={"!bg-white"}
                />
              </div>
              <div className="w-full md:w-1/2 md:pl-2">
                <FormInput
                  label="Next Of Kin Address - (Optional)"
                  name="next_of_kin_address"
                  type="text"
                  placeholder={"Enter Address of Next Of Kin"}
                  inputRef={register(formValidation("text", false))}
                  readOnly={loading}
                  error={errors.next_of_kin_address}
                  errorMessage={
                    errors.next_of_kin_address &&
                    errors.next_of_kin_address.message
                  }
                  className={"!bg-white"}
                />
              </div>
            </div>
          </div>

          <div className="w-full mt-[20px]">
            <Button text="Save and Close" type="submit" loading={loading} />
          </div>
        </form>
      </div>
    </AuthLayout>
  );
}

export default PreRegisterEmployee;
