import React, { useEffect } from "react";
import { useState } from "react";
import FormSelect from "../form-select";
import Button from "../button";
import FormInput from "../form-input";
import LoadingBar from "../loader-bar";
import {
  useAddCompanyHMOSettings,
  useGetHMOPlans,
  useGetHMOSettings,
  useGetHMOs,
  useGetOnboardingHMOPlans,
  useUpdateHMOSettings,
} from "../../redux/hmo/hook";
import FormSwitch from "../form-switch";
import { formatNumber } from "../../utils/functions";

const HMOSettingsTab = () => {
  const { data: hmo_settings, isLoading: settingsLoading } =
    useGetHMOSettings();
  const [canSelectHMO, setCanSelectHMO] = useState(false);
  const [hmoName, setHmoName] = useState("");
  const [hmoId, setHmoId] = useState(null);
  const [hmoPlan, setHmoPlan] = useState("");
  const [planId, setPlanId] = useState(null);
  const [hmoBudget, setHmoBudget] = useState("");
  const [maxSubscriptionMonth, setMaxSubscriptionMonth] = useState(0);
  const [canAddDependants, setCanAddDependants] = useState(0);
  const [maxDependants, setMaxDependants] = useState();
  const [autoRenew, setAutoRenew] = useState(0);

  const [hmoAddType, setHmoAddType] = useState("add");

  useEffect(() => {
    if (hmo_settings) {
      setHmoAddType("update");
    }
  }, [hmo_settings]);

  useEffect(() => {
    if (hmo_settings) {
      setHmoName(hmo_settings.hmo_name);
      setCanSelectHMO(hmo_settings.can_select_hmo);
      setHmoId(hmo_settings.hmo_id);
      setHmoPlan(hmo_settings.hmo_plan);
      setPlanId(hmo_settings.hmo_plan_id);
      setHmoBudget(hmo_settings.hmo_budget);
      setMaxSubscriptionMonth(hmo_settings.max_subscription_months);
      setCanAddDependants(hmo_settings.can_add_dependant);
      setMaxDependants(hmo_settings.max_dependants);
      setAutoRenew(hmo_settings.auto_renew);
    }
  }, [hmo_settings]);

  const { data: hmos } = useGetHMOs();

  const { data: hmoPlansCompleteInformation, isLoading: completePlansLoading } =
    useGetHMOPlans(hmoId);

  const { data: hmoPlans, isLoading: plansLoading } =
    useGetOnboardingHMOPlans(hmoId);

  const [CurrentHrPlan, setCurrentHrPlan] = useState();

  useEffect(() => {
    if (planId && hmoPlansCompleteInformation) {
      let index = hmoPlansCompleteInformation?.findIndex(
        (item) => item.id == planId
      );
      if (index != -1) {
        setCurrentHrPlan(hmoPlansCompleteInformation[index]);
        setHmoBudget(hmoPlansCompleteInformation[index].price);
        setMaxSubscriptionMonth(
          hmoPlansCompleteInformation[index].payment_duration == "0"
            ? 1
            : hmoPlansCompleteInformation[index].payment_duration
        );
      }
    }
  }, [planId, hmoPlansCompleteInformation]);

  useEffect(() => {
    if (hmoId) {
      setCurrentHrPlan(null);
      setHmoBudget(null);
    }
  }, [hmoId]);

  const [hmoData, setHmoData] = useState([
    { value: "", label: "No HMO Selected", hmo_name: "" },
  ]);

  const [hmoPlanData, setHmoPlanData] = useState([
    { value: "", label: "No Plan Selected", plan_name: "" },
  ]);

  useEffect(() => {
    if (hmos) {
      setHmoData(hmos);
    }
  }, [hmos]);

  useEffect(() => {
    if (hmoPlans) {
      setHmoPlanData(hmoPlans);
    }
  }, [hmoPlans]);

  const {
    mutateAsync: updateHMOSettings,
    isLoading: updateHMOSettingsLoading,
  } = useUpdateHMOSettings();
  const {
    mutateAsync: addCompanyHMOSettings,
    isLoading: addCompanyHMOSettingsLoading,
  } = useAddCompanyHMOSettings();

  const submitForm = async () => {
    let payload = {
      can_select_hmo: canSelectHMO ? 1 : 0,
      hmo_name: hmoName,
      hmo_id: hmoId,
      hmo_plan: hmoPlan,
      hmo_plan_id: planId,
      hmo_budget: hmoBudget,
      max_subscription_months: maxSubscriptionMonth,
      can_add_dependant: canAddDependants ? 1 : 0,
      max_dependants: maxDependants,
      auto_renew: autoRenew,
    };

    if (hmoAddType == "update") {
      await updateHMOSettings(payload);
    } else {
      await addCompanyHMOSettings(payload).then(() => {
        window.location.reload();
      });
    }
  };

  return (
    <>
      <div className="flex flex-col bg-white w-full  rounded-md py-3 h-full min-h-fit ">
        <div className="flex flex-col px-4">
          <span className="p1-bold ">HMO Settings</span>
          <span className="text-[13px] text-color-gray w-full md:w-[100%]">
            Update and edit your HMO settings
          </span>
        </div>
        <hr className="divider" />
        <div className="flex flex-col px-4">
          <div className="flex flex-col md:flex-row">
            <div className="flex w-full max-w-[100%] px-4 md:px-0 flex-col">
              {settingsLoading ? (
                <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
                  <LoadingBar loading={settingsLoading} />
                </div>
              ) : (
                <div className="flex flex-row">
                  <div className="w-full">
                    <div className={"flex w-full flex-col font-bold mt-2"}>
                      <FormSwitch
                        label={"Employee Can Select HMO"}
                        name={"can_select_hmo"}
                        readOnly={updateHMOSettingsLoading}
                        checked={canSelectHMO}
                        onClick={() => {
                          if (!canSelectHMO) {
                            setHmoBudget(0);
                            setMaxSubscriptionMonth(0);
                            setCanSelectHMO(!canSelectHMO);
                          } else {
                            let index = hmoPlansCompleteInformation?.findIndex(
                              (item) => item.id == planId
                            );
                            if (index && index != -1) {
                              setCurrentHrPlan(
                                hmoPlansCompleteInformation[index]
                                  ? hmoPlansCompleteInformation[index]
                                  : null
                              );
                              setHmoBudget(
                                hmoPlansCompleteInformation[index].price
                                  ? hmoPlansCompleteInformation[index].price
                                  : null
                              );
                              setMaxSubscriptionMonth(
                                hmoPlansCompleteInformation[index]
                                  .payment_duration
                                  ? hmoPlansCompleteInformation[index]
                                      .payment_duration
                                  : null
                              );
                            }
                            setCanSelectHMO(!canSelectHMO);
                          }
                        }}
                      />
                    </div>
                    <div className="flex flex-col p-4 border rounded-md !bg-gray-50">
                      {!canSelectHMO ? (
                        <>
                          <div className="flex md:flex-row flex-col w-full justify-between gap-2">
                            <div className="md:w-1/2 w-full md:pr-2">
                              <FormSelect
                                value={hmoId}
                                options={hmoData}
                                onChange={(selected) => {
                                  setHmoId(selected);
                                }}
                                label="Select HMO"
                              />
                            </div>
                            {hmoPlans ? (
                              <div className="md:w-1/2 w-full md:pl-2">
                                <FormSelect
                                  value={planId}
                                  options={hmoPlanData}
                                  onChange={(selected) => {
                                    setPlanId(selected);
                                  }}
                                  label="Select HMO Plan"
                                />
                              </div>
                            ) : plansLoading && hmoId ? (
                              <div className="flex flex-col w-full text-center align-center justify-center">
                                <LoadingBar loading={plansLoading && hmoId} />
                              </div>
                            ) : (
                              <div className="flex flex-col md:w-1/2 w-full text-center align-center justify-center">
                                No plan fetched
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col p-2 border rounded mb-2 w-full">
                            {CurrentHrPlan ? (
                              <>
                                <div className="text-gray-500 text-[15px]">
                                  Plan Name
                                </div>
                                <div className="text-[13px] font-extrabold">
                                  {CurrentHrPlan?.name}
                                </div>
                                <hr className="divider" />
                                <div className="font-bold text-[16px] mb-2">
                                  Plan Coverage
                                </div>
                                <div className="grid md:grid-cols-3 grid-cols-3 gap-4 w-full pt-2 duration-500">
                                  <div className="flex flex-col">
                                    <div className="text-gray-500 text-[14px]">
                                      Plan Type
                                    </div>
                                    <div className="text-[13px]">
                                      {CurrentHrPlan?.Plan_type}
                                    </div>
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="text-gray-500 text-[14px]">
                                      An Pn Childbirth
                                    </div>
                                    <div className="text-[13px]">
                                      {CurrentHrPlan?.an_pn_childbirth}
                                    </div>
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="text-gray-500 text-[14px]">
                                      Dental
                                    </div>
                                    <div className="text-[13px]">
                                      {CurrentHrPlan?.dental}
                                    </div>
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="text-gray-500 text-[14px]">
                                      Drugs
                                    </div>
                                    <div className="text-[13px]">
                                      {CurrentHrPlan?.drugs}
                                    </div>
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="text-gray-500 text-[14px]">
                                      Emergency
                                    </div>
                                    <div className="text-[13px]">
                                      {CurrentHrPlan?.emergency}
                                    </div>
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="text-gray-500 text-[14px]">
                                      Gym
                                    </div>
                                    <div className="text-[13px]">
                                      {CurrentHrPlan?.gym}
                                    </div>
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="text-gray-500 text-[14px]">
                                      Gynecology
                                    </div>
                                    <div className="text-[13px]">
                                      {CurrentHrPlan?.gynecology}
                                    </div>
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="text-gray-500 text-[14px]">
                                      Optical care
                                    </div>
                                    <div className="text-[13px]">
                                      {CurrentHrPlan?.optical_care}
                                    </div>
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="text-gray-500 text-[14px]">
                                      Surgery Coverage
                                    </div>
                                    <div className="text-[13px]">
                                      {formatNumber(
                                        CurrentHrPlan?.surgery_coverage,
                                        2
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="text-gray-500 text-[14px]">
                                      Ward Type
                                    </div>
                                    <div className="text-[13px]">
                                      {CurrentHrPlan?.ward_type}
                                    </div>
                                  </div>
                                  <div className="flex flex-col justify-center">
                                    <div className="text-gray-500 text-[14px] hover:underline hover:italic">
                                      <a
                                        href={
                                          CurrentHrPlan?.benefit_detail_link
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        View Full Benefits
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {completePlansLoading && planId ? (
                                  <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
                                    <LoadingBar loading={plansLoading} />
                                  </div>
                                ) : (
                                  <div className="flex p-2 justify-center items-center w-full text-[14px] font-extrabold">
                                    No HMO Plan selected
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </>
                      ) : null}
                      <div className="flex flex-col md:flex-row w-full justify-between gap-2 md:gap-4">
                        <div className="md:w-1/2 w-full md:pr-2">
                          <FormInput
                            label={
                              canSelectHMO
                                ? "HMO Budget (Monthly) - Editable"
                                : "HMO Plan Price - (ReadOnly)"
                            }
                            placeholder={"Enter budget"}
                            name={"hmo_budget"}
                            type="number"
                            onInput={(e) => setHmoBudget(e.target.value)}
                            readOnly={canSelectHMO ? false : true}
                            value={hmoBudget}
                          />
                        </div>
                        <div className="md:w-1/2 w-full md:pl-2">
                          <FormInput
                            label={
                              canSelectHMO
                                ? "Subscription Month - (Editable)"
                                : "Subscription Month - (ReadOnly)"
                            }
                            name={"max_subscription_months"}
                            type="number"
                            onInput={(e) =>
                              setMaxSubscriptionMonth(e.target.value)
                            }
                            readOnly={canSelectHMO ? false : true}
                            value={maxSubscriptionMonth}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="divider" />
                    <div className={"flex w-full flex-col"}>
                      <div className="flex flex-row md:flex-col w-full justify-between gap-4 items-center">
                        <div className="w-full md:w-1/2 !text-[13px] mt-3">
                          <FormSwitch
                            label={"Can employees add Dependants?"}
                            name={"can_add_dependants"}
                            readOnly={updateHMOSettingsLoading}
                            checked={canAddDependants}
                            onClick={() =>
                              setCanAddDependants(!canAddDependants)
                            }
                          />
                        </div>
                        <div className="w-full md:w-1/2 text-[13px]">
                          <FormInput
                            label="How many dependants?"
                            placeholder={"Max. Dependants"}
                            name="max_dependants"
                            className={"max-h-[30px] max-w-[100%]"}
                            type="number"
                            value={maxDependants}
                            onInput={(e) => setMaxDependants(e.target.value)}
                            readOnly={updateHMOSettingsLoading}
                          />
                        </div>
                      </div>
                      <div className="mt-2 md:w-[50%] w-full">
                        <FormSwitch
                          label={"Auto Renew HMO"}
                          name={"auto_renew"}
                          readOnly={updateHMOSettingsLoading}
                          checked={autoRenew}
                          onClick={() => setAutoRenew(!autoRenew)}
                        />
                      </div>
                    </div>
                    <div className="flex justify-end w-full">
                      <Button
                        text={
                          hmoAddType == "update"
                            ? "UPDATE HMO SETTINGS"
                            : "SAVE HMO SETTINGS"
                        }
                        className="flex gap-2 !h-[35px] w-fit p-2 "
                        textClass={"!text-[11px]"}
                        type="button"
                        onClick={() => {
                          submitForm();
                        }}
                        loading={
                          updateHMOSettingsLoading ||
                          addCompanyHMOSettingsLoading
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HMOSettingsTab;
