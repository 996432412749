
import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import Button from '../../button';
import FormSelect from '../../form-select';
import AsyncSelect from 'react-select/async';
import { employees as employeeAPI } from '../../../api/employees';
import Modal from '../modal';
import { useDispatch } from 'react-redux';
import { useAddBeneficiaries, useGetOnboardingHMOGroups } from '../../../redux/hmo/hook';
import FormSwitch from '../../form-switch';
import AddSmallSVG from '../../../assets/svg/add-icon-small.svg';
import AddEmployeeHMOGroup from './add-hmo-group';

function AddHMOBeneficiaries({
  isOpen,
  closeModal,
}) {

    const [type, setType] = useState('all');

    const [employees, setEmployees] = useState([]);
    const [groupId, setGroupId] = useState(null);
    const [useGroupSettings, setUseGroupSetting] = useState(false)
    const [showAddHMOGroup, setShowAddHMOGroup] = useState(false);

    const dispatch = useDispatch();

    const loadOptions = (inputValue) =>
        new Promise((resolve) => {
        employeeAPI
            .getEmployees({
            limit: 10,
            statusFilter: 1,
            search: inputValue,
            page: 1,
            })
            .then((response) => {
            let data = [];

            response.data.forEach((item) => {
                data.push({
                label: `${item.first_name} ${item.last_name} | ${item.mobile} | ${item.email}`,
                value: item.id,
                });
            });

            resolve(data);
            })
            .catch((err) => {
            resolve([]);
        });
    });

    const { data: employeeGroups } = useGetOnboardingHMOGroups(
        1000,
        1,
        -1,
        '',
      );

    const { mutateAsync: addBeneficiary, isLoading: addBeneficiaryLoading } = useAddBeneficiaries();

    const typeOptions = [
        {
            label: 'All Employees',
            value: 'all',
        },
        {
            label: 'Select Employees',
            value: 'select',
        },
    ];

    const submitForm = async () => {
        let employee_ids = '';
        if (type == 'select') {
            if (employees.length === 0) {
                dispatch(setAlert(true, 'info', 'Select at least one employee'));
                return;
            }
            let employeeArrays = [];
    
                employees.forEach((item) => {
                employeeArrays.push(item.value);
            });
    
            employee_ids = employeeArrays.join();
        } else {
            employee_ids = 'none';
        }
        let payload = {
            type,
            employee_ids,
            group_id: groupId
        }

        await addBeneficiary(payload).then(() => {
            closeModal();
        });
    };

    return (
        <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
        >
        <div className='flex flex-col pb-4 overflow-auto'>
            {showAddHMOGroup?
            <AddEmployeeHMOGroup
                isOpen={showAddHMOGroup}
                closeModal={() => setShowAddHMOGroup(false)}
                groupData={null}
                title={'Add HMO Group'}
                buttonText={'Save'}
            />
            :null}
            <div className='pl-8 pr-8 pt-4'>
                <div className='flex flex-row justify-between items-center'>
                    <span className='header-4 items-center'>Add HMO Beneficiaries</span>
                    <div className='cursor-pointer' onClick={() => closeModal()}>
                        <img src={CloseSVG} alt='close' />
                    </div>
                </div>
            </div>
            <hr className='divider mb-2' />
            <div className='flex flex-col px-8'>
                <FormSelect
                    value={type}
                    options={typeOptions}
                    onChange={(selected) => {
                    setType(selected);
                    }}
                    label='Type'
                />
                
                {type == 'all'?
                null:
                <>
                    <hr className='divider mt-2' />
                    <div className='border bg-secondary-2-extralight p-2 rounded-md mb-2'>
                        <label className='text-[13px] leading-[23px] font-normal text-color-gray mb-1'>
                            Select Employees
                        </label>
                        <AsyncSelect
                            isMulti
                            cacheOptions
                            defaultOptions
                            className='text-[13px]'
                            placeholder={'Type to select multiple employees'}
                            loadOptions={loadOptions}
                            onChange={(options) => setEmployees(options)}
                            classNamePrefix='mySelect'
                        />
                    </div>
                </>
                }
                <hr className='divider mt-2' />
                <div className={'flex w-full flex-col font-bold '}>
                    <FormSwitch
                        label={'Use HMO Group Settings'}
                        name={'use_group_settings'}
                        checked={useGroupSettings}
                        onClick={() =>
                            setUseGroupSetting(!useGroupSettings)
                        }
                    />
                </div>
                <hr className='divider -mt-2' />
                {useGroupSettings?
                <>
                    {employeeGroups ? (
                        <FormSelect
                        options={employeeGroups}
                        readOnly={employeeGroups? false: true}
                        onChange={(selected) => {
                            setGroupId(selected);
                        }}
                        label='HMO Groups'
                        />
                    ) : 
                    <div className='flex justify-center text-[13px] text-color-black'>
                        No HMO Group Found. Click link below to add a new HMO Group.
                    </div>
                    }
                    <hr className='divider' />
                    <div className='flex flex-row items-center gap-2 justify-left w-full cursor-pointer'>
                        <img src={AddSmallSVG} alt={'add hmo group'} className={'h-[15px] w-[15px]'} />
                        <span
                        className='p4-medium hover:underline hover:italic hover:font-bold text-primary-3 duration-75'
                        onClick={() => setShowAddHMOGroup(true)}
                        >
                            Add new HMO group
                        </span>
                    </div>
                </>
                :
                null
                }
                <div className='w-full mt-[20px]'>
                    <Button
                        text={'ADD BENEFICIARIES'}
                        loading={addBeneficiaryLoading}
                        onClick={() => {
                            submitForm();
                        }}
                    />
                </div>
            </div>
        </div>
        </Modal>
    );
}

export default AddHMOBeneficiaries;
