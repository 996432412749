import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseSVG from '../../../assets/svg/close.svg';
import Button from '../../button';
import AsyncSelect from 'react-select/async';
import { employees as employeeAPI } from '../../../api/employees';
import { useAddEmployeesToGroup } from '../../../redux/employees/hook/groups';
import { setAlert } from '../../../redux/components/components-slice';
import Modal from '../modal';

function AddEmployeeToGroup({ isOpen, closeModal, groupData, refresh }) {
  const [employees, setEmployees] = useState([]);
  const { mutateAsync: addToGroup, isLoading } = useAddEmployeesToGroup();
  const dispatch = useDispatch();

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      employeeAPI
        .getEmployees({
          limit: 20,
          statusFilter: -1,
          search: inputValue,
          page: 1,
        })
        .then((response) => {
          let data = [];

          response.data.forEach((item) => {
            data.push({
              label: `${item.first_name} ${item.last_name} | ${item.mobile} | ${item.email}`,
              value: item.id,
            });
          });

          resolve(data);
        })
        .catch((err) => {
          resolve([]);
        });
    });

  const submit = () => {
    if (employees.length === 0) {
      dispatch(setAlert(true, 'info', 'Select at least one employee'));
      return;
    }
    let employeeArrays = [];

    employees.forEach((item) => {
      employeeArrays.push(item.value);
    });

    let employee_ids = employeeArrays.join();

    let payload = {
      group: groupData.id,
      employees: employee_ids,
    };

    addToGroup(payload).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col pb-8 overflow-auto h-fit'>
        <div className='flex flex-row justify-between pl-8 pr-8 pt-4 items-center'>
          <span className='header-4'>Add Employee To Payroll Group</span>
          <div className='cursor-pointer' onClick={() => closeModal()}>
            <img src={CloseSVG} alt='close' />
          </div>
        </div>
        <hr className='divider mb-2' />
        {groupData ? (
        <div className='flex flex-col mx-8'>
          <div className='flex flex-col mt-'>
            <div className='flex flex-col border rounded-md p-2 bg-highlight'>
              <label className='text-[15px] leading-[23px] font-semibold text-color-gray'>
                Group Name
              </label>
              <span className='text-[14px]'>{groupData?.name}</span>
            </div>
            <hr className='divider mt-2' />
            <div >
              <label className='text-[13px] leading-[23px] font-normal text-color-gray mb-1'>
                Add Employees
              </label>
              <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions
                className='text-[13px]'
                loadOptions={loadOptions}
                onChange={(options) => setEmployees(options)}
                classNamePrefix='mySelect'
              />
            </div>
          </div>
          <hr className='divider' />
          <div className='w-full'>
            <Button
              text='ADD TO GROUP'
              type='submit'
              loading={isLoading}
              onClick={() => submit()}
            />
          </div>
        </div>
        )
        :
        <div className='flex !h-[35px] w-full justify-center items-center'>
          No group data fetched
        </div>
        }
      </div>
    </Modal>
  );
}

export default AddEmployeeToGroup;
