import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DashboardLayout from "../../components/layouts/dashboard";
import UnauthorizedPage from "../errors/unauthorized";
import RochatSetting from "../../components/ro-chat/rochat-settings";
import Rochat from "../../components/ro-chat/chat";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const RochatPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  const [currentLocation, setCurrentLocation] = useState("chats");

  const location = useLocation();

  useEffect(() => {
    if (location) {
      setCurrentLocation(location.pathname.split("/")[2]);
    }
  }, [location]);

  useEffect(() => {
    if (user) {
      if (user.role !== "Owner") {
        if (user.permissions?.company_permission?.setting_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  return (
    <DashboardLayout title={"Ro-chat"}>
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <div className="flex flex-col -mt-4">
          <div className="" id="chat">
            {/* {currentLocation ==='chats' ?
            <>
              {user?.permissions?.company_permission?.role === 'Owner' ||
                user?.permissions?.company_permission?.setting_read === 1
              ? <Rochat />
              : <UnauthorizedPage />
              }
            </>
            : currentLocation ==='settings' ?
            <>
              {user?.permissions?.company_permission?.role === 'Owner' ||
                  user?.permissions?.company_permission?.setting_read === 1
              ? <RochatSetting />
              : <UnauthorizedPage />
              }
            </>
            : null
            } */}
            Coming soon
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default RochatPage;
