import { Axios } from './axios';

const getWalletHistory = async (payload) => {
  let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
  let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  let type = payload.type ? '&type=' + payload.type : '';

  const { data } = await Axios.get(`/wallet/history?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}${filter}${type}`);
  return data?.data;
};

const getBranchWalletHistory = async (payload) => {
  let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
  let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  let type = payload.type ? '&type=' + payload.type : '';

  const { data } = await Axios.get(`/wallet/history/by-branch/${payload.id}?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}${filter}${type}`);
  return data?.data;
};

const exportAccountStatement = async (payload) => {
  const { data } = await Axios.post('/bonuses/bulk', payload);
  return data;
};

const getAccountDetails = async () => {
  const { data } = await Axios.get('/wallet/account-details');
  return data?.data;
};

const retryTransactionToAnotherBank = async (payload) => {
  const { data } = await Axios.post(`/transactions/retry/${payload.id}/different-bank`, payload);
  return data?.data;
};

const checkBVNVerification = async (payload) => {
  const { data } = await Axios.get(`/wallet/check-bvn-verification`);
  return data?.data;
};

const verifyBVN = async (payload) => {
  const { data } = await Axios.post(`/wallet/verify-bvn`, payload);
  return data;
};

const generateWalletDetails = async (payload) => {
  const { data } = await Axios.post(`/wallet/generate-account-details`, payload);
  return data;
};

export const walletInformation = {
  getWalletHistory,
  getBranchWalletHistory,
  exportAccountStatement,
  getAccountDetails,
  retryTransactionToAnotherBank,
  checkBVNVerification,
  verifyBVN,
  generateWalletDetails
};
