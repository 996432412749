import React, { useEffect } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import Button from "../button";
import { useGetHMOGroups } from "../../redux/hmo/hook";
import AddEmployeeHMOGroup from "../modal/hmo/add-hmo-group";

const EmployeeHMOGroupsTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { data: hmoGroups } = useGetHMOGroups(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const [showAddHMOGroup, setShowAddHMOGroup] = useState(false);
  const [groupData, setGroupData] = useState();
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const history = useHistory();

  const tableConfig = {
    // title: 'Group Management',
    headers: ["Group name", "Members", "Descriptions"],
    keys: ["name", "members", "description"],
    mobileHeader: {
      left: {
        title: "Date Created",
        key: "created",
      },
      right: {
        title: "Group name",
        key: "name",
      },
    },
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => history.push("/employee-hmo/group/view/" + id),
    },
    {
      name: "Edit",
      color: "orange",
      action: (id) => {
        let index = hmoGroups.data.findIndex((item) => item.id === id);
        setButtonText("Update");
        setTitle("Update HMO Group");
        setGroupData(hmoGroups.data[index].id);
        setShowAddHMOGroup(true);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded-md">
      {showAddHMOGroup ? (
        <AddEmployeeHMOGroup
          isOpen={showAddHMOGroup}
          closeModal={() => setShowAddHMOGroup(false)}
          groupData={groupData}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          HMO Groups
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New HMO Group"
              type="submit"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              onClick={() => {
                setGroupData(null);
                setButtonText("Save");
                setTitle("Add HMO Group");
                setShowAddHMOGroup(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={hmoGroups}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        // bulkActionConfig={bulkActionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
        computedActions={true}
        noCheck={true}
      />
    </div>
  );
};

export default EmployeeHMOGroupsTab;
