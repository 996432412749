import React from "react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  useEditSalary,
  useGetSalaryByEmployee,
} from "../../../redux/employees/hook/salaries";
import Button from "../../button";
import FormInput from "../../form-input";
import LoadingBar from "../../loader-bar";
import SingleEmployeeSalaryReviews from "../../modal/employees/single-employee-salary-reviews";
import FormSelect from "../../form-select";
import StatutoryAllowances from "../../modal/employees/statutory-allowance";
import AddStatutoryAllowance from "../../modal/employees/add-statutory-allowance";

const EmployeeCompensation = () => {
  useEffect(() => {}, []);
  const { id } = useParams();

  const { data: singleEmployeeSalary, isLoading } = useGetSalaryByEmployee(id);

  const { mutateAsync: editSalary, isLoading: loading } = useEditSalary();

  const [showSalaryReviews, setShowSalaryReviews] = useState(false);
  const [showStatutoryAllowances, setShowStatutoryAllowances] = useState(false);
  const [showAddStatutoryAllowance, setShowAddStatutoryAllowance] =
    useState(false);

  const [basicSalary, setBasicSalary] = useState(0);
  const [housingAllowance, setHousingAllowance] = useState(0);
  const [transportationAllowance, setTransportationAllowance] = useState(0);
  const [leaveAllowance, setLeaveAllowance] = useState(0);
  const [lunchAllowance, setLunchAllowance] = useState(0);
  const [otherAllowance, setOtherAllowance] = useState(0);
  const [benefitInKind, setBenefitInKind] = useState(0);
  const [netPay, setNetPay] = useState(0);
  const [utilityAllowance, setUtilityAllowance] = useState(0);
  const [salaryId, setSalaryId] = useState();

  const [currency, setCurrency] = useState("naira");
  const currencyOptions = [
    {
      label: "Naira",
      value: "naira",
    },
  ];

  const [payrollCountry, setPayrollCountry] = useState("Nigeria");
  const payrollCountryOptions = [
    {
      label: "Nigeria",
      value: "Nigeria",
    },
  ];
  const [additionalAllowance, setAdditionalAllowance] = useState([]);
  useEffect(() => {
    if (singleEmployeeSalary) {
      setPayrollCountry(singleEmployeeSalary?.payroll_country);
      setCurrency(singleEmployeeSalary?.payroll_currency);
      setBasicSalary(parseFloat(singleEmployeeSalary?.basic_salary.toFixed(2)));
      setHousingAllowance(
        parseFloat(singleEmployeeSalary?.housing_allowance.toFixed(2))
      );
      setTransportationAllowance(
        parseFloat(singleEmployeeSalary?.transportation_allowance.toFixed(2))
      );
      setLeaveAllowance(
        parseFloat(singleEmployeeSalary?.leave_allowance.toFixed(2))
      );
      setLunchAllowance(
        parseFloat(singleEmployeeSalary?.lunch_allowance.toFixed(2))
      );
      setOtherAllowance(
        parseFloat(singleEmployeeSalary?.other_allowance.toFixed(2))
      );
      // setBenefitInKind(parseFloat(singleEmployeeSalary?.benefit_in_kind.toFixed(2)));
      setNetPay(parseFloat(singleEmployeeSalary?.net_pay.toFixed(2)));
      setUtilityAllowance(
        parseFloat(singleEmployeeSalary?.utility_allowance.toFixed(2))
      );

      if (
        singleEmployeeSalary.additional_allowances &&
        singleEmployeeSalary.additional_allowances !== "null"
      ) {
        let newArray = JSON.parse(singleEmployeeSalary?.additional_allowances);
        newArray.forEach((element) => {
          element.amount = parseFloat(element.amount);
        });
        setAdditionalAllowance(newArray);
      }
      setSalaryId(singleEmployeeSalary?.id);
    }
  }, [singleEmployeeSalary]);

  const history = useHistory();

  const updateCompensation = async () => {
    let payroll = {
      id: salaryId,
      body: {
        basic_salary: parseFloat(basicSalary),
        housing_allowance: parseFloat(housingAllowance),
        transportation_allowance: parseFloat(transportationAllowance),
        lunch_allowance: parseFloat(lunchAllowance),
        benefit_in_kind: parseFloat(benefitInKind),
        airtime_allowance: 0,
        data_allowance: 0,
        leave_allowance: parseFloat(leaveAllowance),
        utility_allowance: parseFloat(utilityAllowance),
        other_allowance: parseFloat(otherAllowance),
        additional_allowances: JSON.stringify(additionalAllowance),
        net_pay: parseFloat(netPay),
        payroll_country: payrollCountry,
        payroll_currency: currency,
      },
    };
    await editSalary(payroll);
  };

  return (
    <>
      <div className="pt-3 pb-8 box-shadow h-full w-full bg-white rounded">
        {showSalaryReviews ? (
          <SingleEmployeeSalaryReviews
            isOpen={showSalaryReviews}
            closeModal={() => setShowSalaryReviews(false)}
            id={id}
          />
        ) : null}
        {showStatutoryAllowances ? (
          <StatutoryAllowances
            isOpen={showStatutoryAllowances}
            closeModal={() => setShowStatutoryAllowances(false)}
          />
        ) : null}
        {showAddStatutoryAllowance ? (
          <AddStatutoryAllowance
            isOpen={showAddStatutoryAllowance}
            buttonText={"SAVE"}
            title={"Add Other Statutory Allowances"}
            closeModal={() => setShowAddStatutoryAllowance(false)}
            statEmployeeName={`${singleEmployeeSalary?.first_name} ${singleEmployeeSalary?.last_name} | ${singleEmployeeSalary?.phone}`}
          />
        ) : null}

        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-1">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[20%] w-full">
            Salaries
          </div>
          {singleEmployeeSalary ? (
            <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[80%] w-full">
              <div className="md:w-fit w-[100%]">
                <Button
                  text="Review Salary"
                  type="button"
                  leftIcon={"change"}
                  className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                  textClass={"!text-[11px]"}
                  onClick={() =>
                    history.push(
                      "/employees/review/review-salary-information?type=single&salary=true"
                    )
                  }
                  // onClick={() => history.push('/employees/review/select-option')}
                />
              </div>
              <div className="md:w-fit w-[100%]">
                <Button
                  text="View Salary Reviews"
                  type="button"
                  theme="primary"
                  leftIcon={"view"}
                  className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                  textClass={"!text-[11px]"}
                  onClick={() => setShowSalaryReviews(true)}
                />
              </div>
              <div className="md:w-fit w-[100%]">
                <Button
                  text="View Statutory Allowance"
                  type="button"
                  theme="primary"
                  leftIcon={"view"}
                  className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                  textClass={"!text-[11px]"}
                  onClick={() => setShowStatutoryAllowances(true)}
                />
              </div>
              <div className="md:w-fit w-[100%]">
                <Button
                  text="Statutory Allowance"
                  type="button"
                  theme="primary"
                  leftIcon={"add"}
                  className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                  textClass={"!text-[11px]"}
                  onClick={() => setShowAddStatutoryAllowance(true)}
                />
              </div>
            </div>
          ) : null}
        </div>
        <hr className="divider" />
        {isLoading ? (
          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
            <LoadingBar loading={isLoading} />
          </div>
        ) : (
          <div className="px-4 pb-4">
            {singleEmployeeSalary ? (
              <div
                className={
                  "flex flex-col w-full md:w-[100%] px-4 border p-4 rounded-md"
                }
              >
                <div className="flex flex-col md:flex-row justify-between">
                  <div className="w-full md:w-[180px]">
                    <FormInput
                      label="Basic Salary"
                      name="basic"
                      type="number"
                      className={"!bg-white"}
                      value={basicSalary}
                      onInput={(e) => {
                        let new_value = e.target.value;
                        setBasicSalary(parseFloat(new_value));
                      }}
                    />
                  </div>
                  <div className="w-full md:w-[180px]">
                    <FormInput
                      label="Housing allowance"
                      name="housing"
                      type="number"
                      className={"!bg-white"}
                      value={housingAllowance}
                      onInput={(e) => {
                        let new_value = e.target.value;
                        setHousingAllowance(parseFloat(new_value));
                      }}
                    />
                  </div>
                  <div className="w-full md:w-[180px]">
                    <FormInput
                      label="Transport Allowance"
                      name="transportation_allowance"
                      type="number"
                      className={"!bg-white"}
                      value={transportationAllowance}
                      onInput={(e) => {
                        let new_value = e.target.value;
                        setTransportationAllowance(parseFloat(new_value));
                      }}
                    />
                  </div>
                  <div className="w-full md:w-[180px]">
                    <FormInput
                      label="Leave Allowance"
                      name="leave_allowance"
                      type="text"
                      className={"!bg-white"}
                      value={leaveAllowance}
                      onInput={(e) => {
                        let new_value = e.target.value;
                        setLeaveAllowance(parseFloat(new_value));
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-col md:flex-row justify-between">
                  <div className="w-full md:w-[180px]">
                    <FormInput
                      label="Lunch Allowance"
                      name="lunch_allowance"
                      type="text"
                      className={"!bg-white"}
                      value={lunchAllowance}
                      onInput={(e) => {
                        let new_value = e.target.value;
                        setLunchAllowance(parseFloat(new_value));
                      }}
                    />
                  </div>
                  <div className="w-full md:w-[180px]">
                    <FormInput
                      label="Utility Allowance"
                      name="utility_allowance"
                      type="text"
                      className={"!bg-white"}
                      value={utilityAllowance}
                      onInput={(e) => {
                        let new_value = e.target.value;
                        setUtilityAllowance(parseFloat(new_value));
                      }}
                    />
                  </div>
                  {/* <div className='w-full md:w-[180px]'>
                    <FormInput
                      label='Benefit In Kind'
                      name='benefit_in_kind'
                      type='number'
                      className={'!bg-white'}
                      value={benefitInKind}
                      onInput={(e) => {
                        let new_value = e.target.value
                        setBenefitInKind(parseFloat(new_value))
                        }
                      }
                    />
                  </div> */}
                  <div className="w-full md:w-[180px]">
                    <FormInput
                      label="Other Allowance"
                      name="other_allowance"
                      type="number"
                      className={"!bg-white"}
                      value={otherAllowance}
                      onInput={(e) => {
                        let new_value = e.target.value;
                        setOtherAllowance(parseFloat(new_value));
                      }}
                    />
                  </div>
                </div>
                {additionalAllowance.length > 0 ? (
                  <>
                    <hr className="divider mt-2 mb-2" />
                    <div className="text-[15px] font-extrabold mb-2">
                      Additional Allowances
                    </div>
                    {additionalAllowance.map((item, index) => (
                      <div className="w-full md:w-[180px]">
                        <FormInput
                          label={item.title}
                          name={item.name}
                          type="number"
                          className={"!bg-white"}
                          value={item.amount}
                          onInput={(e) => {
                            let new_value = e.target.value;
                            let newArray = JSON.parse(
                              JSON.stringify(additionalAllowance)
                            );
                            newArray[index].amount = parseFloat(new_value);
                            setAdditionalAllowance(newArray);
                          }}
                        />
                      </div>
                    ))}
                    <hr className="divider mt-2" />
                  </>
                ) : null}
                <div className="w-full md:w-[250px]">
                  <FormInput
                    label="Monthly Net Pay"
                    name="net_pay"
                    type="number"
                    className={"!bg-white"}
                    value={netPay}
                    onInput={(e) => {
                      let new_value = e.target.value;
                      setNetPay(parseFloat(new_value));
                    }}
                  />
                </div>
                <hr className="divider" />
                <div className="flex flex-col md:flex-row justify-between">
                  <div className="w-full md:w-1/2 md:pr-2">
                    <FormSelect
                      value={payrollCountry}
                      readOnly={true}
                      options={payrollCountryOptions}
                      onChange={(selected) => {
                        setPayrollCountry(selected);
                      }}
                      extraClass={"!bg-white"}
                      label="Payroll Country"
                    />
                  </div>
                  <div className="w-full md:w-1/2 md:pl-2">
                    <FormSelect
                      value={currency}
                      readOnly={true}
                      extraClass={"!bg-white"}
                      options={currencyOptions}
                      onChange={(selected) => {
                        setCurrency(selected);
                      }}
                      label="Payroll Currency"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-end w-full mt-[20px]">
                  <Button
                    text="Update Compensation"
                    type="button"
                    loading={loading}
                    className="flex gap-2 !h-[35px] w-fit p-2 "
                    textClass={"!text-[11px]"}
                    onClick={() => updateCompensation()}
                  />
                </div>
              </div>
            ) : (
              <div
                className={
                  "flex flex-row justify-center items-center h-[100px] w-full md:w-full"
                }
              >
                <div className="w-fit">
                  <Button
                    text="Add Compensation"
                    type="button"
                    loading={false}
                    disabled={false}
                    className=" flex gap-2 h-[42px]"
                    leftIcon={"add"}
                    textClass={"!text-[15px]"}
                    onClick={() =>
                      history.push(
                        "/employees/onboarding/select-option?salary=true"
                      )
                    }
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default EmployeeCompensation;
