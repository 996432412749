import React from 'react';
import Info from './info';
import classNames from 'classnames';

const FormSelect = ({
  type,
  name,
  label,
  defaultValue,
  extraClass,
  selectRef,
  readOnly,
  options,
  error,
  onChange,
  errorMessage,
  infos,
  ...rest
}) => {
  const selectedThemeClass =
    type === 'default'
      ? '!bg-color-white w-full !pl-2 text-[14px] leading-[18.23px] !h-[35px]'
      : '!bg-color-white w-full !pl-2 !h-[35px]';

  return (
    <div>
      <div className='flex flex-col pb-1 w-full'>
        {label && (
          <label className='text-[13px] leading-[23px] font-normal text-color-gray -mb-2'>
            {label}
          </label>
        )}
        <select
          name={name}
          className={classNames(
            `border border-color-gray select-input rounded-md ${selectedThemeClass} ${extraClass} my-[10px] text-color-black text-[13px] cursor-pointer`,
            {
              'border border-color-gray !bg-secondary-2-extralight': readOnly,
            },
          )}
          defaultValue={defaultValue}
          ref={selectRef}
          readOnly={readOnly}
          onChange={(event) => {
            if (!readOnly) {
              onChange(event.target.value);
            }
            
          }}
          {...rest}
        >
          {options.map((option, i) => (
            <option key={i} value={option.value} className='cursor-pointer'>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {error && <span>* {errorMessage}</span>}
      {infos && <Info infos={infos} />}
    </div>
  );
};

export default FormSelect;
