import React, { useEffect } from "react";
import { useState } from "react";
import FormSelect from "../form-select";
import { useSelector } from "react-redux";
import FormSwitch from "../form-switch";
import FormInput from "../form-input";
import { useForm } from "react-hook-form";
import { formValidation } from "../../utils/functions";
import {
  useUpdateCompany,
  useUpdateCompanyCurrency,
} from "../../redux/company/hook";
import Button from "../button";
import DOMPurify from "dompurify";
import { Initials } from "../../utils/functions";
import UploadCompanyLogo from "../modal/settings/add-profile-pix";
import { countriesAndState, AllCountries } from "../../constants/countries";
import { currencies } from "../../utils/constants/currencies";
import { industryOptions } from "../../utils/constants/industry-options";

const ManageCompany = () => {
  useEffect(() => {}, []);

  const { company } = useSelector((state) => state.companySlice);
  const [editMode, setEditMode] = useState(false);
  const { mutateAsync: updateCompany, isLoading: loading } = useUpdateCompany();
  const { mutateAsync: updateCompanyCurrency, isLoading: currencyloading } =
    useUpdateCompanyCurrency();

  const [country, setCountry] = useState("Nigeria");
  const [state, setState] = useState("");
  const [stateOptions, setStateOptions] = useState([]);
  const [companyTaxId, setCompanyTaxId] = useState(null);

  

  const [industry, setIndustry] = useState();
  const [currency, setCurrency] = useState();

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (company) {
      setCurrency(company.currency ? company.currency : null);
      setCountry(company.country);
      setState(company.state);
      setIndustry(company.industry ? company.industry : "Not Set");
      setCompanyTaxId(company.tax_id);
    }
  }, [company]);

  const currencyOptions = [
    {
      label: "Naira",
      value: "naira",
    },
  ];

  const [url, setUrl] = useState("https://etax.lirs.net/register");

  const payrollOptions = [
    {
      label: "End of Month",
      value: "28th",
    },
  ];

  useEffect(() => {
    if (!state || state === "Lagos") {
      setUrl("https://etax.lirs.net/register");
    } else if (state === "Rivers") {
      setUrl("https://rivtamis.riversbirs.gov.ng");
    } else if (state === "FCT - Abuja") {
      setUrl(
        "https://fcttaxportal.fctirs.gov.ng/selfservice/Account/NewRegistration"
      );
    } else if (state === "Edo") {
      setUrl("https://eras.eirs.gov.ng/Login/Company");
    } else if (state === "Ogun") {
      setUrl("https://portal.ogetax.ogunstate.gov.ng/registration");
    } else if (state === "Oyo") {
      setUrl("https://selfservice.oyostatebir.com/");
    } else if (state === "Delta") {
      setUrl("http://www.deltabir.com/DeltaRevenue/Default.aspx");
    } else if (state === "Enugu") {
      setUrl("https://irs.en.gov.ng/obtain-esbn");
    } else if (state === "Anambra") {
      setUrl("https://selfportal.anambra.tax/Auth/login");
    } else if (state === "Kano") {
      setUrl("https://taxdrive.kigra.gov.ng/selfservice");
    }
  }, [state]);

  const [showProfileModal, setShowProfileModal] = useState(false);

  useEffect(() => {
    if (country) {
      let getStates = countriesAndState.find((a) => a.name === country);

      if (getStates) {
        let computedStates = [];

        getStates.states.map((item, index) => {
          computedStates.push({ label: item, value: item });
        });

        setStateOptions(computedStates);
      }
    }
  }, [country]);

  function submitForm(data) {
    const company_name = DOMPurify.sanitize(data?.company_name);
    const mobile = DOMPurify.sanitize(data?.mobile);
    const email = DOMPurify.sanitize(data?.email);
    const reg_no = DOMPurify.sanitize(data?.reg_no);
    const tax_id = DOMPurify.sanitize(data?.tax_id);
    const nhf_code = DOMPurify.sanitize(data?.nhf_code);
    const itf_code = DOMPurify.sanitize(data?.itf_code);
    const nhis_code = DOMPurify.sanitize(data?.nhis_code);
    const nsitf_code = DOMPurify.sanitize(data?.nsitf_code);
    const employerPensionCode = DOMPurify.sanitize(data?.employerPensionCode);
    const city = DOMPurify.sanitize(data?.city);
    const address = DOMPurify.sanitize(data?.address);

    let payload = {
      company_name,
      mobile,
      email,
      tax_id,
      nhf_code,
      itf_code,
      nhis_code,
      nsitf_code,
      reg_no,
      employerPensionCode,
      industry,
      country,
      city,
      state,
      address,
    };

    updateCompany(payload).then((result) => {
      setEditMode(false);
    });
  }

  return (
    <>
      {company ? (
        <div className="flex flex-col bg-white w-[100%] box-shadow py-4 pt-3 -mt-2 h-full min-h-fit rounded">
          {showProfileModal ? (
            <UploadCompanyLogo
              isOpen={showProfileModal}
              closeModal={() => setShowProfileModal(false)}
              title={"Upload Company Logo"}
              buttonText={"Save"}
            />
          ) : null}
          <div className="flex flex-row items-center gap-4 px-4 justify-between">
            <div className="flex flex-row gap-3 items-center justify-center">
              <div className="text-[20px] flex font-bold justify-center items-center h-[40px] w-[70px] rounded border-[1px] border-secondary-2 header-3 relative hover:scale-150 hover:m-2 duration-500 px-2">
                {company?.logo ? (
                  <div className="flex items-center justify-center">
                    <img
                      src={company?.logo}
                      alt="logo"
                      className="flex items-center justify-center object-fit min-w-[65px] min-h-[40px] max-w-[65px] max-h-[40px] rounded text-[14px]"
                    />
                  </div>
                ) : (
                  <>{Initials(company?.company_name)}</>
                )}
              </div>
              <div className="flex flex-col">
                <span className="p1-bold ">{company?.company_name}</span>
                <span className="text-[12px] text-color-gray w-full md:w-[100%]">
                  Manage and update your company information
                </span>
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
              <div className="md:w-fit w-[100%]" id="second-step">
                <Button
                  text="Upload Company Logo"
                  type="button"
                  theme="primary"
                  leftIcon={"picture"}
                  className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                  textClass={"!text-[11px]"}
                  onClick={() => {
                    setShowProfileModal(true);
                  }}
                />
              </div>
            </div>
          </div>

          <hr className="divider mb-3" />

          <div className="flex flex-col px-8">
            <div className="w-full md:w-[20%] !font-semibold">
              <FormSwitch
                label={"Edit Mode"}
                name={"switch-edit-mode"}
                checked={editMode}
                onClick={() => setEditMode(!editMode)}
                value={editMode}
              />
            </div>
            {editMode ? (
              <form onSubmit={handleSubmit(submitForm)}>
                <div
                  className={
                    "flex flex-col w-full md:w-[100%] border rounded p-3 -mt-2 bg-highlight"
                  }
                >
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-[250px]">
                      <FormInput
                        label="Company Name"
                        name="company_name"
                        type="text"
                        defaultValue={
                          company?.company_name
                            ? company?.company_name
                            : "Not Set"
                        }
                        inputRef={register(formValidation("text", true))}
                        readOnly={loading}
                        error={errors.company_name}
                        errorMessage={
                          errors.company_name && errors.company_name.message
                        }
                      />
                    </div>
                    <div className="w-full md:w-[250px]">
                      <FormInput
                        label="Phone Number"
                        name="mobile"
                        type="text"
                        defaultValue={
                          company?.mobile ? company?.mobile : "Not Set"
                        }
                        inputRef={register(formValidation("text"))}
                        readOnly={loading}
                        error={errors.mobile}
                        errorMessage={errors.mobile && errors.mobile.message}
                      />
                    </div>
                    <div className="w-full md:w-[250px]">
                      <FormInput
                        label="Email"
                        name="email"
                        type="text"
                        defaultValue={
                          company?.email ? company.email : "Not Set"
                        }
                        inputRef={register(formValidation("email", true))}
                        readOnly={loading}
                        error={errors.email}
                        errorMessage={errors.email && errors.email.message}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-[250px]">
                      <FormInput
                        label="RC Number"
                        name="reg_no"
                        type="text"
                        defaultValue={
                          company?.reg_no ? company.reg_no : "Not Set"
                        }
                        inputRef={register(formValidation("text"))}
                        readOnly={loading}
                        error={errors.reg_no}
                        errorMessage={errors.reg_no && errors.reg_no.message}
                      />
                    </div>
                    <div className="w-full md:w-[250px]">
                      <FormInput
                        label="Employer Pension Code"
                        name="employerPensionCode"
                        defaultValue={
                          company?.employerPensionCode
                            ? company.employerPensionCode
                            : "Not Set"
                        }
                        inputRef={register(formValidation("text"))}
                        readOnly={loading}
                        error={errors.employerPensionCode}
                        errorMessage={
                          errors.employerPensionCode &&
                          errors.employerPensionCode.message
                        }
                      />
                    </div>
                    <div className="w-full md:w-[250px]">
                      <FormInput
                        label=" ITF Code"
                        inputRef={register()}
                        defaultValue={
                          company?.itf_code ? company.itf_code : "Not Set"
                        }
                        name="itf_code"
                        type="text"
                        readOnly={loading}
                      />
                    </div>
                  </div>
                  <div className=" flex md:flex-row flex-col justify-between w-full ">
                    <div className="w-full md:w-[250px] md:pl-2">
                      <FormInput
                        label="NHIS Code"
                        name="nhis_code"
                        defaultValue={
                          company?.nhis_code ? company.nhis_code : "Not Set"
                        }
                        type="text"
                        inputRef={register()}
                        readOnly={loading}
                      />
                    </div>
                    <div className="w-full md:w-[250px] md:pr-2">
                      <FormInput
                        label="NHF Code"
                        inputRef={register()}
                        name="nhf_code"
                        defaultValue={
                          company?.nhf_code ? company.nhf_code : "Not Set"
                        }
                        type="text"
                        readOnly={loading}
                      />
                    </div>
                    <div className="flex flex-row justify-between items-center w-full md:w-[350px]">
                      <div className="w-[65%]">
                        <FormInput
                          label="Payroll Tax ID"
                          name="tax_id"
                          className={"rounded-br-none rounded-tr-none"}
                          type="text"
                          value={companyTaxId}
                          onInput={(e) => {
                            setCompanyTaxId(e.target.value);
                          }}
                          inputRef={register(formValidation("text"))}
                          error={errors.tax_id}
                          errorMessage={errors.tax_id && errors.tax_id.message}
                        />
                      </div>
                      <div className="w-[40%] pt-[9px]">
                        <Button
                          text={"Generate Tax ID"}
                          type="submit"
                          disabled={false}
                          theme="primary"
                          className={`h-[35px] px-2 rounded-bl-none rounded-tl-none`}
                          onClick={() => {
                            window.open(url, "_blank");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:gap-20">
                    <div className="w-full md:w-[250px]">
                      <FormInput
                        label="NSITF Code"
                        name="nsitf_code"
                        defaultValue={
                          company?.nsitf_code ? company.nsitf_code : "Not Set"
                        }
                        type="text"
                        inputRef={register()}
                        readOnly={loading}
                      />
                    </div>
                    <div className="w-full md:w-[250px]">
                      <FormSelect
                        value={industry}
                        options={industryOptions}
                        onChange={(selected) => {
                          setIndustry(selected);
                        }}
                        label="Industry"
                      />
                    </div>
                  </div>
                  <hr className="divider" />
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-[250px]">
                      <FormSelect
                        defaultValue={country}
                        options={AllCountries}
                        onChange={(selected) => {
                          setCountry(selected);
                        }}
                        label="Country"
                      />
                    </div>

                    <div className="w-full md:w-[250px]">
                      {country == "Nigeria" ? (
                        <FormSelect
                          defaultValue={state}
                          options={stateOptions}
                          onChange={(selected) => {
                            setState(selected);
                          }}
                          label="State"
                        />
                      ) : (
                        <FormInput
                          label="State"
                          name="state"
                          type="text"
                          value={state}
                          onInput={(e) => setState(e.target.value)}
                          error={errors.state}
                          errorMessage={errors.state && errors.state.message}
                        />
                      )}
                    </div>
                    <div className="w-full md:w-[250px]">
                      <FormInput
                        label="City"
                        name="city"
                        type="text"
                        defaultValue={company?.city ? company.city : "Not Set"}
                        inputRef={register(formValidation("text"))}
                        readOnly={loading}
                        error={errors.city}
                        errorMessage={errors.city && errors.city.message}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full">
                      <FormInput
                        label="Address"
                        name="address"
                        type="text"
                        defaultValue={
                          company?.address ? company.address : "Not Set"
                        }
                        inputRef={register(formValidation("text"))}
                        readOnly={loading}
                        error={errors.address}
                        errorMessage={errors.address && errors.address.message}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end mt-4">
                    <div className="flex items-center justify-end w-full mt-[20px]">
                      <Button
                        text="Update Company Information"
                        type="submit"
                        loading={loading}
                        className="flex gap-2 !h-[35px] w-fit p-2 "
                        textClass={"!text-[12px]"}
                      />
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <div className="flex flex-col md:flex-row border p-4 rounded-md md:gap-3 pt-2 -mt-2">
                <div className="flex w-3/5 flex-col">
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-3 w-full mt-3">
                    <div className="flex flex-col">
                      <span className="text-[12px] text-color-gray font-semibold">
                        Phone Number
                      </span>
                      <span className="mt-1 text-color-black text-[13px]">
                        {company?.mobile ? company?.mobile : "Not Set"}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-[12px] text-color-gray font-semibold">
                        Email Address
                      </span>
                      <span className="mt-1 text-color-black text-[13px]">
                        {company?.email ? company?.email : "Not Set"}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col mt-8">
                    <span className="header-5">Other Information</span>
                    <hr className="divider" />

                    <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full">
                      <div className="flex flex-col">
                        <span className="text-[12px] text-color-gray font-semibold">
                          RC number
                        </span>
                        <span className="mt-1 text-color-black text-[13px]">
                          {company?.reg_no ? company.reg_no : "Not Set"}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px] text-color-gray font-semibold">
                          Tax ID number (PAYE)
                        </span>
                        <span className="mt-1 text-color-black text-[13px]">
                          {company?.tax_id ? company?.tax_id : "Not Set"}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px] text-color-gray font-semibold">
                          NHF Code
                        </span>
                        <span className="mt-1 text-color-black text-[13px]">
                          {company?.nhf_code ? company?.nhf_code : "Not Set"}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px] text-color-gray font-semibold">
                          NHIS Code
                        </span>
                        <span className="mt-1 text-color-black text-[13px]">
                          {company?.nhis_code ? company?.nhis_code : "Not Set"}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px] text-color-gray font-semibold">
                          ITF Code
                        </span>
                        <span className="mt-1 text-color-black text-[13px]">
                          {company?.itf_code ? company?.itf_code : "Not Set"}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px] text-color-gray font-semibold">
                          NSITF Code
                        </span>
                        <span className="mt-1 text-color-black text-[13px]">
                          {company?.nsitf_code
                            ? company?.nsitf_code
                            : "Not Set"}
                        </span>
                      </div>

                      <div className="flex flex-col">
                        <span className="text-[12px] text-color-gray font-semibold">
                          Employer Pension Code{" "}
                        </span>
                        <span className="mt-1 text-color-black text-[13px]">
                          {company?.employerPensionCode
                            ? company?.employerPensionCode
                            : "Not Set"}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px] text-color-gray font-semibold">
                          Industry
                        </span>
                        <span className="mt-1 text-color-black text-[13px]">
                          {industry}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px] text-color-gray font-semibold">
                          Country
                        </span>
                        <span className="mt-1 text-color-black text-[13px]">
                          {company?.country ? company?.country : "Not Set"}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px] text-color-gray font-semibold">
                          State
                        </span>
                        <span className="mt-1 text-color-black text-[13px]">
                          {company?.state ? company?.state : "Not Set"}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px] text-color-gray font-semibold">
                          City
                        </span>
                        <span className="mt-1 text-color-black text-[13px]">
                          {company?.city ? company?.city : "Not Set"}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[12px] text-color-gray font-semibold">
                          Address
                        </span>
                        <span className="mt-1 text-color-black text-[13px]">
                          {company?.address ? company?.address : "Not Set"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-2/5 p-3 bg-highlight rounded max-h-[160px] mt-3">
                  <div className="flex flex-col gap-2">
                    <FormSelect
                      options={currencies}
                      value={currency}
                      extraClass={"bg-white"}
                      onChange={(selected) => {
                        setCurrency(selected);
                      }}
                      label="Currency"
                    />
                    <div className="flex items-center justify-end w-full ">
                      <Button
                        text="Update company currency"
                        type="submit"
                        loading={loading}
                        className="flex gap-2 !h-[35px] w-fit p-2 "
                        textClass={"!text-[12px]"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ManageCompany;
