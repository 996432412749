import React, { useEffect } from "react";
import Button from "../../components/button";
import DataTable from "../../components/datatable";
import DashboardLayout from "../../components/layouts/dashboard";
import { useState } from "react";
import { PAGINATION_DEFAULT, TicketStatusConfig } from "../../utils/constants";
import { useGetsupportTickets } from "../../redux/wallet/hook";
import AddSupportTicket from "../../components/modal/contact-us/add-ticket";
import { useGetSupportTickets } from "../../redux/contact-us/hook";
import { useHistory } from "react-router-dom";
import FeedbackModal from "../../components/modal/contact-us/add-feedback";

const ContactUsPage = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
    start_date: "",
    end_date: "",
  });

  const [showAddTicket, setShowAddTicket] = useState(false);
  const [showAddFeedback, setShowAddFeedback] = useState(false);

  const [tableType, setTableType] = useState("ticket");

  const switchFunction = () => {
    if (tableType === "ticket") {
      setTableType("table");
    } else {
      setTableType("ticket");
    }
  };
  const history = useHistory();

  const { data: supportTickets } = useGetSupportTickets(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
    pagination?.start_date,
    pagination?.end_date,
    pagination?.type
  );

  const tableConfig = {
    // title: 'Transactions History',
    headers: ["Date", "Subject", "Message", "Status"],
    keys: ["created", "subject", "message", "status"],
    mobileHeader: {
      left: {
        title: "Date Created",
        key: "created",
      },
      right: {
        title: "Subject",
        key: "subject",
      },
    },
  };

  const dateConfig = {
    startDate: "0000-00-00",
    endDate: "0000-00-00",
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = supportTickets.data.findIndex((item) => item.id === id);
        history.push(
          "/contact-us/view-ticket/" + supportTickets.data[index].id
        );
      },
    },
  ];

  return (
    <DashboardLayout title="Contact Us">
      {showAddTicket ? (
        <AddSupportTicket
          isOpen={showAddTicket}
          closeModal={() => setShowAddTicket(false)}
        />
      ) : null}
      {showAddFeedback ? (
        <FeedbackModal
          isOpen={showAddFeedback}
          closeModal={() => setShowAddFeedback(false)}
        />
      ) : null}
      <div className="flex flex-col" id="contact-us">
        <div className="flex flex-col bg-white rounded">
          <div className="flex flex-col md:flex-row md:justify-between items-center justify-center px-4 gap-[10px] my-2 mt-3">
            <div className="p3-bold header-4">Tickets</div>
            <div className="w-full flex md:justify-end justify-center gap-3">
              <Button
                text={`NEW SUPPORT TICKET`}
                type="button"
                className="flex gap-2 !h-[35px] w-fit p-2"
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                onClick={() => setShowAddTicket(true)}
              />
              <Button
                text={`SEND FEEDBACK`}
                type="button"
                theme="secondary"
                className="flex gap-2 !h-[35px] w-fit p-2"
                textClass={"!text-[11px]"}
                onClick={() => setShowAddFeedback(true)}
              />
            </div>
          </div>
          <hr className="divider mt-2 mb-2" />
          <DataTable
            data={supportTickets}
            tableConfig={tableConfig}
            noCheck={true}
            computedActions={true}
            tableType={tableType}
            switchFunction={switchFunction}
            layoutSwitch={false}
            filterByStatus={true}
            filterByDate={dateConfig}
            actionConfig={actionConfig}
            statusConfig={TicketStatusConfig}
            updatePagination={(data) => setPagination(data)}
            searchData={true}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ContactUsPage;
