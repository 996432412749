import classNames from "classnames";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button";
import FormSelectEmployee from "../../../components//employees/form-select-employee";
import FormInput from "../../../components/form-input";
import { useRouteQuery } from "../../../hooks/useRouteQuery";
import { setAlert } from "../../../redux/components/components-slice";
import {
  useGrossPayReverse,
  useNetPayReverse,
} from "../../../redux/employees/hook";
import { useGetSalaryByEmployee } from "../../../redux/employees/hook/salaries";
import SalaryReviewLayout from "../../../components/layouts/salary-review";
import ForwardArrowVG from "../../../assets/svg/forward-arrow.svg";
import { useAddSalaryReview } from "../../../redux/employees/hook/salary-review";
import { useAddPromotionSalaryReview } from "../../../redux/employees/hook/promotion";
import { formatNumber } from "../../../utils/functions";
import { Editor } from "@tinymce/tinymce-react";
import FormSwitch from "../../../components/form-switch";

function ReviewSingleEmployeePay() {
  useEffect(() => {}, []);
  const history = useHistory();
  const query = useRouteQuery();
  const dispatch = useDispatch();

  const [setting, setSetting] = useState("group");
  const [payType, setPayType] = useState("");
  const [payValue, setPayValue] = useState(0);
  const [showBreakdown, setShowBreakdown] = useState(false);
  const [additionalCost, setAdditionalCost] = useState(0);
  const [breakDown, setBreakDown] = useState();
  const [totalPayrollCost, setTotalPayrollCost] = useState();
  const [employee_id, setEmployee] = useState(null);
  const [employeeName, setEmployeeName] = useState(null);
  const { mutateAsync: netReverse, isLoading: netLoading } = useNetPayReverse();
  const { mutateAsync: grossReverse, isLoading: grossLoading } =
    useGrossPayReverse();

  const { employee } = useSelector((state) => state.employeeSlice);

  const [additionalAllowance, setAdditionalAllowance] = useState([]);

  const [reviewStart, setReviewStart] = useState();
  const [grossPay, setGrossPay] = useState();
  const [netPay, setNetPay] = useState();

  const { data: singleEmployeeSalary, isLoading } =
    useGetSalaryByEmployee(employee_id);

  const { mutateAsync: reviewSalary, isLoading: loading } =
    useAddSalaryReview();
  const { mutateAsync: reviewPromotionSalary, isLoading: promotionloading } =
    useAddPromotionSalaryReview();

  const [promotionId, setPromotionId] = useState(null);

  const [sendEmail, setSendEmail] = useState(false);
  const [body, setBody] = useState("");
  const [emailSubject, setEmailSubject] = useState("");

  useEffect(() => {
    if (query) {
      setPayType(query.get("type"));
      setPromotionId(query.get("promotionId"));
      setEmployee(query.get("employeeId"));
      setEmployeeName(query.get("employeeName"));
      setReviewStart(query.get("effectiveDate"));
    }
  }, [query]);

  useEffect(() => {
    if (employee) {
      setEmployee(employee.id);
    }
  }, [employee]);

  useEffect(() => {
    if (breakDown) {
      setTotalPayrollCost(
        parseFloat(breakDown.data.gross_pay) +
          parseFloat(breakDown.data.employer_nhis) +
          parseFloat(breakDown.data.employer_pension) +
          parseFloat(breakDown.data.nsitf) +
          parseFloat(breakDown.data.itf)
      );
    }
  }, [breakDown]);

  useEffect(() => {
    if (singleEmployeeSalary) {
      setAdditionalAllowance(
        singleEmployeeSalary?.additional_allowances
          ? JSON.parse(singleEmployeeSalary?.additional_allowances)
          : []
      );
      let allw = 0;
      if (
        singleEmployeeSalary.additional_allowances &&
        JSON.parse(singleEmployeeSalary.additional_allowances) &&
        JSON.parse(singleEmployeeSalary.additional_allowances).length > 0
      ) {
        JSON.parse(singleEmployeeSalary.additional_allowances).forEach(
          (items) => {
            allw += items.amount;
          }
        );
      }
      let gp =
        singleEmployeeSalary.airtime_allowance +
        singleEmployeeSalary.basic_salary +
        singleEmployeeSalary.benefit_in_kind +
        singleEmployeeSalary.housing_allowance +
        singleEmployeeSalary.leave_allowance +
        singleEmployeeSalary.lunch_allowance +
        singleEmployeeSalary.other_allowance +
        singleEmployeeSalary.transportation_allowance +
        singleEmployeeSalary.utility_allowance +
        allw;
      setGrossPay(gp);
      setNetPay(singleEmployeeSalary.net_pay);
    }
  }, [singleEmployeeSalary]);

  const calcNetReverse = async () => {
    if (!employee_id) {
      dispatch(setAlert(true, "error", "Please select an employee"));
      return;
    }

    if (!payValue && payValue < 1) {
      if (payType === "net-pay") {
        dispatch(
          setAlert(
            true,
            "error",
            "Enter a valid net pay amount, greater than 0"
          )
        );
      } else if (payType === "gross-pay") {
        dispatch(
          setAlert(
            true,
            "error",
            "Enter a valid gross pay amount, greater than 0"
          )
        );
      }
      return;
    }

    let payload = {
      setting,
      employee_id,
    };

    if (payType === "net-pay") {
      payload.net_pay = payValue;
      await netReverse(payload).then((result) => {
        setBreakDown(result);
        setShowBreakdown(true);
      });
    } else if (payType === "gross-pay") {
      payload.gross_pay = payValue;
      await grossReverse(payload).then((result) => {
        setBreakDown(result);
        setShowBreakdown(true);
      });
    }
  };

  const submitSalary = async () => {
    if (promotionId) {
      let payload = {
        promotion_id: promotionId,
        new_basic: breakDown?.data.basic_salary,
        new_housing: breakDown?.data.housing_allowance,
        new_transport: breakDown?.data.transportation_allowance,
        new_leave: breakDown?.data.leave_allowance,
        new_lunch: breakDown?.data.lunch_allowance,
        new_utility: breakDown?.data.utility_allowance,
        new_benefit_in_kind: breakDown?.data.benefit_in_kind,
        new_other_allowance: breakDown?.data.other_allowance,
        new_additional_allowances: JSON.stringify(
          breakDown?.data.additional_allowances
        ),
        new_net_pay: breakDown?.data.net_pay,
        review_start_date: reviewStart,
        employee: employee_id,
        send_email: sendEmail ? 1 : 0,
        email_subject: emailSubject,
        email_body: body.toString(),
      };
      await reviewPromotionSalary(payload).then(() => {
        history.push(`/promotion/view/${promotionId}`);
      });
    } else {
      let payload = {
        new_basic: breakDown?.data.basic_salary,
        new_housing: breakDown?.data.housing_allowance,
        new_transport: breakDown?.data.transportation_allowance,
        new_leave: breakDown?.data.leave_allowance,
        new_lunch: breakDown?.data.lunch_allowance,
        new_utility: breakDown?.data.utility_allowance,
        new_benefit_in_kind: breakDown?.data.benefit_in_kind,
        new_other_allowance: breakDown?.data.other_allowance,
        new_additional_allowances: JSON.stringify(
          breakDown?.data.additional_allowances
        ),
        new_net_pay: breakDown?.data.net_pay,
        review_start_date: reviewStart,
        employee: employee_id,
        send_email: sendEmail ? 1 : 0,
        email_subject: emailSubject,
        email_body: body.toString(),
      };
      await reviewSalary(payload).then(() => {
        history.push("/employees");
      });
    }
  };

  return (
    <SalaryReviewLayout
      step={breakDown ? 3 : 2}
      title={"Review Employee Salary"}
    >
      <div className="flex flex-col w-full  h-full">
        <span className="header-4">
          Review Employee monthly
          {payType === "net-pay"
            ? " take home (Net Pay)"
            : payType === "gross-pay"
            ? " (Gross Pay)"
            : null}
        </span>
        <hr className="divider mb-2" />
        <div className="border bg-highlight p-2 rounded-md -pb-2">
          <FormSelectEmployee
            label={"Type to select employee"}
            readOnly={promotionId || showBreakdown ? true : false}
            name="employee"
            empName={employeeName}
            placeholder="Type to select Employee"
            setEmployee={setEmployee}
          />
        </div>
        <hr className="divider mt-2 mb-2" />
        {employee_id ? (
          <>
            <div
              className={classNames("flex flex-col", {
                hidden: showBreakdown,
              })}
            >
              <div className="flex justify-between items-center gap-2 w-full">
                <FormInput
                  label={`Old Monthly ${
                    payType === "net-pay"
                      ? "Net Pay"
                      : payType === "gross-pay"
                      ? "Gross Pay"
                      : "Full Pay"
                  }`}
                  reaOnly={true}
                  placeholder={"Auto Fetched..."}
                  value={
                    payType === "net-pay"
                      ? formatNumber(netPay, 2)
                      : formatNumber(grossPay, 2)
                  }
                />
                <img
                  src={ForwardArrowVG}
                  width={15}
                  height={15}
                  alt="to"
                  className="mt-2"
                />
                <FormInput
                  label={`New Monthly ${
                    payType === "net-pay"
                      ? "Net Pay"
                      : payType === "gross-pay"
                      ? "Gross Pay"
                      : "Full Pay"
                  }`}
                  placeholder={"Enter Amount"}
                  name={payType}
                  value={payValue}
                  onInput={(e) => {
                    setPayValue(e.target.value);
                  }}
                  type="text"
                />
              </div>
              <FormInput
                label="Review Effective From?"
                value={reviewStart}
                type="date"
                name="review_start_date"
                onInput={(e) => setReviewStart(e.target.value)}
              />
              <FormInput
                label={`Additional non-payroll costs - (HMO, ...)`}
                name={"additional_cost"}
                value={additionalCost}
                onInput={(e) => {
                  if (e.target.value) {
                    setAdditionalCost(e.target.value);
                  } else {
                    setAdditionalCost(0);
                  }
                }}
                type={"number"}
              />
              <div
                className={
                  "flex w-full flex-col gap-2 px-3 border bg-highlight rounded-md pt-4"
                }
              >
                <div className="">
                  <FormSwitch
                    label={
                      "Do you want to send salary review email to employee?"
                    }
                    name={"send_email"}
                    checked={sendEmail}
                    onClick={() => setSendEmail(!sendEmail)}
                    value={sendEmail}
                  />
                </div>

                {sendEmail ? (
                  <div className="flex flex-col">
                    <hr className="divider -mt-2 mb-1" />
                    <FormInput
                      label="Subject"
                      name="subject"
                      type="text"
                      value={emailSubject}
                      placeholder={"Email Subject"}
                      onInput={(e) => {
                        setEmailSubject(e.target.value);
                      }}
                    />
                    <div className="flex md:flex-row flex-col w-full justify-between mb-2">
                      <div className="w-full">
                        <span className="text-[13px] text-gray-500">
                          Promotion Email Body
                        </span>
                        <Editor
                          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                          onEditorChange={(editor) => {
                            setBody(editor);
                          }}
                          value={body}
                          init={{
                            height: 300,
                            menubar: false,
                            placeholder: "Enter Email body here",
                            selector: "textarea",
                            plugins: [
                              "advlist autolink lists link image charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help wordcount",
                            ],
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor fontsize fontfamily | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:10pt }",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="w-full mt-[20px]">
                <Button
                  text="View Breakdown"
                  type="submit"
                  disabled={!reviewStart || !payValue || !employee_id}
                  loading={netLoading || grossLoading}
                  onClick={() => calcNetReverse()}
                />
              </div>

              <button
                className="flex justify-center w-full"
                onClick={() => history.goBack()}
              >
                <span className="p2-medium text-color-black mt-3">Back</span>
              </button>
            </div>

            {/* View Breakdown */}
            <div
              className={classNames("flex flex-col", {
                hidden: !showBreakdown,
              })}
            >
              {showBreakdown ? (
                <>
                  <div className="flex flex-col justify-between w-full">
                    <div className="flex flex-row w-full justify-between">
                      <FormInput
                        label="Old Basic Salary"
                        name="basic"
                        type="text"
                        readOnly
                        value={formatNumber(
                          singleEmployeeSalary?.basic_salary,
                          2
                        )}
                      />
                      <img src={ForwardArrowVG} width={15} alt="to" />
                      <FormInput
                        label="New Basic Salary"
                        name="basic"
                        type="text"
                        readOnly
                        value={formatNumber(breakDown?.data.basic_salary, 2)}
                      />
                    </div>
                    <div className="flex flex-row w-full justify-between">
                      <FormInput
                        label="Old Housing Allowance"
                        name="housing"
                        type="text"
                        readOnly
                        value={formatNumber(
                          singleEmployeeSalary?.housing_allowance,
                          2
                        )}
                      />
                      <img
                        src={ForwardArrowVG}
                        width={15}
                        className="ml-2 mt-2"
                        alt="to"
                      />
                      <FormInput
                        label="New Housing Allowance"
                        name="housing"
                        type="text"
                        readOnly
                        value={formatNumber(
                          breakDown?.data.housing_allowance,
                          2
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col justify-between">
                    <div className="flex flex-row w-full justify-between">
                      <FormInput
                        label="Old Transport Allowance"
                        type="text"
                        readOnly
                        value={formatNumber(
                          singleEmployeeSalary?.transportation_allowance,
                          2
                        )}
                      />
                      <img
                        src={ForwardArrowVG}
                        width={15}
                        className="ml-2 mt-2"
                        alt="to"
                      />
                      <FormInput
                        label="New Transport Allowance"
                        type="text"
                        readOnly
                        value={formatNumber(
                          breakDown?.data.transportation_allowance,
                          2
                        )}
                      />
                    </div>
                    <div className="flex flex-row w-full justify-between">
                      <FormInput
                        label="Old Leave allowance"
                        name="leave"
                        type="text"
                        readOnly
                        value={formatNumber(
                          singleEmployeeSalary?.leave_allowance,
                          2
                        )}
                      />
                      <img
                        src={ForwardArrowVG}
                        width={15}
                        className="ml-2 mt-2"
                        alt="to"
                      />
                      <FormInput
                        label="New Leave allowance"
                        name="leave"
                        type="text"
                        readOnly
                        value={formatNumber(breakDown.data.leave_allowance, 2)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col justify-between">
                    <div className="flex flex-row w-full justify-between">
                      <FormInput
                        label="Old Lunch allowance"
                        name="lunch"
                        type="text"
                        readOnly
                        value={formatNumber(
                          singleEmployeeSalary?.lunch_allowance,
                          2
                        )}
                      />
                      <img
                        src={ForwardArrowVG}
                        width={15}
                        className="ml-2 mt-2"
                        alt="to"
                      />
                      <FormInput
                        label="Old Lunch allowance"
                        name="lunch"
                        type="text"
                        readOnly
                        value={formatNumber(breakDown?.data.lunch_allowance, 2)}
                      />
                    </div>
                    <div className="flex flex-row w-full justify-between">
                      <FormInput
                        label="Old Benefit in kind"
                        name="housing"
                        type="text"
                        readOnly
                        value={formatNumber(
                          singleEmployeeSalary?.benefit_in_kind,
                          2
                        )}
                      />
                      <img
                        src={ForwardArrowVG}
                        width={15}
                        className="ml-2 mt-2"
                        alt="to"
                      />
                      <FormInput
                        label="New Benefit in kind"
                        name="housing"
                        type="text"
                        readOnly
                        value={formatNumber(breakDown?.data.benefit_in_kind, 2)}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col justify-between">
                    <div className="flex flex-row w-full justify-between">
                      <FormInput
                        label="Old Utility allowance"
                        name="utility"
                        type="text"
                        readOnly
                        value={formatNumber(
                          singleEmployeeSalary?.utility_allowance,
                          2
                        )}
                      />
                      <img
                        src={ForwardArrowVG}
                        width={15}
                        className="ml-2 mt-2"
                        alt="to"
                      />
                      <FormInput
                        label="New Utility allowance"
                        name="utility"
                        type="text"
                        readOnly
                        value={formatNumber(
                          breakDown?.data.utility_allowance,
                          2
                        )}
                      />
                    </div>
                    <div className="flex flex-row w-full justify-between">
                      <FormInput
                        label="Old Other allowance"
                        name="other"
                        type="text"
                        readOnly
                        value={formatNumber(
                          singleEmployeeSalary?.other_allowance,
                          2
                        )}
                      />
                      <img
                        src={ForwardArrowVG}
                        width={15}
                        className="ml-2 mt-2"
                        alt="to"
                      />
                      <FormInput
                        label="New Other allowance"
                        name="other"
                        type="text"
                        readOnly
                        value={formatNumber(breakDown?.data.other_allowance, 2)}
                      />
                    </div>
                    {breakDown?.data?.additional_allowances?.length > 0 ? (
                      <>
                        <hr className="divider mt-2 mb-2" />
                        <div className="text-[15px] font-extrabold mb-2">
                          Additional Allowances
                        </div>
                        {breakDown?.data.additional_allowances.map(
                          (item, index) => (
                            <div className="flex flex-row w-full justify-between">
                              {additionalAllowance?.map((subItem, subIndex) => (
                                <>
                                  {subItem.name == item.name ? (
                                    <FormInput
                                      label={"Old " + subItem.title}
                                      name={"old_" + subItem.name}
                                      type="text"
                                      readOnly
                                      value={formatNumber(subItem.amount, 2)}
                                    />
                                  ) : null}
                                </>
                              ))}
                              <img
                                src={ForwardArrowVG}
                                width={15}
                                className="ml-2 mt-2"
                                alt="to"
                              />
                              <FormInput
                                label={"New " + item.title}
                                name={"new_" + item.name}
                                type="text"
                                readOnly
                                value={formatNumber(item.amount, 2)}
                              />
                            </div>
                          )
                        )}
                        <hr className="divider mt-2" />
                      </>
                    ) : null}
                  </div>

                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-1/2 md:pr-2">
                      <FormInput
                        label="New Gross Pay"
                        name="gross_pay"
                        type="text"
                        readOnly
                        value={formatNumber(breakDown?.data.gross_pay, 2)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-2">
                      <FormInput
                        label="New Net Pay"
                        name="net_pay"
                        type="text"
                        readOnly
                        value={formatNumber(breakDown?.data.net_pay, 2)}
                      />
                    </div>
                  </div>
                  <hr className="divider" />
                  {/*   */}
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-1/2 md:pr-2">
                      <FormInput
                        label="Additional Employment Cost"
                        name="additional_cost"
                        type="text"
                        readOnly
                        value={formatNumber(parseFloat(additionalCost), 2)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-2">
                      <FormInput
                        label="Total Employee Cost"
                        name="total_employee_cost"
                        type="text"
                        readOnly
                        className={`border-lime-600 bg-lime-200`}
                        value={formatNumber(
                          totalPayrollCost + parseFloat(additionalCost),
                          2
                        )}
                      />
                    </div>
                  </div>

                  <div className="w-full mt-[20px]">
                    <Button
                      text="Confirm Review"
                      type="submit"
                      loading={loading || promotionloading}
                      onClick={() => submitSalary()}
                    />
                  </div>
                  <div
                    className="flex justify-center w-full cursor-pointer"
                    onClick={() => setShowBreakdown(false)}
                  >
                    <span className="p2-medium text-color-black mt-3">
                      Go Back
                    </span>
                  </div>
                </>
              ) : null}
            </div>
          </>
        ) : (
          <div className="h-[60px] flex items-center justify-center w-full">
            <hr className="divider mt-4" />
            <span>No employee selected</span>
          </div>
        )}
      </div>
    </SalaryReviewLayout>
  );
}

export default ReviewSingleEmployeePay;
