import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import CloseSVG from '../../../../assets/svg/close.svg';
import FacebookSVG from '../../../../assets/svg/facebook.svg';
import TwitterSVG from '../../../../assets/svg/twitter.svg';
import LinkedinSVG from '../../../../assets/svg/linkedin.svg';
import classNames from 'classnames';
import CollapsingIcon from '../../../collapsing-icon';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../redux/components/components-slice';
import CopySVG from '../../../../assets/svg/copy.svg';
import FormSwitch from '../../../form-switch';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useGetCompanySelectAdmins } from '../../../../redux/company/hook';
import FormInput from '../../../form-input';
import { formValidation, formatNumber } from '../../../../utils/functions';
import FormSelect from '../../../form-select';
import Button from '../../../button';
import DOMPurify from 'dompurify';
import {
  useEditRecruitment,
  useEditRecruitmentWorkflow,
} from '../../../../redux/human-resources/hook/recruitment';
import Modal from '../../modal';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';

function RecruitmentDetailsModal({ isOpen, closeModal, recruitmentData }) {
  const [url, setUrl] = useState('bit/ly.RPYVACS161%');

  const [showRecruitmentDetails, setShowRecruitmentDetails] = useState(false);
  const [showRecruitmentWorkflow, setShowRecruitmentWorkflow] = useState(false);
  const [showRecruitmentKeywords, setShowRecruitmentKeywords] = useState(false);
  const {
    mutateAsync: updateRecruitment,
    isLoading: udpateRecruitmentLoading,
  } = useEditRecruitment();

  const {
    mutateAsync: updateRecruitmentWorkflow,
    isLoading: udpateWorkflowLoading,
  } = useEditRecruitmentWorkflow();

  const [editRecruitmentMode, setEditRecruitmentMode] = useState(false);
  const [editWorkflowMode, setEditWorkflowMode] = useState(false);

  const { data: companyAdmins } = useGetCompanySelectAdmins();

  const [emailReceiverId, setEmailReceiverId] = useState();
  const [recruitmentId, setRecruitmentId] = useState();

  const [workflowId, setWorkflowId] = useState();
  const [workflowName, setWorkflowName] = useState();
  const [workflowDescription, setWorkflowDescription] = useState();

  useEffect(() => {
    if (recruitmentData) {
      setUrl(recruitmentData.resume_url);
      setWorkflowId(recruitmentData.workflow_id);
      setRecruitmentId(recruitmentData.id);
      setRole(recruitmentData.role);
      setJobDescription(recruitmentData.job_description);
      setApplicationEnd(recruitmentData.application_end);
      setJobType(recruitmentData.job_type);
      setLocationType(recruitmentData.location_type);
      setMinSalary(recruitmentData.min_salary);
      setMaxSalary(recruitmentData.max_salary);
      setExperienceLevel(recruitmentData.experience_level);
      setYearsOfExperience(recruitmentData.years_of_experience);
      setEmailReceiverId(recruitmentData.email_reciever_id);
      setEmailNotification(recruitmentData.email_notification);
      setWorkflowName(recruitmentData.workflow.name);
      setWorkflowDescription(recruitmentData.workflow.description);
      setUseKeywordFilter(recruitmentData.use_keywords == 1? true : false)
      if (recruitmentData.keywords.length > 0) {
        setKeywords(JSON.parse(recruitmentData?.keywords[0]?.keywords))
      }
      
      setSalaryType(
        recruitmentData.salary_type ? recruitmentData.salary_type : 'Net Pay',
      );
    }
  }, [recruitmentData]);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [jobType, setJobType] = useState('Full-time');

  const jobTypeOptions = [
    { value: 'Full-time', label: 'Full-time' },
    { value: 'Part-time ', label: 'Part-time' },
  ];

  const [role, setRole] = useState();
  const [applicationEnd, setApplicationEnd] = useState();
  const [jobDescription, setJobDescription] = useState();
  const [yearsOfExperience, setYearsOfExperience] = useState();

  const [locationType, setLocationType] = useState('On site');

  const [minSalary, setMinSalary] = useState();
  const [maxSalary, setMaxSalary] = useState();

  const [emailNotification, setEmailNotification] = useState(true);

  const [useKeywordFilter, setUseKeywordFilter] = useState(false);

  const [acceptanceRate, setAcceptanceRate] = useState('any');

  const [keywords, setKeywords] = useState([]);
  const [keyword, setKeyword] = useState('')

  const acceptanceRateOptions = [
    { value: 'any', label: 'Accept on finding any keyword' },
    { value: 'all', label: 'Accept on finding all keywords (only)' },
  ];

  const locationOptions = [
    { value: 'On site', label: 'On site' },
    { value: 'Remote', label: 'Remote' },
    { value: 'Hybrid', label: 'Hybrid' },
  ];

  const [educationLevel, setEducationLevel] = useState('Degree');

  const educationLevelOptions = [
    { value: 'High School', label: 'High School' },
    { value: 'Diploma', label: 'Diploma' },
    { value: 'Degree', label: 'Degree' },
    { value: 'MSc.', label: 'MSc.' },
    { value: 'MBA', label: 'MBA' },
    { value: 'phd', label: 'PhD.' },
  ];

  const [experienceLevel, setExperienceLevel] = useState('Entry');

  const experienceLevelOptions = [
    { value: 'Entry', label: 'Entry' },
    { value: 'Intern', label: 'Intern' },
    { value: 'Mid-Level', label: 'Mid-Level' },
    { value: 'Senior-Level', label: 'Senior-Level' },
    { value: 'Management', label: 'Management' },
  ];

  const [salaryType, setSalaryType] = useState('Net Salary');

  const SalaryOptions = [
    { value: 'Net Salary', label: 'Net Salary' },
    { value: 'Gross Salary', label: 'Gross Salary' },
  ];

  const updateWorkflow = async (data) => {
    const worflow_name = DOMPurify.sanitize(data?.workflow_name);
    const workflow_description = DOMPurify.sanitize(data?.workflow_description);
    let payload = {
      id: workflowId,
      data: {
        name: worflow_name,
        description: workflow_description,
      },
    };
    updateRecruitmentWorkflow(payload).then(() => {
      closeModal();
    });
  };

  const submitForm = async (data) => {
    const min_salary = DOMPurify.sanitize(data?.min_salary);
    const max_salary = DOMPurify.sanitize(data?.max_salary);
    const years_of_experience = DOMPurify.sanitize(data?.years_of_experience);

    const requestData = {
      id: recruitmentId,
      data: {
        min_salary,
        max_salary,
        role,
        job_description: jobDescription,
        workflow_id: workflowId,
        location_type: locationType,
        years_of_experience,
        experience_level: experienceLevel,
        min_education: educationLevel,
        job_type: jobType,
        application_end: applicationEnd,
        salary_type: salaryType,
        email_notification: emailNotification ? 1 : 0,
        email_reciever_id: emailReceiverId? emailReceiverId: 0  ,
        use_keywords: useKeywordFilter?1:0,
        acceptance_rate: acceptanceRate,
        keywords: JSON.stringify(keywords)
      },
    };
    await updateRecruitment(requestData).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='md:max-w-[600px] w-full h-screen overflow-auto  !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto pb-4'>
        <div className='pl-6 pr-6 pt-4'>
          <div className='flex flex-row justify-between items-center'>
            <span className='header-4'>View Recruitment Details</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
        </div>
        <hr className='divider mb-1' />
        <div className='flex flex-col p3 items-center justify-center'>
          <span className='text-color-black pt-2 text-[14px] font-semibold'>
            Candidate application link:
          </span>
          <span className=' text-secondary-2 pt-1 !text-center text-[13px]'>
            {recruitmentData?.application_url}{' '}
          </span>
          <CopyToClipboard
            text={recruitmentData?.application_url}
            onCopy={() => {
              dispatch(setAlert(true, 'success', 'Copied successfully'));
            }}
          >
            <button className='flex flex-row mt-2 items-center text-center'>
              <span className='text-color-black pt-2 text-[14px] text-hr-primary-1 hover:font-bold duration-300'>
                Copy to clipboard{' '}
              </span>
              <img src={CopySVG} width={15} className='ml-2 mt-2' alt='copy' />
            </button>
          </CopyToClipboard>
        </div>
        <div className='p-2 text-[14px] text-center w-[100%] '>Share via:</div>
        <div className='flex flex-row gap-4 justify-center items-center'>
          <a href='https://www.facebook.com' target='_blank' rel='noreferrer' className='hover:scale-110 duration-300'>
            <img src={FacebookSVG} alt='facebook' />
          </a>
          <a href='https://www.twitter.com' target='_blank' rel='noreferrer' className='hover:scale-110 duration-300'>
            <img src={TwitterSVG} alt='twitter' />
          </a>
          <a href='https://www.linkedin.com' target='_blank' rel='noreferrer' className='hover:scale-110 duration-300'>
            <img src={LinkedinSVG} alt='linkedin' />
          </a>
        </div>
        <hr className='divider mt-4 mb-2' />
        <div className='flex flex-col w-full'>
          <div className='w-full px-4'>
            <div
              className={classNames('flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300', {
                'pt-3 pb-3': showRecruitmentDetails,
              })}
              onClick={() => setShowRecruitmentDetails(!showRecruitmentDetails)}
            >
              <span className='text-[14px]'>Recruitment Details</span>
              <div className='pr-4'>
                <CollapsingIcon
                  defaultPosition='left'
                  isOpen={showRecruitmentDetails}
                />
              </div>
            </div>
            <div
              className={classNames('flex flex-col', {
                hidden: !showRecruitmentDetails,
              })}
            >
              <Collapse isOpen={showRecruitmentDetails}>
                <div className='w-full md:w-[50%] mt-2'>
                  <FormSwitch
                    label={'Edit Mode'}
                    name={'switch-edit-mode'}
                    checked={editRecruitmentMode}
                    onClick={() => setEditRecruitmentMode(!editRecruitmentMode)}
                  />
                </div>
                {!editRecruitmentMode ? (
                  <>
                    <div className='flex flex-col border rounded-md p-3 -mt-1'>
                      <div className='grid grid-cols-2 gap-3 w-full'>
                        <div className='flex flex-col'>
                          <span className='text-[13px] text-color-gray'>
                            Role
                          </span>
                          <span className='mt-1 text-color-black text-[13px] font-bold'>
                            {recruitmentData?.role}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[13px] text-color-gray'>
                            Job Type
                          </span>
                          <span className='mt-1 text-color-black text-[13px] font-bold'>
                            {recruitmentData?.job_type}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[13px] text-color-gray'>
                            Location Type
                          </span>
                          <span className='mt-1 text-color-black text-[13px] font-bold'>
                            {recruitmentData?.location_type}
                          </span>
                        </div>

                        <div className='flex flex-col'>
                          <span className='text-[13px] text-color-gray'>
                            Minimum Education
                          </span>
                          <span className='mt-1 text-color-black text-[13px] font-bold'>
                            {recruitmentData?.min_education}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[13px] text-color-gray'>
                            Required Experience
                          </span>
                          <span className='mt-1 text-color-black text-[13px] font-bold'>
                            {recruitmentData?.years_of_experience}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[13px] text-color-gray'>
                            Experience Level
                          </span>
                          <span className='mt-1 text-color-black text-[13px] font-bold'>
                            {recruitmentData?.experience_level}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[13px] text-color-gray'>
                            Salary Type
                          </span>
                          <span className='mt-1 text-color-black text-[13px] font-bold'>
                            {recruitmentData?.salary_type}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[14px] text-color-gray'>
                            Salary Range (Monthly)
                          </span>
                          <span className='mt-1 text-color-black text-[13px] font-bold'>
                            {recruitmentData?.min_salary && recruitmentData?.max_salary?
                            <>
                            {formatNumber(parseFloat(recruitmentData?.min_salary), 2)} - {formatNumber(parseFloat(recruitmentData?.max_salary), 2)}
                            </>
                            : recruitmentData?.min_salary?
                            <>
                              {formatNumber(parseFloat(recruitmentData?.min_salary), 2)}
                            </>
                            : recruitmentData?.max_salary?
                            <>
                              {formatNumber(parseFloat(recruitmentData?.max_salary), 2)}
                            </>
                            :
                            <div>-</div>
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='grid grid-cols-1 gap-2 w-full p4 mt-[20px]'>
                      <span className='text-[15px] font-semibold text-color-gray'>
                        Job Description
                      </span>
                      <div className='border-1 p-3 !max-h-[600px] rounded-md overflow-auto'>
                        <td className='!max-h-[600px] overflow-auto !text-[13px] leading-[28px]' dangerouslySetInnerHTML={{__html: recruitmentData?.job_description}} />
                      </div>
                    </div>
                  </>
                ) : (
                  <form className='form' onSubmit={handleSubmit(submitForm)}>
                    <div className='flex flex-col md:flex-row justify-between'>
                      <div className='w-full'>
                        <FormInput
                          label='Job Role'
                          name='job_role'
                          value={role}
                          onInput={(e) => {
                            setRole(e.target.value);
                          }}
                          type='text'
                          inputRef={register(formValidation('text', true))}
                          error={errors.job_role}
                          errorMessage={
                            errors.job_role && errors.job_role.message
                          }
                        />
                      </div>
                    </div>
                    <div className='flex flex-col md:flex-row justify-between'>
                      <div className='w-full'>
                        <FormSelect
                          defaultValue={jobType}
                          options={jobTypeOptions}
                          onChange={(selected) => {
                            setJobType(selected);
                          }}
                          label='Job Type'
                        />
                      </div>
                    </div>
                    <div className='flex flex-col md:flex-row justify-between'>
                      <div className='md:w-1/2 md:pr-2'>
                        <FormSelect
                          defaultValue={experienceLevel}
                          options={experienceLevelOptions}
                          onChange={(selected) => {
                            setExperienceLevel(selected);
                          }}
                          label=' Experience level'
                        />
                      </div>
                      <div className='md:w-1/2 md:pr-2'>
                        <FormInput
                          label='Years Of Experience - (Optional)'
                          name='years_of_experience'
                          type='number'
                          value={yearsOfExperience}
                          onInput={(e) => {
                            setYearsOfExperience(e.target.value);
                          }}
                          inputRef={register(formValidation('number', true))}
                          error={errors.years_of_experience}
                          errorMessage={
                            errors.years_of_experience &&
                            errors.years_of_experience.message
                          }
                        />
                      </div>
                    </div>
                    <div className='flex flex-col md:flex-row justify-between'>
                      <div className='w-full md:w-1/2 md:pr-2'>
                        <FormSelect
                          defaultValue={locationType}
                          options={locationOptions}
                          onChange={(selected) => {
                            setLocationType(selected);
                          }}
                          label='Location Type'
                        />
                      </div>
                      <div className='w-full md:w-1/2 md:pl-2'>
                        <FormInput
                          placeholder='Application End'
                          name='application_end'
                          label='Application Ends?'
                          type='date'
                          value={applicationEnd}
                          onInput={(e) => {
                            setApplicationEnd(e.target.value);
                          }}
                          inputRef={register(formValidation('date', false))}
                          error={errors.application_end}
                          errorMessage={'Application end is required'}
                        />
                      </div>
                    </div>
                    <span className='text-color-gray'>
                      Salary Range - (Optional)
                    </span>
                    <div className='flex flex-row justify-between items-center'>
                      <div className='w-1/2 pr-2'>
                        <FormInput
                          name='min_salary'
                          placeholder='Minimum Salary'
                          type='number'
                          value={minSalary}
                          onInput={(e) => setMinSalary(e.target.value)}
                          inputRef={register(formValidation('number', false))}
                          error={minSalary > maxSalary && minSalary}
                          errorMessage={'Minimum Salary Cannot be greater'}
                        />
                      </div>
                      <div className='w-1/2 pl-2 '>
                        <FormInput
                          placeholder='Maximum Salary'
                          name='max_salary'
                          type='number'
                          inputRef={register(formValidation('number', false))}
                          value={maxSalary}
                          onInput={(e) => setMaxSalary(e.target.value)}
                          error={minSalary > maxSalary && maxSalary}
                          errorMessage={'Maximum Salary Cannot be less'}
                        />
                      </div>
                    </div>
                    <div className='flex flex-col md:flex-row justify-between'>
                      <div className='md:w-1/2 md:pr-2'>
                        <FormSelect
                          defaultValue={salaryType}
                          options={SalaryOptions}
                          onChange={(selected) => {
                            setSalaryType(selected);
                          }}
                          label='Salary Type'
                        />
                      </div>
                      <div className='md:w-1/2 md:pl-2 '>
                        <FormSelect
                          defaultValue={educationLevel}
                          options={educationLevelOptions}
                          onChange={(selected) => {
                            setEducationLevel(selected);
                          }}
                          label='Minimum Education'
                        />
                      </div>
                    </div>
                    <div className='flex flex-col md:flex-row justify-between mt-2'>
                      <div className='w-full'>
                        <div className='text-color-gray text-[15px] pb-2'>Job Description</div>
                        <Editor
                          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                          onEditorChange={(editor) => {
                            setJobDescription(editor)
                          }}
                          value={jobDescription}
                          init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor fontsize fontfamily | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:10pt }'
                          }}
                        />
                      </div>
                    </div>
                    <>
                      <hr className='divider mt-2' />
                      <div className={'flex w-[100%] flex-col items-center'}>
                        <div className='w-full '>
                          <FormSwitch
                            label={'Auto filter applicants by keywords?'}
                            name={'filter_by_keyword'}
                            checked={useKeywordFilter}
                            onClick={() => {
                              setUseKeywordFilter(!useKeywordFilter);
                            }}
                          />
                        </div>
                        <div className='w-full'>
                        {useKeywordFilter ? (
                        <>
                          <span className='text-color-gray text-[13px]'>
                            Keywords
                          </span>
                          <div className='flex flex-row gap-2 !min-h-fit border rounded p-2 flex-wrap duration-300'>
                            {keywords.length > 0?
                            <>
                            {keywords.map((item, index) => (
                              <div className='flex flex-row border'>
                                <div
                                className='flex items-center justify-center border-r font-bold !max-w-fit rounder-tl rounder-tl p-2 !max-h-[25px] !text-[14px] !text-color-black'
                                >
                                  {keywords[index]}
                                </div>
                                <button
                                onClick={() => {
                                  let newArray = JSON.parse(JSON.stringify(keywords))
                                  newArray.splice(index, 1);
                                  setKeywords(newArray)
                                }}
                                  type='button'
                                  className={'flex items-center justify-center !max-w-fit rounder-br rounder-tr p-2 !max-h-[25px] !text-[13px] !text-color-black hover:bg-highlight font-bold text-red-700'}
                                >
                                  X
                                </button>
                              </div>
                              
                            ))}
                            </>
                            :
                            <div className='text-[15px] flex items-center !w-full justify-center text-color-gray'>
                              No keyword added
                            </div>
                            } 
                          </div>
                          <div className='w-full mt-2'>
                            <div className='flex flex-row justify-between items-center'>
                              <div className='w-[70%]'>
                                <FormInput
                                  placeholder='Enter keyword here'
                                  name='keyword'
                                  className={'!h-[35px] rounded-br-none rounded-tr-none'}
                                  type='text'
                                  value={keyword}
                                  onInput={(e) => {
                                    setKeyword(e.target.value);
                                  }}
                                />
                              </div>
                              <div className='w-[30%] pt-[9px]'>
                                <Button
                                  text={'ADD KEYWORD'}
                                  type='button'
                                  disabled={false}
                                  textClass='!text-[13px]'
                                  theme='primary'
                                  className={`!h-[36px] pl-1 pr-1 rounded-bl-none rounded-tl-none border-l-none -mt-3`}
                                  onClick={() => {
                                    let newArray = JSON.parse(JSON.stringify(keywords))
                                    newArray.push(keyword);
                                    setKeywords(newArray) 
                                    setKeyword('')
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <FormSelect
                            defaultValue={acceptanceRate}
                            options={acceptanceRateOptions}
                            onChange={(selected) => {
                              setAcceptanceRate(selected);
                            }}
                            label='Acceptance Rate'
                            extraClass={'!w-full !md:w-[50%]'}
                          />
                        </>
                        ) 
                        : null}
                        </div>
                        
                      </div>
                    </>
                    <hr className='divider mt-2' />
                    <div className={'flex w-[100%] flex-col items-center'}>
                      <div className='w-full '>
                        <FormSwitch
                          label={'Receive Application Email?'}
                          name={'email_notification'}
                          email_receiver_id
                          checked={emailNotification}
                          onClick={() => {
                            setEmailNotification(!emailNotification);
                          }}
                        />
                      </div>
                      <div className='w-full'>
                        {companyAdmins && emailNotification ? (
                          <FormSelect
                            options={companyAdmins}
                            value={emailReceiverId}
                            readOnly={emailReceiverId ? false : true}
                            onChange={(selected) => {
                              setEmailReceiverId(selected);
                            }}
                            label='Who Receives Emails?'
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className='flex justify-end w-full mb-2'>
                      <Button
                        text='UPDATE RECRUITMENT DETAILS'
                        textClass={'text-[12px]'}
                        className={'w-fit h-[42px]'}
                        type='submit'
                        theme={'third'}
                        loading={udpateRecruitmentLoading}
                      />
                    </div>
                  </form>
                )}
              </Collapse>
            </div>
          </div>
          <hr className='divider mt-1 mb-1' />
          <div className='w-full px-4'>
            <div
              className={classNames('flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300', {
                'pt-3 pb-3': showRecruitmentWorkflow,
              })}
              onClick={() =>
                setShowRecruitmentWorkflow(!showRecruitmentWorkflow)
              }
            >
              <span className='text-[14px]'>Recruitment Workflow</span>
              <div className='pr-4'>
                <CollapsingIcon
                  defaultPosition='left'
                  isOpen={showRecruitmentWorkflow}
                />
              </div>
            </div>
            <div
              className={classNames('flex flex-col', {
                hidden: !showRecruitmentWorkflow,
              })}
            >
              <Collapse isOpen={showRecruitmentWorkflow}>
                <div className='w-full md:w-[50%] mt-2'>
                  <FormSwitch
                    label={'Edit Mode'}
                    name={'switch-edit-mode'}
                    checked={editWorkflowMode}
                    onClick={() => setEditWorkflowMode(!editWorkflowMode)}
                  />
                </div>
                {!editWorkflowMode ? (
                  <>
                    <div className='grid md:grid-cols-1 grid-cols-1 gap-4 w-full mb-3 border rounded-md p-2'>
                      <div className='flex flex-col'>
                        <span className='text-[14px] font-extrabold text-color-gray'>
                          Workflow Name
                        </span>
                        <span className='mt-1 text-color-black text-[13px]'>
                          {recruitmentData?.workflow.name}
                        </span>
                      </div>
                    </div>
                    <div className='mt-2 grid md:grid-cols-1 grid-cols-1 gap-4 w-full mb-1'>
                      <div className='flex flex-col'>
                        <span className='text-[14px] font-extrabold text-color-gray'>Stages</span>
                      </div>
                    </div>
                    <div className='grid md:grid-cols-1 grid-cols-1 gap-4 w-full p-2 rounded-md mt-2 border'>
                      <div className='flex flex-col'>
                        <div className='flex flex-row gap-4 mb-2'>
                          <span className='header-4 w-[30%] text-color-gray'></span>
                          <span className='text-[14px] font-extrabold w-[40%] text-[#0d5225]'>
                            Title
                          </span>
                          <span className='text-[14px] font-extrabold w-[25%] text-[#0d5225]'>
                            Type
                          </span>
                        </div>
                        {recruitmentData?.recruitment_stages.map((item) => (
                          <div className='flex flex-row gap-4 h-[30px]'>
                            <div className='text-[14px] font-semibold w-[30%]'>
                              Stage {item.index}:
                            </div>
                            <div className='text-[12px] w-[40%]'>
                              {item.name}
                            </div>
                            <div className='text-[12px] w-[25%]'>{item.type}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <form
                    className='form'
                    onSubmit={handleSubmit(updateWorkflow)}
                  >
                    <div className='flex flex-col md:flex-row justify-between'>
                      <div className='w-full'>
                        <FormInput
                          label='Workflow Name'
                          name='workflow_name'
                          value={workflowName}
                          onInput={(e) => {
                            setWorkflowName(e.target.value);
                          }}
                          type='text'
                          inputRef={register(formValidation('text', true))}
                          error={errors.workflow_name}
                          errorMessage={
                            errors.workflow_name && errors.workflow_name.message
                          }
                        />
                      </div>
                    </div>
                    <div className='flex flex-col md:flex-row justify-between'>
                      <div className='w-full'>
                        <FormInput
                          label='Workflow Description'
                          name='workflow_description'
                          type='text'
                          value={workflowDescription}
                          onInput={(e) =>
                            setWorkflowDescription(e.target.value)
                          }
                          inputRef={register(formValidation('text', true))}
                          error={errors.workflow_description}
                          errorMessage={
                            errors.workflow_description &&
                            errors.workflow_description.message
                          }
                        />
                      </div>
                    </div>
                    <div className='flex justify-end w-full mb-2'>
                      <Button
                        text='Update Recruitment Workflow'
                        textClass={'text-[12px]'}
                        className={'w-fit h-[42px]'}
                        type='submit'
                        theme={'third'}
                        loading={udpateWorkflowLoading}
                      />
                    </div>
                  </form>
                )}
              </Collapse>
            </div>
          </div>
        </div>
        
      </div>
    </Modal>
  );
}

export default RecruitmentDetailsModal;
